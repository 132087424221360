/* tslint:disable */
/* eslint-disable */
/**
 * Bezba.Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @enum {string}
 */

export const AcceptanceType = {
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3,
    NUMBER_4: 4
} as const;

export type AcceptanceType = typeof AcceptanceType[keyof typeof AcceptanceType];


/**
 * 
 * @export
 * @interface AddressCustomerModel
 */
export interface AddressCustomerModel {
    /**
     * Id zákazníka
     * @type {number}
     * @memberof AddressCustomerModel
     */
    'customerId'?: number;
    /**
     * Celé jméno zákazníka
     * @type {string}
     * @memberof AddressCustomerModel
     */
    'fullname'?: string;
}
/**
 * 
 * @export
 * @interface AddressModel
 */
export interface AddressModel {
    /**
     * Identifikátor
     * @type {number}
     * @memberof AddressModel
     */
    'id'?: number;
    /**
     * Název
     * @type {string}
     * @memberof AddressModel
     */
    'name'?: string | null;
    /**
     * Město
     * @type {string}
     * @memberof AddressModel
     */
    'city': string;
    /**
     * Zkratka města
     * @type {string}
     * @memberof AddressModel
     */
    'cityAbbreviation'?: string | null;
    /**
     * Část města
     * @type {string}
     * @memberof AddressModel
     */
    'cityPart'?: string | null;
    /**
     * Ulice
     * @type {string}
     * @memberof AddressModel
     */
    'street': string;
    /**
     * Číslo popisné
     * @type {string}
     * @memberof AddressModel
     */
    'streetNumber': string;
    /**
     * PSČ
     * @type {string}
     * @memberof AddressModel
     */
    'postCode': string;
    /**
     * Komentář
     * @type {string}
     * @memberof AddressModel
     */
    'comment'?: string | null;
    /**
     * Identifikátor místa v Google Maps
     * @type {string}
     * @memberof AddressModel
     */
    'googlePlaceId'?: string | null;
    /**
     * Zeměpisná šířka
     * @type {number}
     * @memberof AddressModel
     */
    'lat'?: number | null;
    /**
     * Zeměpisná délka
     * @type {number}
     * @memberof AddressModel
     */
    'lng'?: number | null;
    /**
     * 
     * @type {AddressModelBillingZone}
     * @memberof AddressModel
     */
    'billingZone'?: AddressModelBillingZone | null;
    /**
     * 
     * @type {AddressModelAddressType}
     * @memberof AddressModel
     */
    'addressType'?: AddressModelAddressType;
    /**
     * 
     * @type {AddressModelCustomer}
     * @memberof AddressModel
     */
    'customer'?: AddressModelCustomer | null;
    /**
     * Aktivní
     * @type {boolean}
     * @memberof AddressModel
     */
    'isActive'?: boolean;
    /**
     * Nenalezeno
     * @type {boolean}
     * @memberof AddressModel
     */
    'notFound'?: boolean;
}
/**
 * @type AddressModelAddressType
 * Typ adresy
 * @export
 */
export type AddressModelAddressType = AddressType;

/**
 * @type AddressModelBillingZone
 * Fakturační zóna
 * @export
 */
export type AddressModelBillingZone = BillingZone;

/**
 * @type AddressModelCustomer
 * Informace o zákazníkovi
 * @export
 */
export type AddressModelCustomer = AddressCustomerModel;

/**
 * 
 * @export
 * @enum {string}
 */

export const AddressType = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3,
    NUMBER_4: 4,
    NUMBER_5: 5
} as const;

export type AddressType = typeof AddressType[keyof typeof AddressType];


/**
 * 
 * @export
 * @interface AuditPropertyModel
 */
export interface AuditPropertyModel {
    /**
     * ID.
     * @type {number}
     * @memberof AuditPropertyModel
     */
    'id'?: number;
    /**
     * Název majetku.
     * @type {string}
     * @memberof AuditPropertyModel
     */
    'property'?: string;
    /**
     * Hodnota majetku.
     * @type {string}
     * @memberof AuditPropertyModel
     */
    'value'?: string | null;
    /**
     * ID auditního záznamu.
     * @type {number}
     * @memberof AuditPropertyModel
     */
    'auditId'?: number;
    /**
     * Datum a čas vytvoření.
     * @type {string}
     * @memberof AuditPropertyModel
     */
    'createdDate'?: string;
    /**
     * ID uživatele spojeného s majetkem.
     * @type {number}
     * @memberof AuditPropertyModel
     */
    'userId'?: number;
    /**
     * Celé jméno uživatele.
     * @type {string}
     * @memberof AuditPropertyModel
     */
    'userFullName'?: string;
    /**
     * Typ entity zapojené v auditu.
     * @type {string}
     * @memberof AuditPropertyModel
     */
    'entityType'?: string;
    /**
     * Jedinečný identifikátor entity zapojené v auditu.
     * @type {number}
     * @memberof AuditPropertyModel
     */
    'entityId'?: number;
    /**
     * 
     * @type {AuditPropertyModelType}
     * @memberof AuditPropertyModel
     */
    'type'?: AuditPropertyModelType;
}
/**
 * @type AuditPropertyModelType
 * Typ auditního záznamu.
 * @export
 */
export type AuditPropertyModelType = AuditType;

/**
 * 
 * @export
 * @enum {string}
 */

export const AuditType = {
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3
} as const;

export type AuditType = typeof AuditType[keyof typeof AuditType];


/**
 * 
 * @export
 * @interface AuthToken
 */
export interface AuthToken {
    /**
     * 
     * @type {string}
     * @memberof AuthToken
     */
    'value'?: string;
    /**
     * 
     * @type {string}
     * @memberof AuthToken
     */
    'expiryDate'?: string;
}
/**
 * 
 * @export
 * @interface BaseEntity
 */
export interface BaseEntity {
    /**
     * 
     * @type {number}
     * @memberof BaseEntity
     */
    'id'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof BaseEntity
     */
    'isDeleted'?: boolean;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const BillingZone = {
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3,
    NUMBER_4: 4
} as const;

export type BillingZone = typeof BillingZone[keyof typeof BillingZone];


/**
 * 
 * @export
 * @interface CancelOrderRequest
 */
export interface CancelOrderRequest {
    /**
     * ID objednávky
     * @type {number}
     * @memberof CancelOrderRequest
     */
    'id': number;
    /**
     * Datum zrušení objednávky
     * @type {string}
     * @memberof CancelOrderRequest
     */
    'cancelSince'?: string | null;
}
/**
 * 
 * @export
 * @interface CancelOrderResponse
 */
export interface CancelOrderResponse {
    /**
     * 
     * @type {string}
     * @memberof CancelOrderResponse
     */
    'message'?: string;
    /**
     * 
     * @type {number}
     * @memberof CancelOrderResponse
     */
    'id'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CancelOrderResponse
     */
    'deleted'?: boolean;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const CardType = {
    NUMBER_1: 1,
    NUMBER_2: 2
} as const;

export type CardType = typeof CardType[keyof typeof CardType];


/**
 * 
 * @export
 * @interface CarrierModel
 */
export interface CarrierModel {
    /**
     * Identifikátor dopravce.
     * @type {number}
     * @memberof CarrierModel
     */
    'id'?: number;
    /**
     * Celé jméno dopravce.
     * @type {string}
     * @memberof CarrierModel
     */
    'nameFull'?: string;
    /**
     * Zkrácené jméno dopravce.
     * @type {string}
     * @memberof CarrierModel
     */
    'nameShort'?: string;
    /**
     * Zkratka dopravce.
     * @type {string}
     * @memberof CarrierModel
     */
    'abbreviation'?: string;
    /**
     * 
     * @type {CarrierModelAddress}
     * @memberof CarrierModel
     */
    'address'?: CarrierModelAddress | null;
    /**
     * Identifikátor obrázku loga dopravce.
     * @type {number}
     * @memberof CarrierModel
     */
    'logoImageId'?: number | null;
    /**
     * Stav aktivace dopravce.
     * @type {boolean}
     * @memberof CarrierModel
     */
    'isActive'?: boolean;
}
/**
 * @type CarrierModelAddress
 * Adresa dopravce.
 * @export
 */
export type CarrierModelAddress = AddressModel;

/**
 * 
 * @export
 * @interface ChangePasswordRequest
 */
export interface ChangePasswordRequest {
    /**
     * ID uživatele
     * @type {number}
     * @memberof ChangePasswordRequest
     */
    'userId': number;
    /**
     * Staré heslo
     * @type {string}
     * @memberof ChangePasswordRequest
     */
    'passwordOld'?: string;
    /**
     * Nové heslo
     * @type {string}
     * @memberof ChangePasswordRequest
     */
    'passwordNew': string;
}
/**
 * 
 * @export
 * @interface CloneOperatingDayRequest
 */
export interface CloneOperatingDayRequest {
    /**
     * Nový název pro klonovaný provozní den.
     * @type {string}
     * @memberof CloneOperatingDayRequest
     */
    'newName': string;
}
/**
 * 
 * @export
 * @interface CloneOperatingDayResponse
 */
export interface CloneOperatingDayResponse {
    /**
     * Zpráva oznamující, že provozní den byl úspěšně zduplikován.
     * @type {string}
     * @memberof CloneOperatingDayResponse
     */
    'message'?: string;
    /**
     * ID nově vytvořeného provozního dne.
     * @type {number}
     * @memberof CloneOperatingDayResponse
     */
    'id'?: number;
}
/**
 * 
 * @export
 * @interface CloneOrderResponse
 */
export interface CloneOrderResponse {
    /**
     * 
     * @type {string}
     * @memberof CloneOrderResponse
     */
    'message'?: string;
    /**
     * 
     * @type {number}
     * @memberof CloneOrderResponse
     */
    'id'?: number;
}
/**
 * 
 * @export
 * @interface CloneShiftsRequest
 */
export interface CloneShiftsRequest {
    /**
     * Seznam identifikátorů dopravců (Carrier) pro zduplikování směny.
     * @type {Array<number>}
     * @memberof CloneShiftsRequest
     */
    'carrierIds'?: Array<number>;
}
/**
 * 
 * @export
 * @interface CreateMultipleSlotInstanceNotesRequest
 */
export interface CreateMultipleSlotInstanceNotesRequest {
    /**
     * ID Slotu, jehož instance se mají zaplnit
     * @type {number}
     * @memberof CreateMultipleSlotInstanceNotesRequest
     */
    'slotId'?: number;
    /**
     * Datum a čas začátku plnění instancí
     * @type {string}
     * @memberof CreateMultipleSlotInstanceNotesRequest
     */
    'from'?: string;
    /**
     * Datum a čas konce plnění instancí
     * @type {string}
     * @memberof CreateMultipleSlotInstanceNotesRequest
     */
    'to'?: string;
    /**
     * Čas začátku poznámky v instanci
     * @type {string}
     * @memberof CreateMultipleSlotInstanceNotesRequest
     */
    'noteStart'?: string;
    /**
     * Čas konce poznámky v instanci
     * @type {string}
     * @memberof CreateMultipleSlotInstanceNotesRequest
     */
    'noteEnd'?: string;
    /**
     * Text poznámky
     * @type {string}
     * @memberof CreateMultipleSlotInstanceNotesRequest
     */
    'text': string;
    /**
     * Text pro řidiče
     * @type {string}
     * @memberof CreateMultipleSlotInstanceNotesRequest
     */
    'driverText'?: string;
}
/**
 * 
 * @export
 * @interface CreatePasswordRequest
 */
export interface CreatePasswordRequest {
    /**
     * Token
     * @type {string}
     * @memberof CreatePasswordRequest
     */
    'token': string;
    /**
     * Heslo
     * @type {string}
     * @memberof CreatePasswordRequest
     */
    'password': string;
}
/**
 * 
 * @export
 * @interface CustomerContactModel
 */
export interface CustomerContactModel {
    /**
     * ID.
     * @type {number}
     * @memberof CustomerContactModel
     */
    'id'?: number;
    /**
     * Název.
     * @type {string}
     * @memberof CustomerContactModel
     */
    'name'?: string;
    /**
     * Telefonní číslo.
     * @type {string}
     * @memberof CustomerContactModel
     */
    'phoneNumber'?: string;
}
/**
 * 
 * @export
 * @interface CustomerModel
 */
export interface CustomerModel {
    /**
     * ID zákazníka.
     * @type {number}
     * @memberof CustomerModel
     */
    'id'?: number;
    /**
     * Jméno zákazníka.
     * @type {string}
     * @memberof CustomerModel
     */
    'firstName': string;
    /**
     * Příjmení zákazníka.
     * @type {string}
     * @memberof CustomerModel
     */
    'lastName': string;
    /**
     * Datum narození zákazníka.
     * @type {string}
     * @memberof CustomerModel
     */
    'dateOfBirth': string;
    /**
     * Telefonní číslo zákazníka.
     * @type {string}
     * @memberof CustomerModel
     */
    'phoneNumber': string;
    /**
     * Emailová adresa zákazníka.
     * @type {string}
     * @memberof CustomerModel
     */
    'email': string;
    /**
     * Preferovaný způsob komunikace pomocí emailu.
     * @type {boolean}
     * @memberof CustomerModel
     */
    'isEmailPreferred'?: boolean;
    /**
     * Jméno opatrovníka.
     * @type {string}
     * @memberof CustomerModel
     */
    'guardianFirstName'?: string | null;
    /**
     * Příjmení opatrovníka.
     * @type {string}
     * @memberof CustomerModel
     */
    'guardianLastName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerModel
     */
    'cardNumber': string;
    /**
     * 
     * @type {CardType}
     * @memberof CustomerModel
     */
    'cardType': CardType;
    /**
     * Popis handicapu zákazníka.
     * @type {string}
     * @memberof CustomerModel
     */
    'handicapDescription'?: string | null;
    /**
     * ID vybavení handicapovaného.
     * @type {number}
     * @memberof CustomerModel
     */
    'handicapEquipmentId': number;
    /**
     * Veřejný komentář.
     * @type {string}
     * @memberof CustomerModel
     */
    'generalComment'?: string | null;
    /**
     * Interní komentář.
     * @type {string}
     * @memberof CustomerModel
     */
    'internalComment'?: string | null;
    /**
     * Stav aktivace zákazníka.
     * @type {boolean}
     * @memberof CustomerModel
     */
    'isActive'?: boolean;
    /**
     * Kolekce modelů kontaktů zákazníka.
     * @type {Array<CustomerContactModel>}
     * @memberof CustomerModel
     */
    'contacts'?: Array<CustomerContactModel>;
    /**
     * Kolekce modelů adres zákazníka.
     * @type {Array<AddressModel>}
     * @memberof CustomerModel
     */
    'addresses': Array<AddressModel>;
}
/**
 * 
 * @export
 * @interface DataTableResponseModelOfAddressModel
 */
export interface DataTableResponseModelOfAddressModel {
    /**
     * Kolekce dat v odpovědi z datové tabulky.
     * @type {Array<AddressModel>}
     * @memberof DataTableResponseModelOfAddressModel
     */
    'data'?: Array<AddressModel>;
    /**
     * Celkový počet záznamů v datové tabulce.
     * @type {number}
     * @memberof DataTableResponseModelOfAddressModel
     */
    'total'?: number;
}
/**
 * 
 * @export
 * @interface DataTableResponseModelOfAuditPropertyModel
 */
export interface DataTableResponseModelOfAuditPropertyModel {
    /**
     * Kolekce dat v odpovědi z datové tabulky.
     * @type {Array<AuditPropertyModel>}
     * @memberof DataTableResponseModelOfAuditPropertyModel
     */
    'data'?: Array<AuditPropertyModel>;
    /**
     * Celkový počet záznamů v datové tabulce.
     * @type {number}
     * @memberof DataTableResponseModelOfAuditPropertyModel
     */
    'total'?: number;
}
/**
 * 
 * @export
 * @interface DataTableResponseModelOfCarrierModel
 */
export interface DataTableResponseModelOfCarrierModel {
    /**
     * Kolekce dat v odpovědi z datové tabulky.
     * @type {Array<CarrierModel>}
     * @memberof DataTableResponseModelOfCarrierModel
     */
    'data'?: Array<CarrierModel>;
    /**
     * Celkový počet záznamů v datové tabulce.
     * @type {number}
     * @memberof DataTableResponseModelOfCarrierModel
     */
    'total'?: number;
}
/**
 * 
 * @export
 * @interface DataTableResponseModelOfGetCustomersModel
 */
export interface DataTableResponseModelOfGetCustomersModel {
    /**
     * Kolekce dat v odpovědi z datové tabulky.
     * @type {Array<GetCustomersModel>}
     * @memberof DataTableResponseModelOfGetCustomersModel
     */
    'data'?: Array<GetCustomersModel>;
    /**
     * Celkový počet záznamů v datové tabulce.
     * @type {number}
     * @memberof DataTableResponseModelOfGetCustomersModel
     */
    'total'?: number;
}
/**
 * 
 * @export
 * @interface DataTableResponseModelOfGetOrdersSingleResponse
 */
export interface DataTableResponseModelOfGetOrdersSingleResponse {
    /**
     * Kolekce dat v odpovědi z datové tabulky.
     * @type {Array<GetOrdersSingleResponse>}
     * @memberof DataTableResponseModelOfGetOrdersSingleResponse
     */
    'data'?: Array<GetOrdersSingleResponse>;
    /**
     * Celkový počet záznamů v datové tabulce.
     * @type {number}
     * @memberof DataTableResponseModelOfGetOrdersSingleResponse
     */
    'total'?: number;
}
/**
 * 
 * @export
 * @interface DataTableResponseModelOfGetRideModel
 */
export interface DataTableResponseModelOfGetRideModel {
    /**
     * Kolekce dat v odpovědi z datové tabulky.
     * @type {Array<GetRideModel>}
     * @memberof DataTableResponseModelOfGetRideModel
     */
    'data'?: Array<GetRideModel>;
    /**
     * Celkový počet záznamů v datové tabulce.
     * @type {number}
     * @memberof DataTableResponseModelOfGetRideModel
     */
    'total'?: number;
}
/**
 * 
 * @export
 * @interface DataTableResponseModelOfGetShiftsResponseSingle
 */
export interface DataTableResponseModelOfGetShiftsResponseSingle {
    /**
     * Kolekce dat v odpovědi z datové tabulky.
     * @type {Array<GetShiftsResponseSingle>}
     * @memberof DataTableResponseModelOfGetShiftsResponseSingle
     */
    'data'?: Array<GetShiftsResponseSingle>;
    /**
     * Celkový počet záznamů v datové tabulce.
     * @type {number}
     * @memberof DataTableResponseModelOfGetShiftsResponseSingle
     */
    'total'?: number;
}
/**
 * 
 * @export
 * @interface DataTableResponseModelOfGetSlotResponse
 */
export interface DataTableResponseModelOfGetSlotResponse {
    /**
     * Kolekce dat v odpovědi z datové tabulky.
     * @type {Array<GetSlotResponse>}
     * @memberof DataTableResponseModelOfGetSlotResponse
     */
    'data'?: Array<GetSlotResponse>;
    /**
     * Celkový počet záznamů v datové tabulce.
     * @type {number}
     * @memberof DataTableResponseModelOfGetSlotResponse
     */
    'total'?: number;
}
/**
 * 
 * @export
 * @interface DataTableResponseModelOfGetUsersModel
 */
export interface DataTableResponseModelOfGetUsersModel {
    /**
     * Kolekce dat v odpovědi z datové tabulky.
     * @type {Array<GetUsersModel>}
     * @memberof DataTableResponseModelOfGetUsersModel
     */
    'data'?: Array<GetUsersModel>;
    /**
     * Celkový počet záznamů v datové tabulce.
     * @type {number}
     * @memberof DataTableResponseModelOfGetUsersModel
     */
    'total'?: number;
}
/**
 * 
 * @export
 * @interface DataTableResponseModelOfGetVehiclesSingleResponse
 */
export interface DataTableResponseModelOfGetVehiclesSingleResponse {
    /**
     * Kolekce dat v odpovědi z datové tabulky.
     * @type {Array<GetVehiclesSingleResponse>}
     * @memberof DataTableResponseModelOfGetVehiclesSingleResponse
     */
    'data'?: Array<GetVehiclesSingleResponse>;
    /**
     * Celkový počet záznamů v datové tabulce.
     * @type {number}
     * @memberof DataTableResponseModelOfGetVehiclesSingleResponse
     */
    'total'?: number;
}
/**
 * 
 * @export
 * @interface DeleteVehicleTypeEquipmentResponse
 */
export interface DeleteVehicleTypeEquipmentResponse {
    /**
     * 
     * @type {string}
     * @memberof DeleteVehicleTypeEquipmentResponse
     */
    'message'?: string;
    /**
     * 
     * @type {number}
     * @memberof DeleteVehicleTypeEquipmentResponse
     */
    'typeId'?: number;
    /**
     * 
     * @type {number}
     * @memberof DeleteVehicleTypeEquipmentResponse
     */
    'variantId'?: number;
}
/**
 * 
 * @export
 * @interface EndLogShiftRequest
 */
export interface EndLogShiftRequest {
    /**
     * ID směny řidiče
     * @type {number}
     * @memberof EndLogShiftRequest
     */
    'driverShiftId'?: number;
    /**
     * ID směny vozidla
     * @type {number}
     * @memberof EndLogShiftRequest
     */
    'vehicleShiftId'?: number;
}
/**
 * 
 * @export
 * @interface EndLogShiftResponse
 */
export interface EndLogShiftResponse {
    /**
     * ID ukončené směny
     * @type {number}
     * @memberof EndLogShiftResponse
     */
    'id'?: number;
}
/**
 * 
 * @export
 * @interface EquipmentAmountModel
 */
export interface EquipmentAmountModel {
    /**
     * Id pomůcky
     * @type {number}
     * @memberof EquipmentAmountModel
     */
    'equipmentId'?: number;
    /**
     * Množství
     * @type {number}
     * @memberof EquipmentAmountModel
     */
    'amount'?: number;
}
/**
 * 
 * @export
 * @interface EquipmentMultisetModel
 */
export interface EquipmentMultisetModel {
    /**
     * Id
     * @type {number}
     * @memberof EquipmentMultisetModel
     */
    'id'?: number;
    /**
     * Seznam pomůcek pro handicapované
     * @type {Array<EquipmentAmountModel>}
     * @memberof EquipmentMultisetModel
     */
    'equipments'?: Array<EquipmentAmountModel>;
}
/**
 * 
 * @export
 * @interface ErrorResponseModel
 */
export interface ErrorResponseModel {
    /**
     * 
     * @type {number}
     * @memberof ErrorResponseModel
     */
    'statusCode'?: number;
    /**
     * 
     * @type {string}
     * @memberof ErrorResponseModel
     */
    'message'?: string;
    /**
     * 
     * @type {Errors}
     * @memberof ErrorResponseModel
     */
    'errors'?: Errors;
}
/**
 * 
 * @export
 * @interface Errors
 */
export interface Errors {
    /**
     * 
     * @type {Array<string>}
     * @memberof Errors
     */
    'generalErrors'?: Array<string>;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const Escort = {
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3
} as const;

export type Escort = typeof Escort[keyof typeof Escort];


/**
 * 
 * @export
 * @enum {string}
 */

export const EventType = {
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3,
    NUMBER_4: 4,
    NUMBER_5: 5,
    NUMBER_6: 6,
    NUMBER_7: 7
} as const;

export type EventType = typeof EventType[keyof typeof EventType];


/**
 * 
 * @export
 * @interface ForceEndLogShiftRequest
 */
export interface ForceEndLogShiftRequest {
    /**
     * ID směny řidiče
     * @type {number}
     * @memberof ForceEndLogShiftRequest
     */
    'driverShiftId'?: number;
    /**
     * ID směny vozidla
     * @type {number}
     * @memberof ForceEndLogShiftRequest
     */
    'vehicleShiftId'?: number;
}
/**
 * 
 * @export
 * @interface ForceEndLogShiftResponse
 */
export interface ForceEndLogShiftResponse {
    /**
     * ID ukončené směny
     * @type {number}
     * @memberof ForceEndLogShiftResponse
     */
    'id'?: number;
}
/**
 * 
 * @export
 * @interface ForgotPasswordRequest
 */
export interface ForgotPasswordRequest {
    /**
     * Email
     * @type {string}
     * @memberof ForgotPasswordRequest
     */
    'email': string;
}
/**
 * 
 * @export
 * @interface GPSDozorVehicleInformationModel
 */
export interface GPSDozorVehicleInformationModel {
    /**
     * Kód vozidla
     * @type {string}
     * @memberof GPSDozorVehicleInformationModel
     */
    'code'?: string;
    /**
     * Kód skupiny, ke které vozidlo patří
     * @type {string}
     * @memberof GPSDozorVehicleInformationModel
     */
    'groupCode'?: string;
    /**
     * ID pobočky, ke které vozidlo patří
     * @type {number}
     * @memberof GPSDozorVehicleInformationModel
     */
    'branchId'?: number | null;
    /**
     * Název pobočky, ke které vozidlo patří
     * @type {string}
     * @memberof GPSDozorVehicleInformationModel
     */
    'branchName'?: string;
    /**
     * Název vozidla
     * @type {string}
     * @memberof GPSDozorVehicleInformationModel
     */
    'name'?: string;
    /**
     * SPZ (Škoda Praha Zlín) vozidla
     * @type {string}
     * @memberof GPSDozorVehicleInformationModel
     */
    'spz'?: string;
    /**
     * Procento nabití baterie vozidla
     * @type {number}
     * @memberof GPSDozorVehicleInformationModel
     */
    'batteryPercentage'?: number;
    /**
     * Rychlost vozidla
     * @type {number}
     * @memberof GPSDozorVehicleInformationModel
     */
    'speed'?: number;
    /**
     * 
     * @type {GPSDozorVehicleInformationModelLastPosition}
     * @memberof GPSDozorVehicleInformationModel
     */
    'lastPosition'?: GPSDozorVehicleInformationModelLastPosition;
    /**
     * IMEI číslo zařízení vozidla
     * @type {string}
     * @memberof GPSDozorVehicleInformationModel
     */
    'deviceImei'?: string;
    /**
     * Zda je vozidlo aktivní
     * @type {boolean}
     * @memberof GPSDozorVehicleInformationModel
     */
    'isActive'?: boolean;
    /**
     * Časové razítko poslední známé pozice vozidla
     * @type {string}
     * @memberof GPSDozorVehicleInformationModel
     */
    'lastPositionTimestamp'?: string;
    /**
     * Odometer vozidla (množství ujetých kilometrů)
     * @type {number}
     * @memberof GPSDozorVehicleInformationModel
     */
    'odometer'?: number;
    /**
     * Telefonní číslo vozidla
     * @type {string}
     * @memberof GPSDozorVehicleInformationModel
     */
    'phone'?: string;
    /**
     * Číslo vozidla
     * @type {string}
     * @memberof GPSDozorVehicleInformationModel
     */
    'number'?: string;
}
/**
 * @type GPSDozorVehicleInformationModelLastPosition
 * Poslední známá pozice vozidla
 * @export
 */
export type GPSDozorVehicleInformationModelLastPosition = LastPosition;

/**
 * 
 * @export
 * @interface GetAddressesRequest
 */
export interface GetAddressesRequest {
}
/**
 * 
 * @export
 * @interface GetAddressesResponse
 */
export interface GetAddressesResponse {
    /**
     * Kolekce dat v odpovědi z datové tabulky.
     * @type {Array<AddressModel>}
     * @memberof GetAddressesResponse
     */
    'data'?: Array<AddressModel>;
    /**
     * Celkový počet záznamů v datové tabulce.
     * @type {number}
     * @memberof GetAddressesResponse
     */
    'total'?: number;
}
/**
 * 
 * @export
 * @interface GetCarriersRequest
 */
export interface GetCarriersRequest {
}
/**
 * 
 * @export
 * @interface GetCarriersResponse
 */
export interface GetCarriersResponse {
    /**
     * Kolekce dat v odpovědi z datové tabulky.
     * @type {Array<CarrierModel>}
     * @memberof GetCarriersResponse
     */
    'data'?: Array<CarrierModel>;
    /**
     * Celkový počet záznamů v datové tabulce.
     * @type {number}
     * @memberof GetCarriersResponse
     */
    'total'?: number;
    /**
     * Dopravci
     * @type {Array<CarrierModel>}
     * @memberof GetCarriersResponse
     */
    'carriers'?: Array<CarrierModel>;
}
/**
 * 
 * @export
 * @interface GetCarriersResponseAllOf
 */
export interface GetCarriersResponseAllOf {
    /**
     * Dopravci
     * @type {Array<CarrierModel>}
     * @memberof GetCarriersResponseAllOf
     */
    'carriers'?: Array<CarrierModel>;
}
/**
 * 
 * @export
 * @interface GetCustomerResponse
 */
export interface GetCustomerResponse {
    /**
     * ID zákazníka.
     * @type {number}
     * @memberof GetCustomerResponse
     */
    'id'?: number;
    /**
     * Jméno zákazníka.
     * @type {string}
     * @memberof GetCustomerResponse
     */
    'firstName': string;
    /**
     * Příjmení zákazníka.
     * @type {string}
     * @memberof GetCustomerResponse
     */
    'lastName': string;
    /**
     * Datum narození zákazníka.
     * @type {string}
     * @memberof GetCustomerResponse
     */
    'dateOfBirth': string;
    /**
     * Telefonní číslo zákazníka.
     * @type {string}
     * @memberof GetCustomerResponse
     */
    'phoneNumber': string;
    /**
     * Emailová adresa zákazníka.
     * @type {string}
     * @memberof GetCustomerResponse
     */
    'email': string;
    /**
     * Preferovaný způsob komunikace pomocí emailu.
     * @type {boolean}
     * @memberof GetCustomerResponse
     */
    'isEmailPreferred'?: boolean;
    /**
     * Jméno opatrovníka.
     * @type {string}
     * @memberof GetCustomerResponse
     */
    'guardianFirstName'?: string | null;
    /**
     * Příjmení opatrovníka.
     * @type {string}
     * @memberof GetCustomerResponse
     */
    'guardianLastName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetCustomerResponse
     */
    'cardNumber': string;
    /**
     * 
     * @type {CardType}
     * @memberof GetCustomerResponse
     */
    'cardType': CardType;
    /**
     * Popis handicapu zákazníka.
     * @type {string}
     * @memberof GetCustomerResponse
     */
    'handicapDescription'?: string | null;
    /**
     * ID vybavení handicapovaného.
     * @type {number}
     * @memberof GetCustomerResponse
     */
    'handicapEquipmentId': number;
    /**
     * Veřejný komentář.
     * @type {string}
     * @memberof GetCustomerResponse
     */
    'generalComment'?: string | null;
    /**
     * Interní komentář.
     * @type {string}
     * @memberof GetCustomerResponse
     */
    'internalComment'?: string | null;
    /**
     * Stav aktivace zákazníka.
     * @type {boolean}
     * @memberof GetCustomerResponse
     */
    'isActive'?: boolean;
    /**
     * Kolekce modelů kontaktů zákazníka.
     * @type {Array<CustomerContactModel>}
     * @memberof GetCustomerResponse
     */
    'contacts'?: Array<CustomerContactModel>;
    /**
     * Kolekce modelů adres zákazníka.
     * @type {Array<AddressModel>}
     * @memberof GetCustomerResponse
     */
    'addresses': Array<AddressModel>;
    /**
     * Naposled upraveno
     * @type {string}
     * @memberof GetCustomerResponse
     */
    'lastModified'?: string;
    /**
     * Naposled upraveno subjektem
     * @type {string}
     * @memberof GetCustomerResponse
     */
    'lastModifiedBy'?: string;
    /**
     * Pomůcka
     * @type {string}
     * @memberof GetCustomerResponse
     */
    'handicapEquipmentAbbr'?: string;
}
/**
 * 
 * @export
 * @interface GetCustomerResponseAllOf
 */
export interface GetCustomerResponseAllOf {
    /**
     * Naposled upraveno
     * @type {string}
     * @memberof GetCustomerResponseAllOf
     */
    'lastModified'?: string;
    /**
     * Naposled upraveno subjektem
     * @type {string}
     * @memberof GetCustomerResponseAllOf
     */
    'lastModifiedBy'?: string;
    /**
     * Pomůcka
     * @type {string}
     * @memberof GetCustomerResponseAllOf
     */
    'handicapEquipmentAbbr'?: string;
}
/**
 * 
 * @export
 * @interface GetCustomersModel
 */
export interface GetCustomersModel {
    /**
     * Identifikátor zákazníka.
     * @type {number}
     * @memberof GetCustomersModel
     */
    'id'?: number;
    /**
     * Celé jméno zákazníka.
     * @type {string}
     * @memberof GetCustomersModel
     */
    'fullName'?: string;
    /**
     * Název vybavení pro zdravotně postižené.
     * @type {string}
     * @memberof GetCustomersModel
     */
    'handicapEquipmentName'?: string;
    /**
     * Zkratka vybavení pro zdravotně postižené.
     * @type {string}
     * @memberof GetCustomersModel
     */
    'handicapEquipmentAbbreviation'?: string;
    /**
     * Věk zákazníka.
     * @type {number}
     * @memberof GetCustomersModel
     */
    'age'?: number;
    /**
     * Adresa zákazníka.
     * @type {string}
     * @memberof GetCustomersModel
     */
    'address'?: string;
    /**
     * Telefonní číslo zákazníka.
     * @type {string}
     * @memberof GetCustomersModel
     */
    'phoneNumber'?: string | null;
    /**
     * E-mailová adresa zákazníka.
     * @type {string}
     * @memberof GetCustomersModel
     */
    'email'?: string;
}
/**
 * 
 * @export
 * @interface GetCustomersRequest
 */
export interface GetCustomersRequest {
}
/**
 * 
 * @export
 * @interface GetCustomersResponse
 */
export interface GetCustomersResponse {
    /**
     * Kolekce dat v odpovědi z datové tabulky.
     * @type {Array<GetCustomersModel>}
     * @memberof GetCustomersResponse
     */
    'data'?: Array<GetCustomersModel>;
    /**
     * Celkový počet záznamů v datové tabulce.
     * @type {number}
     * @memberof GetCustomersResponse
     */
    'total'?: number;
}
/**
 * 
 * @export
 * @interface GetCutomerAuditRequest
 */
export interface GetCutomerAuditRequest {
}
/**
 * 
 * @export
 * @interface GetCutomerAuditResponse
 */
export interface GetCutomerAuditResponse {
    /**
     * Kolekce dat v odpovědi z datové tabulky.
     * @type {Array<AuditPropertyModel>}
     * @memberof GetCutomerAuditResponse
     */
    'data'?: Array<AuditPropertyModel>;
    /**
     * Celkový počet záznamů v datové tabulce.
     * @type {number}
     * @memberof GetCutomerAuditResponse
     */
    'total'?: number;
}
/**
 * 
 * @export
 * @interface GetDriverCurrentLogShiftResponse
 */
export interface GetDriverCurrentLogShiftResponse {
    /**
     * ID směny řidiče
     * @type {number}
     * @memberof GetDriverCurrentLogShiftResponse
     */
    'driverShiftId'?: number | null;
    /**
     * ID směny vozidla
     * @type {number}
     * @memberof GetDriverCurrentLogShiftResponse
     */
    'vehicleShiftId'?: number | null;
    /**
     * ID aktuální jízdy
     * @type {number}
     * @memberof GetDriverCurrentLogShiftResponse
     */
    'currentRideId'?: number | null;
    /**
     * Pozastaveno
     * @type {boolean}
     * @memberof GetDriverCurrentLogShiftResponse
     */
    'paused'?: boolean;
}
/**
 * 
 * @export
 * @interface GetDriverStartableShiftsResponse
 */
export interface GetDriverStartableShiftsResponse {
    /**
     * Seznam startovatelných směn
     * @type {Array<StartableShift>}
     * @memberof GetDriverStartableShiftsResponse
     */
    'startableShifts'?: Array<StartableShift>;
}
/**
 * 
 * @export
 * @interface GetHandicapEquipmentResponse
 */
export interface GetHandicapEquipmentResponse {
    /**
     * 
     * @type {Array<HandicapEquipment>}
     * @memberof GetHandicapEquipmentResponse
     */
    'equipments'?: Array<HandicapEquipment>;
}
/**
 * 
 * @export
 * @interface GetMapVehiclesResponse
 */
export interface GetMapVehiclesResponse {
    /**
     * 
     * @type {Array<GPSDozorVehicleInformationModel>}
     * @memberof GetMapVehiclesResponse
     */
    'vehicleInformationModels'?: Array<GPSDozorVehicleInformationModel>;
}
/**
 * 
 * @export
 * @interface GetOperatingDayCalendarResponse
 */
export interface GetOperatingDayCalendarResponse {
    /**
     * Seznam provozních dní
     * @type {Array<OperatingDayCalendar>}
     * @memberof GetOperatingDayCalendarResponse
     */
    'days'?: Array<OperatingDayCalendar>;
}
/**
 * 
 * @export
 * @interface GetOperatingDaysRequest
 */
export interface GetOperatingDaysRequest {
}
/**
 * 
 * @export
 * @interface GetOrderInstanceModel
 */
export interface GetOrderInstanceModel {
    /**
     * Id instance objednávky
     * @type {number}
     * @memberof GetOrderInstanceModel
     */
    'id'?: number;
    /**
     * Id jízdy
     * @type {number}
     * @memberof GetOrderInstanceModel
     */
    'rideId'?: number;
    /**
     * Id instance slotu
     * @type {number}
     * @memberof GetOrderInstanceModel
     */
    'slotInstanceId'?: number | null;
    /**
     * Název slotu
     * @type {string}
     * @memberof GetOrderInstanceModel
     */
    'slotName'?: string | null;
    /**
     * Čas vyzvednutí
     * @type {string}
     * @memberof GetOrderInstanceModel
     */
    'pickup'?: string;
    /**
     * Čas vyložení
     * @type {string}
     * @memberof GetOrderInstanceModel
     */
    'dropoff'?: string;
    /**
     * Adresa odkud
     * @type {string}
     * @memberof GetOrderInstanceModel
     */
    'addressFrom'?: string | null;
    /**
     * Adresa kam
     * @type {string}
     * @memberof GetOrderInstanceModel
     */
    'addressTo'?: string | null;
    /**
     * Jméno zákazníka
     * @type {string}
     * @memberof GetOrderInstanceModel
     */
    'customerName'?: string;
    /**
     * Celé jméno zákazníka
     * @type {string}
     * @memberof GetOrderInstanceModel
     */
    'customerFullName'?: string;
    /**
     * Datum narození zákazníka
     * @type {string}
     * @memberof GetOrderInstanceModel
     */
    'customerCardNumber'?: string | null;
    /**
     * 
     * @type {GetOrderInstanceModelAddressBillingZone}
     * @memberof GetOrderInstanceModel
     */
    'addressBillingZone'?: GetOrderInstanceModelAddressBillingZone | null;
    /**
     * Datum narození zákazníka
     * @type {string}
     * @memberof GetOrderInstanceModel
     */
    'customerDateOfBirth'?: string;
    /**
     * Věk zákazníka
     * @type {number}
     * @memberof GetOrderInstanceModel
     */
    'customerAge'?: number;
    /**
     * 
     * @type {GetOrderInstanceModelEscort}
     * @memberof GetOrderInstanceModel
     */
    'escort'?: GetOrderInstanceModelEscort | null;
    /**
     * ID pomůcky pro handicapované
     * @type {number}
     * @memberof GetOrderInstanceModel
     */
    'handicapEquipmentId'?: number;
    /**
     * Pomůcka pro handicapované
     * @type {string}
     * @memberof GetOrderInstanceModel
     */
    'equipment'?: string;
    /**
     * Id doprovodné pomůcky
     * @type {number}
     * @memberof GetOrderInstanceModel
     */
    'escortHandicapEquipmentId'?: number;
    /**
     * Zkratka doprovodné pomůcky
     * @type {string}
     * @memberof GetOrderInstanceModel
     */
    'escortHandicapEquipmentAbbr'?: string;
    /**
     * Telefonní číslo zákazníka
     * @type {string}
     * @memberof GetOrderInstanceModel
     */
    'customerPhoneNumber'?: string;
    /**
     * Doprovodná pomůcka
     * @type {string}
     * @memberof GetOrderInstanceModel
     */
    'escortEquipment'?: string | null;
    /**
     * Popis handicapu zákazníka
     * @type {string}
     * @memberof GetOrderInstanceModel
     */
    'customerHandicapDescription'?: string;
    /**
     * Přítomnost doprovodného psa.
     * @type {boolean}
     * @memberof GetOrderInstanceModel
     */
    'escortDog'?: boolean;
    /**
     * Příruční zavazadlo
     * @type {boolean}
     * @memberof GetOrderInstanceModel
     */
    'escortLuggage'?: boolean;
    /**
     * Interní poznámka
     * @type {string}
     * @memberof GetOrderInstanceModel
     */
    'noteInternal'?: string;
    /**
     * Poznámka pro řidiče
     * @type {string}
     * @memberof GetOrderInstanceModel
     */
    'noteDriver'?: string;
    /**
     * Poznámka objednávky
     * @type {string}
     * @memberof GetOrderInstanceModel
     */
    'orderInstanceNote'?: string | null;
    /**
     * Id dne objednávky
     * @type {number}
     * @memberof GetOrderInstanceModel
     */
    'orderDayId'?: number;
    /**
     * Den v týdnu
     * @type {number}
     * @memberof GetOrderInstanceModel
     */
    'weekday'?: number | null;
    /**
     * Id objednávky
     * @type {number}
     * @memberof GetOrderInstanceModel
     */
    'orderId'?: number;
    /**
     * Id zákazníka
     * @type {number}
     * @memberof GetOrderInstanceModel
     */
    'customerId'?: number;
    /**
     * 
     * @type {GetOrderInstanceModelOrderType}
     * @memberof GetOrderInstanceModel
     */
    'orderType'?: GetOrderInstanceModelOrderType;
    /**
     * 
     * @type {GetOrderInstanceModelState}
     * @memberof GetOrderInstanceModel
     */
    'state'?: GetOrderInstanceModelState;
    /**
     * Datum zrušení
     * @type {string}
     * @memberof GetOrderInstanceModel
     */
    'dateCancelled'?: string | null;
    /**
     * 
     * @type {GetOrderInstanceModelCancellationType}
     * @memberof GetOrderInstanceModel
     */
    'cancellationType'?: GetOrderInstanceModelCancellationType | null;
    /**
     * Poplatek za jízdu
     * @type {number}
     * @memberof GetOrderInstanceModel
     */
    'customerFee'?: number | null;
    /**
     * Počet doprovodů nad rámec
     * @type {number}
     * @memberof GetOrderInstanceModel
     */
    'extraEscort'?: number;
}
/**
 * @type GetOrderInstanceModelAddressBillingZone
 * Zóna adresy trvalého bydliště zákazníka
 * @export
 */
export type GetOrderInstanceModelAddressBillingZone = BillingZone;

/**
 * @type GetOrderInstanceModelCancellationType
 * Typ zrušení
 * @export
 */
export type GetOrderInstanceModelCancellationType = OrderInstanceCancellationType;

/**
 * @type GetOrderInstanceModelEscort
 * Typ doprovodu
 * @export
 */
export type GetOrderInstanceModelEscort = Escort;

/**
 * @type GetOrderInstanceModelOrderType
 * Typ objednávky
 * @export
 */
export type GetOrderInstanceModelOrderType = OrderType;

/**
 * @type GetOrderInstanceModelState
 * Stav objednávky
 * @export
 */
export type GetOrderInstanceModelState = OrderInstanceState;

/**
 * 
 * @export
 * @interface GetOrderInstancesRequest
 */
export interface GetOrderInstancesRequest {
}
/**
 * 
 * @export
 * @interface GetOrderResponse
 */
export interface GetOrderResponse {
    /**
     * ID objednávky.
     * @type {number}
     * @memberof GetOrderResponse
     */
    'id'?: number;
    /**
     * 
     * @type {AcceptanceType}
     * @memberof GetOrderResponse
     */
    'acceptanceType': AcceptanceType;
    /**
     * ID zákazníka.
     * @type {number}
     * @memberof GetOrderResponse
     */
    'customerId': number;
    /**
     * 
     * @type {OrderModelEscort}
     * @memberof GetOrderResponse
     */
    'escort'?: OrderModelEscort;
    /**
     * ID doprovodné pomůcky pro handicapovaného.
     * @type {number}
     * @memberof GetOrderResponse
     */
    'escortHandicapEquipmentId'?: number | null;
    /**
     * Přítomnost doprovodného psa.
     * @type {boolean}
     * @memberof GetOrderResponse
     */
    'escortDog'?: boolean;
    /**
     * Přítomnost zavazadel.
     * @type {boolean}
     * @memberof GetOrderResponse
     */
    'escortLuggage'?: boolean;
    /**
     * 
     * @type {OrderModelAddressFrom}
     * @memberof GetOrderResponse
     */
    'addressFrom'?: OrderModelAddressFrom | null;
    /**
     * 
     * @type {OrderModelAddressTo}
     * @memberof GetOrderResponse
     */
    'addressTo'?: OrderModelAddressTo | null;
    /**
     * 
     * @type {OrderModelOrderType}
     * @memberof GetOrderResponse
     */
    'orderType'?: OrderModelOrderType;
    /**
     * 
     * @type {OrderModelRepetitionFrequency}
     * @memberof GetOrderResponse
     */
    'repetitionFrequency'?: OrderModelRepetitionFrequency;
    /**
     * 
     * @type {OrderModelState}
     * @memberof GetOrderResponse
     */
    'state'?: OrderModelState;
    /**
     * Identifikátor slot instance pro odběr.
     * @type {number}
     * @memberof GetOrderResponse
     */
    'slotInstanceId'?: number | null;
    /**
     * Identifikátor slot instance pro doručení.
     * @type {number}
     * @memberof GetOrderResponse
     */
    'slotInstanceReturnId'?: number | null;
    /**
     * Datum.
     * @type {string}
     * @memberof GetOrderResponse
     */
    'date': string;
    /**
     * Datum do.
     * @type {string}
     * @memberof GetOrderResponse
     */
    'dateTo': string;
    /**
     * Interní poznámka.
     * @type {string}
     * @memberof GetOrderResponse
     */
    'noteInternal'?: string;
    /**
     * Poznámka pro řidiče.
     * @type {string}
     * @memberof GetOrderResponse
     */
    'noteDriver'?: string;
    /**
     * Doba trvání.
     * @type {number}
     * @memberof GetOrderResponse
     */
    'duration'?: number;
    /**
     * Seznam časů objednávek.
     * @type {Array<OrderDayModel>}
     * @memberof GetOrderResponse
     */
    'times'?: Array<OrderDayModel>;
    /**
     * Indikuje, zda je možné upravit adresy v objednávce.
     * @type {boolean}
     * @memberof GetOrderResponse
     */
    'canChangeAddresses'?: boolean;
    /**
     * 
     * @type {GetCustomersModel}
     * @memberof GetOrderResponse
     */
    'customer'?: GetCustomersModel | null;
}
/**
 * 
 * @export
 * @interface GetOrderResponseAllOf
 */
export interface GetOrderResponseAllOf {
    /**
     * 
     * @type {GetCustomersModel}
     * @memberof GetOrderResponseAllOf
     */
    'customer'?: GetCustomersModel | null;
}
/**
 * 
 * @export
 * @interface GetOrdersRequest
 */
export interface GetOrdersRequest {
}
/**
 * 
 * @export
 * @interface GetOrdersResponse
 */
export interface GetOrdersResponse {
    /**
     * Kolekce dat v odpovědi z datové tabulky.
     * @type {Array<GetOrdersSingleResponse>}
     * @memberof GetOrdersResponse
     */
    'data'?: Array<GetOrdersSingleResponse>;
    /**
     * Celkový počet záznamů v datové tabulce.
     * @type {number}
     * @memberof GetOrdersResponse
     */
    'total'?: number;
}
/**
 * 
 * @export
 * @interface GetOrdersSingleResponse
 */
export interface GetOrdersSingleResponse {
    /**
     * 
     * @type {number}
     * @memberof GetOrdersSingleResponse
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetOrdersSingleResponse
     */
    'customerName'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetOrdersSingleResponse
     */
    'customerFullName'?: string;
    /**
     * 
     * @type {Array<SingleOrderHandicapEquipmentModel>}
     * @memberof GetOrdersSingleResponse
     */
    'handicapEquipments'?: Array<SingleOrderHandicapEquipmentModel>;
    /**
     * 
     * @type {string}
     * @memberof GetOrdersSingleResponse
     */
    'addressFrom'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetOrdersSingleResponse
     */
    'addressTo'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetOrdersSingleResponse
     */
    'date'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetOrdersSingleResponse
     */
    'dateTo'?: string | null;
    /**
     * 
     * @type {RideFromToTime}
     * @memberof GetOrdersSingleResponse
     */
    'time'?: RideFromToTime;
    /**
     * 
     * @type {RideFromToTime}
     * @memberof GetOrdersSingleResponse
     */
    'returnTime'?: RideFromToTime;
    /**
     * 
     * @type {Array<number>}
     * @memberof GetOrdersSingleResponse
     */
    'weekdays'?: Array<number>;
    /**
     * 
     * @type {OrderRepetitionFrequency}
     * @memberof GetOrdersSingleResponse
     */
    'repetitionFrequency'?: OrderRepetitionFrequency;
    /**
     * 
     * @type {OrderState}
     * @memberof GetOrdersSingleResponse
     */
    'state'?: OrderState;
    /**
     * 
     * @type {OrderType}
     * @memberof GetOrdersSingleResponse
     */
    'type'?: OrderType;
}
/**
 * 
 * @export
 * @interface GetRideCustomersInformationResponse
 */
export interface GetRideCustomersInformationResponse {
    /**
     * ID jízdy
     * @type {number}
     * @memberof GetRideCustomersInformationResponse
     */
    'id'?: number;
    /**
     * Kolekce zákazníků
     * @type {Array<RideCustomerModel>}
     * @memberof GetRideCustomersInformationResponse
     */
    'customers'?: Array<RideCustomerModel>;
}
/**
 * 
 * @export
 * @interface GetRideModel
 */
export interface GetRideModel {
    /**
     * ID jízdy.
     * @type {number}
     * @memberof GetRideModel
     */
    'id'?: number;
    /**
     * Seznam instancí objednávek spojených s jízdou.
     * @type {Array<GetOrderInstanceModel>}
     * @memberof GetRideModel
     */
    'orderInstances'?: Array<GetOrderInstanceModel>;
    /**
     * Seznam zastávek spojených s jízdou.
     * @type {Array<RideStopModel>}
     * @memberof GetRideModel
     */
    'stops'?: Array<RideStopModel>;
    /**
     * Datum jízdy.
     * @type {string}
     * @memberof GetRideModel
     */
    'date'?: string;
    /**
     * Datum vyzvednutí.
     * @type {string}
     * @memberof GetRideModel
     */
    'pickup'?: string;
    /**
     * Datum vyložení.
     * @type {string}
     * @memberof GetRideModel
     */
    'dropoff'?: string;
    /**
     * Indikátor, zda má jízda definovanou dobu trvání.
     * @type {boolean}
     * @memberof GetRideModel
     */
    'hasDuration'?: boolean;
    /**
     * Jméno řidiče.
     * @type {string}
     * @memberof GetRideModel
     */
    'driver'?: string;
    /**
     * Název vozidla.
     * @type {string}
     * @memberof GetRideModel
     */
    'vehicle'?: string;
    /**
     * Jméno asistenta.
     * @type {string}
     * @memberof GetRideModel
     */
    'assistant'?: string;
    /**
     * Název slotu.
     * @type {string}
     * @memberof GetRideModel
     */
    'slotName'?: string;
    /**
     * ID instance slotu.
     * @type {number}
     * @memberof GetRideModel
     */
    'slotInstanceId'?: number | null;
    /**
     * Indikátor, zda je jízda nad kapacitou.
     * @type {boolean}
     * @memberof GetRideModel
     */
    'isOverCapacity'?: boolean;
    /**
     * 
     * @type {GetRideModelState}
     * @memberof GetRideModel
     */
    'state'?: GetRideModelState;
    /**
     * 
     * @type {GetRideModelOrderType}
     * @memberof GetRideModel
     */
    'orderType'?: GetRideModelOrderType;
    /**
     * Poplatek pro zákazníka.
     * @type {number}
     * @memberof GetRideModel
     */
    'customerFee'?: number | null;
}
/**
 * @type GetRideModelOrderType
 * Typ objednávky spojený s jízdou.
 * @export
 */
export type GetRideModelOrderType = OrderType;

/**
 * @type GetRideModelState
 * Stav jízdy.
 * @export
 */
export type GetRideModelState = OrderInstanceState;

/**
 * 
 * @export
 * @interface GetRidesPlanningResponse
 */
export interface GetRidesPlanningResponse {
    /**
     * Jízdy
     * @type {Array<RidePlanningModel>}
     * @memberof GetRidesPlanningResponse
     */
    'rides'?: Array<RidePlanningModel>;
}
/**
 * 
 * @export
 * @interface GetRidesRequest
 */
export interface GetRidesRequest {
}
/**
 * 
 * @export
 * @interface GetRidesResponse
 */
export interface GetRidesResponse {
    /**
     * Kolekce dat v odpovědi z datové tabulky.
     * @type {Array<GetRideModel>}
     * @memberof GetRidesResponse
     */
    'data'?: Array<GetRideModel>;
    /**
     * Celkový počet záznamů v datové tabulce.
     * @type {number}
     * @memberof GetRidesResponse
     */
    'total'?: number;
}
/**
 * 
 * @export
 * @interface GetShiftLogsResponse
 */
export interface GetShiftLogsResponse {
    /**
     * Celkový počet ujetých kilometrů.
     * @type {number}
     * @memberof GetShiftLogsResponse
     */
    'totalMileage'?: number;
    /**
     * Celkový odhad ujetých kilometrů.
     * @type {number}
     * @memberof GetShiftLogsResponse
     */
    'totalMileageEstimate'?: number;
    /**
     * Celkový počet ujetých kilometrů zákazníky.
     * @type {number}
     * @memberof GetShiftLogsResponse
     */
    'customersMileage'?: number | null;
    /**
     * Celkový počet ujetých kilometrů v Praze.
     * @type {number}
     * @memberof GetShiftLogsResponse
     */
    'pragueMileage'?: number | null;
    /**
     * Celkový počet ujetých kilometrů mimo Prahu.
     * @type {number}
     * @memberof GetShiftLogsResponse
     */
    'otherMileage'?: number | null;
    /**
     * Celkový manuální počet ujetých kilometrů v Praze.
     * @type {number}
     * @memberof GetShiftLogsResponse
     */
    'pragueManual'?: number | null;
    /**
     * Celkový manuální počet ujetých kilometrů mimo Prahu.
     * @type {number}
     * @memberof GetShiftLogsResponse
     */
    'otherManual'?: number | null;
    /**
     * Celkový čas strávený na směnách.
     * @type {number}
     * @memberof GetShiftLogsResponse
     */
    'timeTotal'?: number | null;
    /**
     * Celkový odhadovaný čas strávený na směnách.
     * @type {number}
     * @memberof GetShiftLogsResponse
     */
    'timeTotalEstimate'?: number | null;
    /**
     * Celkový čas strávený u zákazníků.
     * @type {number}
     * @memberof GetShiftLogsResponse
     */
    'timeCustomers'?: number | null;
    /**
     * Celkový čas strávený na přejezdech.
     * @type {number}
     * @memberof GetShiftLogsResponse
     */
    'timeCrossings'?: number | null;
    /**
     * Celkový čas strávený na přestávkách.
     * @type {number}
     * @memberof GetShiftLogsResponse
     */
    'timeBreaks'?: number | null;
    /**
     * Zda jsou všechny směny dokončeny.
     * @type {boolean}
     * @memberof GetShiftLogsResponse
     */
    'allFinished'?: boolean;
    /**
     * Zda jsou všechny směny uzavřeny.
     * @type {boolean}
     * @memberof GetShiftLogsResponse
     */
    'allClosed'?: boolean;
    /**
     * Seznam logů směn.
     * @type {Array<LogShiftModel>}
     * @memberof GetShiftLogsResponse
     */
    'logs'?: Array<LogShiftModel> | null;
}
/**
 * 
 * @export
 * @interface GetShiftLogsSummaryResponse
 */
export interface GetShiftLogsSummaryResponse {
    /**
     * Celková ujetá vzdálenost.
     * @type {number}
     * @memberof GetShiftLogsSummaryResponse
     */
    'totalMileage'?: number;
    /**
     * Ujetá vzdálenost v Praze.
     * @type {number}
     * @memberof GetShiftLogsSummaryResponse
     */
    'pragueMileage'?: number | null;
    /**
     * Ujetá vzdálenost mimo Prahu.
     * @type {number}
     * @memberof GetShiftLogsSummaryResponse
     */
    'otherMileage'?: number | null;
    /**
     * Zda jsou všechny směny dokončeny.
     * @type {boolean}
     * @memberof GetShiftLogsSummaryResponse
     */
    'allFinished'?: boolean;
    /**
     * Zda jsou všechny směny uzavřeny.
     * @type {boolean}
     * @memberof GetShiftLogsSummaryResponse
     */
    'allClosed'?: boolean;
    /**
     * Seznam záznamů směn.
     * @type {Array<LogShiftSummaryModel>}
     * @memberof GetShiftLogsSummaryResponse
     */
    'logs'?: Array<LogShiftSummaryModel> | null;
}
/**
 * 
 * @export
 * @interface GetShiftModel
 */
export interface GetShiftModel {
    /**
     * Id
     * @type {number}
     * @memberof GetShiftModel
     */
    'id'?: number;
    /**
     * Id objektu
     * @type {number}
     * @memberof GetShiftModel
     */
    'objectId'?: number;
    /**
     * Počáteční datum a čas
     * @type {string}
     * @memberof GetShiftModel
     */
    'start'?: string;
    /**
     * Koncové datum a čas
     * @type {string}
     * @memberof GetShiftModel
     */
    'end'?: string;
    /**
     * Text
     * @type {string}
     * @memberof GetShiftModel
     */
    'text'?: string;
    /**
     * Překrývající se
     * @type {boolean}
     * @memberof GetShiftModel
     */
    'isOverlapping'?: boolean;
    /**
     * Odkazováno
     * @type {boolean}
     * @memberof GetShiftModel
     */
    'isReferenced'?: boolean;
    /**
     * Id typu vozidla
     * @type {number}
     * @memberof GetShiftModel
     */
    'vehicleTypeId'?: number | null;
}
/**
 * 
 * @export
 * @interface GetShiftPlanningModel
 */
export interface GetShiftPlanningModel {
    /**
     * Id
     * @type {number}
     * @memberof GetShiftPlanningModel
     */
    'id'?: number;
    /**
     * Id objektu
     * @type {number}
     * @memberof GetShiftPlanningModel
     */
    'objectId'?: number;
    /**
     * Počáteční datum a čas
     * @type {string}
     * @memberof GetShiftPlanningModel
     */
    'start'?: string;
    /**
     * Koncové datum a čas
     * @type {string}
     * @memberof GetShiftPlanningModel
     */
    'end'?: string;
    /**
     * Text
     * @type {string}
     * @memberof GetShiftPlanningModel
     */
    'text'?: string;
    /**
     * Id typu vozidla
     * @type {number}
     * @memberof GetShiftPlanningModel
     */
    'vehicleTypeId'?: number | null;
}
/**
 * 
 * @export
 * @interface GetShiftsRequest
 */
export interface GetShiftsRequest {
}
/**
 * 
 * @export
 * @interface GetShiftsResponse
 */
export interface GetShiftsResponse {
    /**
     * Kolekce dat v odpovědi z datové tabulky.
     * @type {Array<GetShiftsResponseSingle>}
     * @memberof GetShiftsResponse
     */
    'data'?: Array<GetShiftsResponseSingle>;
    /**
     * Celkový počet záznamů v datové tabulce.
     * @type {number}
     * @memberof GetShiftsResponse
     */
    'total'?: number;
}
/**
 * 
 * @export
 * @interface GetShiftsResponseSingle
 */
export interface GetShiftsResponseSingle {
    /**
     * ID směny.
     * @type {number}
     * @memberof GetShiftsResponseSingle
     */
    'id'?: number;
    /**
     * ID vozidla.
     * @type {number}
     * @memberof GetShiftsResponseSingle
     */
    'vehicleId'?: number | null;
    /**
     * Název vozidla.
     * @type {string}
     * @memberof GetShiftsResponseSingle
     */
    'vehicleName'?: string;
    /**
     * Registrační číslo vozidla.
     * @type {string}
     * @memberof GetShiftsResponseSingle
     */
    'vehicleRegistrationNumber'?: string;
    /**
     * ID řidiče.
     * @type {number}
     * @memberof GetShiftsResponseSingle
     */
    'driverId'?: number | null;
    /**
     * Jméno řidiče.
     * @type {string}
     * @memberof GetShiftsResponseSingle
     */
    'driverName'?: string;
    /**
     * ID asistenta.
     * @type {number}
     * @memberof GetShiftsResponseSingle
     */
    'assistantId'?: number | null;
    /**
     * Jméno asistenta.
     * @type {string}
     * @memberof GetShiftsResponseSingle
     */
    'assistantName'?: string;
    /**
     * ID instance slotu.
     * @type {number}
     * @memberof GetShiftsResponseSingle
     */
    'slotInstanceId'?: number;
    /**
     * Název instance slotu.
     * @type {string}
     * @memberof GetShiftsResponseSingle
     */
    'slotInstanceName'?: string;
    /**
     * 
     * @type {GetShiftsResponseSingleShiftType}
     * @memberof GetShiftsResponseSingle
     */
    'shiftType'?: GetShiftsResponseSingleShiftType;
    /**
     * Datum a čas začátku směny.
     * @type {string}
     * @memberof GetShiftsResponseSingle
     */
    'start'?: string;
    /**
     * Datum a čas konce směny.
     * @type {string}
     * @memberof GetShiftsResponseSingle
     */
    'end'?: string;
}
/**
 * @type GetShiftsResponseSingleShiftType
 * Typ směny.
 * @export
 */
export type GetShiftsResponseSingleShiftType = ShiftType;

/**
 * 
 * @export
 * @interface GetSlotInstancesPlanningRequest
 */
export interface GetSlotInstancesPlanningRequest {
}
/**
 * 
 * @export
 * @interface GetSlotInstancesRequest
 */
export interface GetSlotInstancesRequest {
}
/**
 * 
 * @export
 * @interface GetSlotInstancesRequest2
 */
export interface GetSlotInstancesRequest2 {
}
/**
 * 
 * @export
 * @interface GetSlotResponse
 */
export interface GetSlotResponse {
    /**
     * ID slotu.
     * @type {number}
     * @memberof GetSlotResponse
     */
    'id'?: number;
    /**
     * Název slotu.
     * @type {string}
     * @memberof GetSlotResponse
     */
    'name'?: string;
    /**
     * 
     * @type {GetSlotResponseOperatingDay}
     * @memberof GetSlotResponse
     */
    'operatingDay'?: GetSlotResponseOperatingDay;
    /**
     * 
     * @type {GetSlotResponseCarrier}
     * @memberof GetSlotResponse
     */
    'carrier'?: GetSlotResponseCarrier;
    /**
     * 
     * @type {GetSlotResponseVehicleType}
     * @memberof GetSlotResponse
     */
    'vehicleType'?: GetSlotResponseVehicleType;
    /**
     * Čas počátku slotu.
     * @type {string}
     * @memberof GetSlotResponse
     */
    'start'?: string;
    /**
     * Čas konce slotu.
     * @type {string}
     * @memberof GetSlotResponse
     */
    'end'?: string;
    /**
     * Čas počátku asistenta (volitelný).
     * @type {string}
     * @memberof GetSlotResponse
     */
    'assistantStart'?: string | null;
    /**
     * Čas konce asistenta (volitelný).
     * @type {string}
     * @memberof GetSlotResponse
     */
    'assistantEnd'?: string | null;
    /**
     * Pořadí slotu.
     * @type {number}
     * @memberof GetSlotResponse
     */
    'sequence'?: number;
    /**
     * Indikace aktivity slotu.
     * @type {boolean}
     * @memberof GetSlotResponse
     */
    'isActive'?: boolean;
}
/**
 * @type GetSlotResponseCarrier
 * Model dopravce.
 * @export
 */
export type GetSlotResponseCarrier = CarrierModel;

/**
 * @type GetSlotResponseOperatingDay
 * Model operačního dne.
 * @export
 */
export type GetSlotResponseOperatingDay = OperatingDayModel;

/**
 * @type GetSlotResponseVehicleType
 * Model typu vozidla.
 * @export
 */
export type GetSlotResponseVehicleType = VehicleTypeModel;

/**
 * 
 * @export
 * @interface GetSlotsRequest
 */
export interface GetSlotsRequest {
}
/**
 * 
 * @export
 * @interface GetSlotsResponse
 */
export interface GetSlotsResponse {
    /**
     * Kolekce dat v odpovědi z datové tabulky.
     * @type {Array<GetSlotResponse>}
     * @memberof GetSlotsResponse
     */
    'data'?: Array<GetSlotResponse>;
    /**
     * Celkový počet záznamů v datové tabulce.
     * @type {number}
     * @memberof GetSlotsResponse
     */
    'total'?: number;
}
/**
 * 
 * @export
 * @interface GetUserResponse
 */
export interface GetUserResponse {
    /**
     * Id
     * @type {number}
     * @memberof GetUserResponse
     */
    'id'?: number;
    /**
     * Jméno
     * @type {string}
     * @memberof GetUserResponse
     */
    'firstName': string;
    /**
     * Příjmení
     * @type {string}
     * @memberof GetUserResponse
     */
    'lastName': string;
    /**
     * Email
     * @type {string}
     * @memberof GetUserResponse
     */
    'email': string;
    /**
     * Osobní číslo
     * @type {number}
     * @memberof GetUserResponse
     */
    'personalNumber'?: number | null;
    /**
     * Telefonní číslo
     * @type {string}
     * @memberof GetUserResponse
     */
    'phoneNumber'?: string | null;
    /**
     * 
     * @type {UserModelAddress}
     * @memberof GetUserResponse
     */
    'address'?: UserModelAddress | null;
    /**
     * Id profilového obrázku
     * @type {number}
     * @memberof GetUserResponse
     */
    'avatarImageId'?: number | null;
    /**
     * Role
     * @type {Array<RoleModel>}
     * @memberof GetUserResponse
     */
    'roles': Array<RoleModel>;
    /**
     * Aktivní
     * @type {boolean}
     * @memberof GetUserResponse
     */
    'isActive'?: boolean;
    /**
     * Seznam refresh tokenů
     * @type {Array<RefreshTokenModel>}
     * @memberof GetUserResponse
     */
    'refreshTokenModels'?: Array<RefreshTokenModel>;
}
/**
 * 
 * @export
 * @interface GetUsersModel
 */
export interface GetUsersModel {
    /**
     * Id
     * @type {number}
     * @memberof GetUsersModel
     */
    'id'?: number;
    /**
     * Osobní číslo
     * @type {number}
     * @memberof GetUsersModel
     */
    'personalNumber'?: number | null;
    /**
     * Celé jméno
     * @type {string}
     * @memberof GetUsersModel
     */
    'fullName'?: string;
    /**
     * Email
     * @type {string}
     * @memberof GetUsersModel
     */
    'email'?: string;
    /**
     * Telefonní číslo
     * @type {string}
     * @memberof GetUsersModel
     */
    'phoneNumber'?: string | null;
    /**
     * Role
     * @type {Array<string>}
     * @memberof GetUsersModel
     */
    'roles'?: Array<string>;
}
/**
 * 
 * @export
 * @interface GetUsersRequest
 */
export interface GetUsersRequest {
}
/**
 * 
 * @export
 * @interface GetUsersResponse
 */
export interface GetUsersResponse {
    /**
     * Kolekce dat v odpovědi z datové tabulky.
     * @type {Array<GetUsersModel>}
     * @memberof GetUsersResponse
     */
    'data'?: Array<GetUsersModel>;
    /**
     * Celkový počet záznamů v datové tabulce.
     * @type {number}
     * @memberof GetUsersResponse
     */
    'total'?: number;
}
/**
 * 
 * @export
 * @interface GetVehicleResponse
 */
export interface GetVehicleResponse {
    /**
     * ID vozidla.
     * @type {number}
     * @memberof GetVehicleResponse
     */
    'id'?: number;
    /**
     * Název vozidla.
     * @type {string}
     * @memberof GetVehicleResponse
     */
    'name'?: string;
    /**
     * Typ vozidla.
     * @type {string}
     * @memberof GetVehicleResponse
     */
    'type'?: string;
    /**
     * ID typu vozidla
     * @type {number}
     * @memberof GetVehicleResponse
     */
    'typeId'?: number;
    /**
     * Registrační značka vozidla.
     * @type {string}
     * @memberof GetVehicleResponse
     */
    'plateNumber'?: string;
    /**
     * Registrační číslo
     * @type {number}
     * @memberof GetVehicleResponse
     */
    'registrationNumber'?: number;
    /**
     * VIN vozidla.
     * @type {string}
     * @memberof GetVehicleResponse
     */
    'vin'?: string;
    /**
     * Telefonní číslo vozidla.
     * @type {string}
     * @memberof GetVehicleResponse
     */
    'phoneNumber'?: string;
    /**
     * 
     * @type {GetVehicleResponseCarrier}
     * @memberof GetVehicleResponse
     */
    'carrier'?: GetVehicleResponseCarrier | null;
    /**
     * Určuje, zda je vozidlo aktivní či ne.
     * @type {boolean}
     * @memberof GetVehicleResponse
     */
    'isActive'?: boolean;
    /**
     * Kód pro GPS Dozor.
     * @type {string}
     * @memberof GetVehicleResponse
     */
    'gpsDozorCode'?: string;
}
/**
 * @type GetVehicleResponseCarrier
 * Informace o dopravci, ke kterému vozidlo patří.
 * @export
 */
export type GetVehicleResponseCarrier = CarrierModel;

/**
 * 
 * @export
 * @interface GetVehiclesRequest
 */
export interface GetVehiclesRequest {
}
/**
 * 
 * @export
 * @interface GetVehiclesResponse
 */
export interface GetVehiclesResponse {
    /**
     * Kolekce dat v odpovědi z datové tabulky.
     * @type {Array<GetVehiclesSingleResponse>}
     * @memberof GetVehiclesResponse
     */
    'data'?: Array<GetVehiclesSingleResponse>;
    /**
     * Celkový počet záznamů v datové tabulce.
     * @type {number}
     * @memberof GetVehiclesResponse
     */
    'total'?: number;
}
/**
 * 
 * @export
 * @interface GetVehiclesSingleResponse
 */
export interface GetVehiclesSingleResponse {
    /**
     * ID vozidla.
     * @type {number}
     * @memberof GetVehiclesSingleResponse
     */
    'id'?: number;
    /**
     * Název vozidla.
     * @type {string}
     * @memberof GetVehiclesSingleResponse
     */
    'name'?: string;
    /**
     * Typ vozidla.
     * @type {string}
     * @memberof GetVehiclesSingleResponse
     */
    'type'?: string;
    /**
     * ID typu vozidla.
     * @type {number}
     * @memberof GetVehiclesSingleResponse
     */
    'typeId'?: number;
    /**
     * Početní značka vozidla.
     * @type {string}
     * @memberof GetVehiclesSingleResponse
     */
    'plateNumber'?: string;
    /**
     * Registrační číslo vozidla.
     * @type {number}
     * @memberof GetVehiclesSingleResponse
     */
    'registrationNumber'?: number;
    /**
     * VIN (Vehicle Identification Number) vozidla.
     * @type {string}
     * @memberof GetVehiclesSingleResponse
     */
    'vin'?: string;
    /**
     * Telefonní číslo spojené s vozidlem.
     * @type {string}
     * @memberof GetVehiclesSingleResponse
     */
    'phoneNumber'?: string;
    /**
     * Zkratka přepravce spojená s vozidlem.
     * @type {string}
     * @memberof GetVehiclesSingleResponse
     */
    'carrierAbbreviation'?: string;
    /**
     * Kód GPS Dozor pro vozidlo.
     * @type {string}
     * @memberof GetVehiclesSingleResponse
     */
    'gpsDozorCode'?: string;
    /**
     * Jedinečný identifikátor přepravce.
     * @type {number}
     * @memberof GetVehiclesSingleResponse
     */
    'carrierId'?: number;
    /**
     * Příznak označující, zda je vozidlo aktivní nebo ne.
     * @type {boolean}
     * @memberof GetVehiclesSingleResponse
     */
    'isActive'?: boolean;
}
/**
 * 
 * @export
 * @interface HandicapEquipment
 */
export interface HandicapEquipment {
    /**
     * 
     * @type {number}
     * @memberof HandicapEquipment
     */
    'id'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof HandicapEquipment
     */
    'isDeleted'?: boolean;
    /**
     * Název pomůcky
     * @type {string}
     * @memberof HandicapEquipment
     */
    'name'?: string;
    /**
     * Zkratka názvu pomůcky
     * @type {string}
     * @memberof HandicapEquipment
     */
    'abbreviation'?: string;
}
/**
 * 
 * @export
 * @interface HandicapEquipmentAllOf
 */
export interface HandicapEquipmentAllOf {
    /**
     * Název pomůcky
     * @type {string}
     * @memberof HandicapEquipmentAllOf
     */
    'name'?: string;
    /**
     * Zkratka názvu pomůcky
     * @type {string}
     * @memberof HandicapEquipmentAllOf
     */
    'abbreviation'?: string;
}
/**
 * 
 * @export
 * @interface JoinRidesRequest
 */
export interface JoinRidesRequest {
    /**
     * Seznam identifikátorů jízd, které se mají sloučit.
     * @type {Array<number>}
     * @memberof JoinRidesRequest
     */
    'rideIds': Array<number>;
    /**
     * Seznam zastávek a pořadí, jakým se mají navštívit v rámci sloučení jízd.
     * @type {Array<StopModel>}
     * @memberof JoinRidesRequest
     */
    'stops': Array<StopModel>;
    /**
     * 
     * @type {JoinRidesRequestType}
     * @memberof JoinRidesRequest
     */
    'type'?: JoinRidesRequestType;
    /**
     * Délka trvání jízdy po lsoučení v minutách.
     * @type {number}
     * @memberof JoinRidesRequest
     */
    'duration': number;
}
/**
 * @type JoinRidesRequestType
 * Typ sloučení jízd.
 * @export
 */
export type JoinRidesRequestType = JoinType;

/**
 * 
 * @export
 * @interface JoinRidesResponse
 */
export interface JoinRidesResponse {
    /**
     * Počet jízd, které byly úspěšně sloučeny.
     * @type {number}
     * @memberof JoinRidesResponse
     */
    'affected'?: number;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const JoinType = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2
} as const;

export type JoinType = typeof JoinType[keyof typeof JoinType];


/**
 * 
 * @export
 * @interface JwtToken
 */
export interface JwtToken {
    /**
     * 
     * @type {string}
     * @memberof JwtToken
     */
    'value'?: string;
    /**
     * 
     * @type {string}
     * @memberof JwtToken
     */
    'expiryDate'?: string;
}
/**
 * 
 * @export
 * @interface LastPosition
 */
export interface LastPosition {
    /**
     * Zeměpisná šířka pozice
     * @type {string}
     * @memberof LastPosition
     */
    'latitude'?: string;
    /**
     * Zeměpisná délka pozice
     * @type {string}
     * @memberof LastPosition
     */
    'longitude'?: string;
}
/**
 * 
 * @export
 * @interface LogShiftEventEstimatesModel
 */
export interface LogShiftEventEstimatesModel {
    /**
     * ID
     * @type {number}
     * @memberof LogShiftEventEstimatesModel
     */
    'id'?: number;
    /**
     * Odhadovaný čas
     * @type {number}
     * @memberof LogShiftEventEstimatesModel
     */
    'estimatedTime'?: number;
    /**
     * Odhadovaná vzdálenost
     * @type {number}
     * @memberof LogShiftEventEstimatesModel
     */
    'estimatedMileage'?: number;
}
/**
 * 
 * @export
 * @interface LogShiftEventManualModel
 */
export interface LogShiftEventManualModel {
    /**
     * ID
     * @type {number}
     * @memberof LogShiftEventManualModel
     */
    'id'?: number;
    /**
     * Manuálně zadaná vzdálenost v Praze
     * @type {number}
     * @memberof LogShiftEventManualModel
     */
    'pragueManual'?: number | null;
    /**
     * Manuálně zadaná vzdálenost mimo Prahu
     * @type {number}
     * @memberof LogShiftEventManualModel
     */
    'otherManual'?: number | null;
}
/**
 * 
 * @export
 * @interface LogShiftEventModel
 */
export interface LogShiftEventModel {
    /**
     * Id
     * @type {number}
     * @memberof LogShiftEventModel
     */
    'id'?: number;
    /**
     * Odhadovaný datum
     * @type {string}
     * @memberof LogShiftEventModel
     */
    'estimatedDateTime'?: string | null;
    /**
     * Datum
     * @type {string}
     * @memberof LogShiftEventModel
     */
    'dateTime'?: string;
    /**
     * Id instance objednávky 
     * @type {number}
     * @memberof LogShiftEventModel
     */
    'orderInstanceId'?: number | null;
    /**
     * Id jízdy
     * @type {number}
     * @memberof LogShiftEventModel
     */
    'rideId'?: number | null;
    /**
     * 
     * @type {UserModelAddress}
     * @memberof LogShiftEventModel
     */
    'address'?: UserModelAddress | null;
    /**
     * 
     * @type {LogShiftEventModelCustomerZone}
     * @memberof LogShiftEventModel
     */
    'customerZone'?: LogShiftEventModelCustomerZone | null;
    /**
     * 
     * @type {LogShiftEventModelEventType}
     * @memberof LogShiftEventModel
     */
    'eventType'?: LogShiftEventModelEventType;
    /**
     * Zeměpisná šířka
     * @type {number}
     * @memberof LogShiftEventModel
     */
    'lat'?: number | null;
    /**
     * Zeměpisná délka
     * @type {number}
     * @memberof LogShiftEventModel
     */
    'lng'?: number | null;
    /**
     * Odometer
     * @type {number}
     * @memberof LogShiftEventModel
     */
    'odometer'?: number | null;
    /**
     * Obsazený
     * @type {boolean}
     * @memberof LogShiftEventModel
     */
    'occupied'?: boolean;
    /**
     * Odhadovaná vzdálenost
     * @type {number}
     * @memberof LogShiftEventModel
     */
    'estimatedMileage'?: number;
    /**
     * Skutečná vzdálenost
     * @type {number}
     * @memberof LogShiftEventModel
     */
    'realMileage'?: number | null;
    /**
     * Odhadovaný čas
     * @type {number}
     * @memberof LogShiftEventModel
     */
    'estimatedTime'?: number;
    /**
     * Skutečný čas
     * @type {number}
     * @memberof LogShiftEventModel
     */
    'realTime'?: number | null;
    /**
     * Odhadovaná vzdálenost Prahy
     * @type {number}
     * @memberof LogShiftEventModel
     */
    'pragueMileage'?: number | null;
    /**
     * Odhadovaná vzdálenost ostatních míst
     * @type {number}
     * @memberof LogShiftEventModel
     */
    'otherMileage'?: number | null;
    /**
     * Manuálně zadaná vzdálenost v Praze
     * @type {number}
     * @memberof LogShiftEventModel
     */
    'pragueManual'?: number | null;
    /**
     * Manuálně zadaná vzdálenost mimo Prahu
     * @type {number}
     * @memberof LogShiftEventModel
     */
    'otherManual'?: number | null;
    /**
     * Text
     * @type {string}
     * @memberof LogShiftEventModel
     */
    'text'?: string;
    /**
     * Zákazník
     * @type {string}
     * @memberof LogShiftEventModel
     */
    'customer'?: string;
}
/**
 * @type LogShiftEventModelCustomerZone
 * Zóna zákazníka
 * @export
 */
export type LogShiftEventModelCustomerZone = BillingZone;

/**
 * @type LogShiftEventModelEventType
 * Typ události
 * @export
 */
export type LogShiftEventModelEventType = EventType;

/**
 * 
 * @export
 * @interface LogShiftEventSummaryModel
 */
export interface LogShiftEventSummaryModel {
    /**
     * Id
     * @type {number}
     * @memberof LogShiftEventSummaryModel
     */
    'id'?: number;
    /**
     * Datum a čas
     * @type {string}
     * @memberof LogShiftEventSummaryModel
     */
    'dateTime'?: string;
    /**
     * Id instance objednávky
     * @type {number}
     * @memberof LogShiftEventSummaryModel
     */
    'orderInstanceId'?: number | null;
    /**
     * Id instance jízdy
     * @type {number}
     * @memberof LogShiftEventSummaryModel
     */
    'rideId'?: number | null;
    /**
     * 
     * @type {LogShiftEventModelCustomerZone}
     * @memberof LogShiftEventSummaryModel
     */
    'customerZone'?: LogShiftEventModelCustomerZone | null;
    /**
     * 
     * @type {LogShiftEventModelEventType}
     * @memberof LogShiftEventSummaryModel
     */
    'eventType'?: LogShiftEventModelEventType;
    /**
     * Zeměpisná šířka
     * @type {number}
     * @memberof LogShiftEventSummaryModel
     */
    'lat'?: number | null;
    /**
     * Zeměpisná délka
     * @type {number}
     * @memberof LogShiftEventSummaryModel
     */
    'lng'?: number | null;
    /**
     * Odometer
     * @type {number}
     * @memberof LogShiftEventSummaryModel
     */
    'odometer'?: number | null;
}
/**
 * 
 * @export
 * @interface LogShiftModel
 */
export interface LogShiftModel {
    /**
     * Identifikátor směny řidiče
     * @type {number}
     * @memberof LogShiftModel
     */
    'driverShiftId'?: number;
    /**
     * Identifikátor směny vozidla
     * @type {number}
     * @memberof LogShiftModel
     */
    'vehicleShiftId'?: number;
    /**
     * Id řidiče
     * @type {number}
     * @memberof LogShiftModel
     */
    'driverId'?: number;
    /**
     * Jméno řidiče
     * @type {string}
     * @memberof LogShiftModel
     */
    'driverFirstName'?: string;
    /**
     * Příjmení řidiče
     * @type {string}
     * @memberof LogShiftModel
     */
    'driverLastName'?: string;
    /**
     * Id vozidla
     * @type {number}
     * @memberof LogShiftModel
     */
    'vehicleId'?: number;
    /**
     * Název vozidla
     * @type {string}
     * @memberof LogShiftModel
     */
    'vehicleName'?: string;
    /**
     * Registrační číslo vozidla
     * @type {string}
     * @memberof LogShiftModel
     */
    'vehicleNumber'?: string;
    /**
     * GPS Dozor kód vozidla
     * @type {string}
     * @memberof LogShiftModel
     */
    'vehicleGpsDozorCode'?: string;
    /**
     * Id aktuální jízdy
     * @type {number}
     * @memberof LogShiftModel
     */
    'currentRideId'?: number | null;
    /**
     * Směna dokončena
     * @type {boolean}
     * @memberof LogShiftModel
     */
    'finished'?: boolean;
    /**
     * Směna pozastavena
     * @type {boolean}
     * @memberof LogShiftModel
     */
    'paused'?: boolean;
    /**
     * Směna uzavřena
     * @type {boolean}
     * @memberof LogShiftModel
     */
    'closed'?: boolean;
    /**
     * Simulovaná směna
     * @type {boolean}
     * @memberof LogShiftModel
     */
    'simulated'?: boolean;
    /**
     * Datum a čas zahájení směny
     * @type {string}
     * @memberof LogShiftModel
     */
    'start'?: string | null;
    /**
     * Datum a čas ukončení směny
     * @type {string}
     * @memberof LogShiftModel
     */
    'end'?: string | null;
    /**
     * Celková ujetá vzdálenost
     * @type {number}
     * @memberof LogShiftModel
     */
    'totalMileage'?: number;
    /**
     * Celková odhadovaná vzdálenost
     * @type {number}
     * @memberof LogShiftModel
     */
    'totalMileageEstimate'?: number;
    /**
     * Ujetá vzdálenost s cestujícími
     * @type {number}
     * @memberof LogShiftModel
     */
    'customersMileage'?: number | null;
    /**
     * Ujetá vzdálenost v Praze
     * @type {number}
     * @memberof LogShiftModel
     */
    'pragueMileage'?: number | null;
    /**
     * Ujetá vzdálenost mimo Prahu
     * @type {number}
     * @memberof LogShiftModel
     */
    'otherMileage'?: number | null;
    /**
     * Manuálně zadaná vzdálenost v Praze
     * @type {number}
     * @memberof LogShiftModel
     */
    'pragueManual'?: number | null;
    /**
     * Manuálně zadaná vzdálenost mimo Prahu
     * @type {number}
     * @memberof LogShiftModel
     */
    'otherManual'?: number | null;
    /**
     * Čas celkem
     * @type {number}
     * @memberof LogShiftModel
     */
    'timeTotal'?: number | null;
    /**
     * Celkový odhad času
     * @type {number}
     * @memberof LogShiftModel
     */
    'timeTotalEstimate'?: number | null;
    /**
     * Celkový čas s cestujícími
     * @type {number}
     * @memberof LogShiftModel
     */
    'timeCustomers'?: number | null;
    /**
     * Celkový odhad času strávený přejezdy
     * @type {number}
     * @memberof LogShiftModel
     */
    'timeCrossings'?: number | null;
    /**
     * Celkový odhad času strávený přestávkami
     * @type {number}
     * @memberof LogShiftModel
     */
    'timeBreaks'?: number | null;
    /**
     * Celkový odhad času
     * @type {number}
     * @memberof LogShiftModel
     */
    'totalEstimate'?: number | null;
    /**
     * Překročení limitu odchylky odhadovaného času
     * @type {boolean}
     * @memberof LogShiftModel
     */
    'deviationOverLimit'?: boolean;
    /**
     * 
     * @type {LogShiftModelSlotInstance}
     * @memberof LogShiftModel
     */
    'slotInstance'?: LogShiftModelSlotInstance;
    /**
     * Seznam událostí v logu
     * @type {Array<LogShiftEventModel>}
     * @memberof LogShiftModel
     */
    'events'?: Array<LogShiftEventModel>;
}
/**
 * @type LogShiftModelSlotInstance
 * Instance slotu
 * @export
 */
export type LogShiftModelSlotInstance = SlotInstanceShiftLogModel;

/**
 * 
 * @export
 * @interface LogShiftSummaryModel
 */
export interface LogShiftSummaryModel {
    /**
     * Id řidičovy směny
     * @type {number}
     * @memberof LogShiftSummaryModel
     */
    'driverShiftId'?: number;
    /**
     * Id směny vozidla
     * @type {number}
     * @memberof LogShiftSummaryModel
     */
    'vehicleShiftId'?: number;
    /**
     * Id řidiče
     * @type {number}
     * @memberof LogShiftSummaryModel
     */
    'driverId'?: number;
    /**
     * Křestní jméno řidiče
     * @type {string}
     * @memberof LogShiftSummaryModel
     */
    'driverFirstName'?: string;
    /**
     * Příjmení řidiče
     * @type {string}
     * @memberof LogShiftSummaryModel
     */
    'driverLastName'?: string;
    /**
     * Id vozidla
     * @type {number}
     * @memberof LogShiftSummaryModel
     */
    'vehicleId'?: number;
    /**
     * Název vozidla
     * @type {string}
     * @memberof LogShiftSummaryModel
     */
    'vehicleName'?: string;
    /**
     * Registrační číslo vozidla
     * @type {string}
     * @memberof LogShiftSummaryModel
     */
    'vehicleNumber'?: string;
    /**
     * Směna dokončena
     * @type {boolean}
     * @memberof LogShiftSummaryModel
     */
    'finished'?: boolean;
    /**
     * Směna pozastavena
     * @type {boolean}
     * @memberof LogShiftSummaryModel
     */
    'paused'?: boolean;
    /**
     * Směna uzavřena
     * @type {boolean}
     * @memberof LogShiftSummaryModel
     */
    'closed'?: boolean;
    /**
     * Simulovaná směna
     * @type {boolean}
     * @memberof LogShiftSummaryModel
     */
    'simulated'?: boolean;
    /**
     * Začátek směny
     * @type {string}
     * @memberof LogShiftSummaryModel
     */
    'start'?: string | null;
    /**
     * Konec směny
     * @type {string}
     * @memberof LogShiftSummaryModel
     */
    'end'?: string | null;
    /**
     * Plánovaný začátek směny
     * @type {string}
     * @memberof LogShiftSummaryModel
     */
    'startPlanned'?: string | null;
    /**
     * Plánovaný konec směny
     * @type {string}
     * @memberof LogShiftSummaryModel
     */
    'endPlanned'?: string | null;
    /**
     * Celková ujetá vzdálenost
     * @type {number}
     * @memberof LogShiftSummaryModel
     */
    'totalMileage'?: number | null;
    /**
     * Ujetá vzdálenost v Praze
     * @type {number}
     * @memberof LogShiftSummaryModel
     */
    'pragueMileage'?: number | null;
    /**
     * Ujetá vzdálenost mimo Prahu
     * @type {number}
     * @memberof LogShiftSummaryModel
     */
    'otherMileage'?: number | null;
    /**
     * Celkový odhadovaný čas
     * @type {number}
     * @memberof LogShiftSummaryModel
     */
    'totalEstimate'?: number | null;
    /**
     * Překročení limitu odchylky
     * @type {boolean}
     * @memberof LogShiftSummaryModel
     */
    'deviationOverLimit'?: boolean;
    /**
     * Seznam událostí směny
     * @type {Array<LogShiftEventSummaryModel>}
     * @memberof LogShiftSummaryModel
     */
    'events'?: Array<LogShiftEventSummaryModel>;
    /**
     * 
     * @type {LogShiftModelSlotInstance}
     * @memberof LogShiftSummaryModel
     */
    'slotInstance'?: LogShiftModelSlotInstance;
}
/**
 * 
 * @export
 * @interface LoginRequest
 */
export interface LoginRequest {
    /**
     * Email
     * @type {string}
     * @memberof LoginRequest
     */
    'email': string;
    /**
     * Heslo
     * @type {string}
     * @memberof LoginRequest
     */
    'password': string;
}
/**
 * 
 * @export
 * @interface LoginResponse
 */
export interface LoginResponse {
    /**
     * ID uživatele
     * @type {number}
     * @memberof LoginResponse
     */
    'id'?: number;
    /**
     * Křestní jméno
     * @type {string}
     * @memberof LoginResponse
     */
    'firstName'?: string;
    /**
     * Příjmení
     * @type {string}
     * @memberof LoginResponse
     */
    'lastName'?: string;
    /**
     * ID profilového obrázku
     * @type {number}
     * @memberof LoginResponse
     */
    'avatarImageId'?: number | null;
    /**
     * Celé jméno
     * @type {string}
     * @memberof LoginResponse
     */
    'fullName'?: string;
    /**
     * 
     * @type {LoginResponseToken}
     * @memberof LoginResponse
     */
    'token'?: LoginResponseToken;
    /**
     * 
     * @type {LoginResponseRefreshToken}
     * @memberof LoginResponse
     */
    'refreshToken'?: LoginResponseRefreshToken;
}
/**
 * @type LoginResponseRefreshToken
 * Obnovení tokenu
 * @export
 */
export type LoginResponseRefreshToken = RefreshToken;

/**
 * @type LoginResponseToken
 * JWT token
 * @export
 */
export type LoginResponseToken = JwtToken;

/**
 * 
 * @export
 * @interface OfFAnonymousType2OfListOfOperatingDayModelAndInt32
 */
export interface OfFAnonymousType2OfListOfOperatingDayModelAndInt32 {
    /**
     * 
     * @type {Array<OperatingDayModel>}
     * @memberof OfFAnonymousType2OfListOfOperatingDayModelAndInt32
     */
    'data'?: Array<OperatingDayModel> | null;
    /**
     * 
     * @type {number}
     * @memberof OfFAnonymousType2OfListOfOperatingDayModelAndInt32
     */
    'total'?: number;
}
/**
 * 
 * @export
 * @interface OperatingDayCalendar
 */
export interface OperatingDayCalendar {
    /**
     * ID provozního dne
     * @type {number}
     * @memberof OperatingDayCalendar
     */
    'id'?: number;
    /**
     * Datum provozního dne
     * @type {string}
     * @memberof OperatingDayCalendar
     */
    'date'?: string;
}
/**
 * 
 * @export
 * @interface OperatingDayModel
 */
export interface OperatingDayModel {
    /**
     * ID provozního dne.
     * @type {number}
     * @memberof OperatingDayModel
     */
    'id'?: number;
    /**
     * Název pracovního dne.
     * @type {string}
     * @memberof OperatingDayModel
     */
    'name'?: string;
    /**
     * Barva přidělená pracovnímu dni.
     * @type {string}
     * @memberof OperatingDayModel
     */
    'color'?: string;
    /**
     * Stav aktivace pracovního dne.
     * @type {boolean}
     * @memberof OperatingDayModel
     */
    'isActive'?: boolean;
    /**
     * Datum vytvoření pracovního dne.
     * @type {string}
     * @memberof OperatingDayModel
     */
    'created'?: string;
    /**
     * Datum deaktivace pracovního dne.
     * @type {string}
     * @memberof OperatingDayModel
     */
    'deActivated'?: string | null;
}
/**
 * 
 * @export
 * @interface OrderDayModel
 */
export interface OrderDayModel {
    /**
     * Zahrnutí zpáteční cesty.
     * @type {boolean}
     * @memberof OrderDayModel
     */
    'isReturn'?: boolean;
    /**
     * Den v týdnu
     * @type {number}
     * @memberof OrderDayModel
     */
    'weekday'?: number;
    /**
     * Čas vyzvednutí.
     * @type {string}
     * @memberof OrderDayModel
     */
    'pickup'?: string;
    /**
     * Čas vyložení
     * @type {string}
     * @memberof OrderDayModel
     */
    'dropoff'?: string;
    /**
     * 
     * @type {OrderDayModelPrimaryTime}
     * @memberof OrderDayModel
     */
    'primaryTime'?: OrderDayModelPrimaryTime;
}
/**
 * @type OrderDayModelPrimaryTime
 * Primární čas.
 * @export
 */
export type OrderDayModelPrimaryTime = TimeType;

/**
 * 
 * @export
 * @enum {string}
 */

export const OrderInstanceCancellationType = {
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3,
    NUMBER_4: 4,
    NUMBER_5: 5
} as const;

export type OrderInstanceCancellationType = typeof OrderInstanceCancellationType[keyof typeof OrderInstanceCancellationType];


/**
 * 
 * @export
 * @enum {string}
 */

export const OrderInstanceState = {
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3,
    NUMBER_4: 4,
    NUMBER_5: 5,
    NUMBER_6: 6
} as const;

export type OrderInstanceState = typeof OrderInstanceState[keyof typeof OrderInstanceState];


/**
 * 
 * @export
 * @interface OrderModel
 */
export interface OrderModel {
    /**
     * ID objednávky.
     * @type {number}
     * @memberof OrderModel
     */
    'id'?: number;
    /**
     * 
     * @type {AcceptanceType}
     * @memberof OrderModel
     */
    'acceptanceType': AcceptanceType;
    /**
     * ID zákazníka.
     * @type {number}
     * @memberof OrderModel
     */
    'customerId': number;
    /**
     * 
     * @type {OrderModelEscort}
     * @memberof OrderModel
     */
    'escort'?: OrderModelEscort;
    /**
     * ID doprovodné pomůcky pro handicapovaného.
     * @type {number}
     * @memberof OrderModel
     */
    'escortHandicapEquipmentId'?: number | null;
    /**
     * Přítomnost doprovodného psa.
     * @type {boolean}
     * @memberof OrderModel
     */
    'escortDog'?: boolean;
    /**
     * Přítomnost zavazadel.
     * @type {boolean}
     * @memberof OrderModel
     */
    'escortLuggage'?: boolean;
    /**
     * 
     * @type {OrderModelAddressFrom}
     * @memberof OrderModel
     */
    'addressFrom'?: OrderModelAddressFrom | null;
    /**
     * 
     * @type {OrderModelAddressTo}
     * @memberof OrderModel
     */
    'addressTo'?: OrderModelAddressTo | null;
    /**
     * 
     * @type {OrderModelOrderType}
     * @memberof OrderModel
     */
    'orderType'?: OrderModelOrderType;
    /**
     * 
     * @type {OrderModelRepetitionFrequency}
     * @memberof OrderModel
     */
    'repetitionFrequency'?: OrderModelRepetitionFrequency;
    /**
     * 
     * @type {OrderModelState}
     * @memberof OrderModel
     */
    'state'?: OrderModelState;
    /**
     * Identifikátor slot instance pro odběr.
     * @type {number}
     * @memberof OrderModel
     */
    'slotInstanceId'?: number | null;
    /**
     * Identifikátor slot instance pro doručení.
     * @type {number}
     * @memberof OrderModel
     */
    'slotInstanceReturnId'?: number | null;
    /**
     * Datum.
     * @type {string}
     * @memberof OrderModel
     */
    'date': string;
    /**
     * Datum do.
     * @type {string}
     * @memberof OrderModel
     */
    'dateTo': string;
    /**
     * Interní poznámka.
     * @type {string}
     * @memberof OrderModel
     */
    'noteInternal'?: string;
    /**
     * Poznámka pro řidiče.
     * @type {string}
     * @memberof OrderModel
     */
    'noteDriver'?: string;
    /**
     * Doba trvání.
     * @type {number}
     * @memberof OrderModel
     */
    'duration'?: number;
    /**
     * Seznam časů objednávek.
     * @type {Array<OrderDayModel>}
     * @memberof OrderModel
     */
    'times'?: Array<OrderDayModel>;
    /**
     * Indikuje, zda je možné upravit adresy v objednávce.
     * @type {boolean}
     * @memberof OrderModel
     */
    'canChangeAddresses'?: boolean;
}
/**
 * @type OrderModelAddressFrom
 * Adresa odkud.
 * @export
 */
export type OrderModelAddressFrom = AddressModel;

/**
 * @type OrderModelAddressTo
 * Adresa kam.
 * @export
 */
export type OrderModelAddressTo = AddressModel;

/**
 * @type OrderModelEscort
 * Typ doprovodu.
 * @export
 */
export type OrderModelEscort = Escort;

/**
 * @type OrderModelOrderType
 * Typ objednávky.
 * @export
 */
export type OrderModelOrderType = OrderType;

/**
 * @type OrderModelRepetitionFrequency
 * Frekvence opakování objednávky.
 * @export
 */
export type OrderModelRepetitionFrequency = OrderRepetitionFrequency;

/**
 * @type OrderModelState
 * Stav objednávky.
 * @export
 */
export type OrderModelState = OrderState;

/**
 * 
 * @export
 * @enum {string}
 */

export const OrderRepetitionFrequency = {
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3
} as const;

export type OrderRepetitionFrequency = typeof OrderRepetitionFrequency[keyof typeof OrderRepetitionFrequency];


/**
 * 
 * @export
 * @enum {string}
 */

export const OrderState = {
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3,
    NUMBER_4: 4,
    NUMBER_5: 5,
    NUMBER_6: 6
} as const;

export type OrderState = typeof OrderState[keyof typeof OrderState];


/**
 * 
 * @export
 * @enum {string}
 */

export const OrderType = {
    NUMBER_1: 1,
    NUMBER_2: 2
} as const;

export type OrderType = typeof OrderType[keyof typeof OrderType];


/**
 * 
 * @export
 * @interface PatchAddressGeocodingRequest
 */
export interface PatchAddressGeocodingRequest {
    /**
     * ID zakázníka, pod kterého spadají hledané adresy
     * @type {number}
     * @memberof PatchAddressGeocodingRequest
     */
    'id'?: number;
    /**
     * Identifikátor místa v Google Maps přiřazený adrese.
     * @type {string}
     * @memberof PatchAddressGeocodingRequest
     */
    'googlePlaceId'?: string | null;
    /**
     * Zeměpisná šířka (Latitude) místa, ke kterému se adresa vztahuje.
     * @type {number}
     * @memberof PatchAddressGeocodingRequest
     */
    'lat'?: number | null;
    /**
     * Zeměpisná délka (Longitude) místa, ke kterému se adresa vztahuje.
     * @type {number}
     * @memberof PatchAddressGeocodingRequest
     */
    'lng'?: number | null;
    /**
     * Určuje, zda nebylo nalezeno místo (geokód) pro adresu.
     * @type {boolean}
     * @memberof PatchAddressGeocodingRequest
     */
    'notFound': boolean;
}
/**
 * 
 * @export
 * @interface PatchCustomerIsActiveRequest
 */
export interface PatchCustomerIsActiveRequest {
    /**
     * Stav aktivity, true pro aktivaci, false pro deaktivaci.
     * @type {boolean}
     * @memberof PatchCustomerIsActiveRequest
     */
    'isActive'?: boolean;
}
/**
 * 
 * @export
 * @interface PatchExtraEscortsRequest
 */
export interface PatchExtraEscortsRequest {
    /**
     * Změna počtu extra doprovodů
     * @type {number}
     * @memberof PatchExtraEscortsRequest
     */
    'change'?: number;
}
/**
 * 
 * @export
 * @interface PatchFixEventLocationRequest
 */
export interface PatchFixEventLocationRequest {
    /**
     * Kód vozidla v GPS Dozor
     * @type {string}
     * @memberof PatchFixEventLocationRequest
     */
    'vehicleGpsDozorCode': string;
    /**
     * Čas opravy umístění
     * @type {string}
     * @memberof PatchFixEventLocationRequest
     */
    'time'?: string;
}
/**
 * 
 * @export
 * @interface PatchIsActiveRequest
 */
export interface PatchIsActiveRequest {
    /**
     * 
     * @type {boolean}
     * @memberof PatchIsActiveRequest
     */
    'isActive'?: boolean;
}
/**
 * 
 * @export
 * @interface PatchIsActiveRequest2
 */
export interface PatchIsActiveRequest2 {
    /**
     * Je aktivní
     * @type {boolean}
     * @memberof PatchIsActiveRequest2
     */
    'isActive'?: boolean;
}
/**
 * 
 * @export
 * @interface PatchIsActiveRequest3
 */
export interface PatchIsActiveRequest3 {
    /**
     * Nový stav aktivity slotu.
     * @type {boolean}
     * @memberof PatchIsActiveRequest3
     */
    'isActive'?: boolean;
}
/**
 * 
 * @export
 * @interface PatchIsActiveRequest4
 */
export interface PatchIsActiveRequest4 {
    /**
     * Stav aktivity instance slotu.
     * @type {boolean}
     * @memberof PatchIsActiveRequest4
     */
    'isActive'?: boolean;
}
/**
 * 
 * @export
 * @interface PatchIsActiveRequest5
 */
export interface PatchIsActiveRequest5 {
    /**
     * Zda má být provozní den aktivní
     * @type {boolean}
     * @memberof PatchIsActiveRequest5
     */
    'isActive'?: boolean;
}
/**
 * 
 * @export
 * @interface PatchIsActiveRequest6
 */
export interface PatchIsActiveRequest6 {
    /**
     * Stav aktivity, true pro aktivaci, false pro deaktivaci.
     * @type {boolean}
     * @memberof PatchIsActiveRequest6
     */
    'isActive'?: boolean;
}
/**
 * 
 * @export
 * @interface PatchOrderInstanceStateRequest
 */
export interface PatchOrderInstanceStateRequest {
    /**
     * 
     * @type {GetOrderInstanceModelState}
     * @memberof PatchOrderInstanceStateRequest
     */
    'state'?: GetOrderInstanceModelState;
    /**
     * 
     * @type {PatchOrderInstanceStateRequestCancellationType}
     * @memberof PatchOrderInstanceStateRequest
     */
    'cancellationType'?: PatchOrderInstanceStateRequestCancellationType | null;
}
/**
 * @type PatchOrderInstanceStateRequestCancellationType
 * Typ zrušení objednávky (pouze pro zrušené objednávky)
 * @export
 */
export type PatchOrderInstanceStateRequestCancellationType = OrderInstanceCancellationType;

/**
 * 
 * @export
 * @interface PatchOrderNoninstantiablesRequest
 */
export interface PatchOrderNoninstantiablesRequest {
    /**
     * ID objednávky
     * @type {number}
     * @memberof PatchOrderNoninstantiablesRequest
     */
    'orderId'?: number;
    /**
     * Interní poznámka
     * @type {string}
     * @memberof PatchOrderNoninstantiablesRequest
     */
    'noteInternal'?: string;
    /**
     * Poznámka řidičovi
     * @type {string}
     * @memberof PatchOrderNoninstantiablesRequest
     */
    'noteDriver'?: string;
    /**
     * 
     * @type {PatchOrderNoninstantiablesRequestAcceptanceType}
     * @memberof PatchOrderNoninstantiablesRequest
     */
    'acceptanceType'?: PatchOrderNoninstantiablesRequestAcceptanceType;
}
/**
 * @type PatchOrderNoninstantiablesRequestAcceptanceType
 * Typ schválení
 * @export
 */
export type PatchOrderNoninstantiablesRequestAcceptanceType = AcceptanceType;

/**
 * 
 * @export
 * @interface PatchRideDurationRequest
 */
export interface PatchRideDurationRequest {
    /**
     * Nová délka jízdy
     * @type {number}
     * @memberof PatchRideDurationRequest
     */
    'duration'?: number;
}
/**
 * 
 * @export
 * @interface PatchRideSlotInstanceRequest
 */
export interface PatchRideSlotInstanceRequest {
    /**
     * ID instance slotu
     * @type {number}
     * @memberof PatchRideSlotInstanceRequest
     */
    'slotInstanceId'?: number;
}
/**
 * 
 * @export
 * @interface PatchRideStopSequenceRequest
 */
export interface PatchRideStopSequenceRequest {
    /**
     * Seznam zastávek
     * @type {Array<StopModel>}
     * @memberof PatchRideStopSequenceRequest
     */
    'stops'?: Array<StopModel>;
}
/**
 * 
 * @export
 * @interface PatchShiftLogClosedRequest
 */
export interface PatchShiftLogClosedRequest {
    /**
     * Uzavřeno
     * @type {boolean}
     * @memberof PatchShiftLogClosedRequest
     */
    'closed': boolean;
    /**
     * Praha cellkově
     * @type {number}
     * @memberof PatchShiftLogClosedRequest
     */
    'pragueTotal'?: number | null;
    /**
     * Mimo Prahu celkově
     * @type {number}
     * @memberof PatchShiftLogClosedRequest
     */
    'otherTotal'?: number | null;
}
/**
 * 
 * @export
 * @interface PatchShiftLogClosedRequestAllOf
 */
export interface PatchShiftLogClosedRequestAllOf {
    /**
     * Uzavřeno
     * @type {boolean}
     * @memberof PatchShiftLogClosedRequestAllOf
     */
    'closed'?: boolean;
    /**
     * Praha cellkově
     * @type {number}
     * @memberof PatchShiftLogClosedRequestAllOf
     */
    'pragueTotal'?: number | null;
    /**
     * Mimo Prahu celkově
     * @type {number}
     * @memberof PatchShiftLogClosedRequestAllOf
     */
    'otherTotal'?: number | null;
}
/**
 * 
 * @export
 * @interface PatchShiftLogEstimatesRequest
 */
export interface PatchShiftLogEstimatesRequest {
    /**
     * Seznam odhadů pro jednotlivé události
     * @type {Array<LogShiftEventEstimatesModel>}
     * @memberof PatchShiftLogEstimatesRequest
     */
    'eventEstimates'?: Array<LogShiftEventEstimatesModel> | null;
    /**
     * Celkový odhad
     * @type {number}
     * @memberof PatchShiftLogEstimatesRequest
     */
    'totalEstimate'?: number | null;
}
/**
 * 
 * @export
 * @interface PatchShiftLogEstimatesRequestAllOf
 */
export interface PatchShiftLogEstimatesRequestAllOf {
    /**
     * Seznam odhadů pro jednotlivé události
     * @type {Array<LogShiftEventEstimatesModel>}
     * @memberof PatchShiftLogEstimatesRequestAllOf
     */
    'eventEstimates'?: Array<LogShiftEventEstimatesModel> | null;
    /**
     * Celkový odhad
     * @type {number}
     * @memberof PatchShiftLogEstimatesRequestAllOf
     */
    'totalEstimate'?: number | null;
}
/**
 * 
 * @export
 * @interface PatchShitLogManualRequest
 */
export interface PatchShitLogManualRequest {
    /**
     * Seznam manuálně zadaných hodnot událostí směny.
     * @type {Array<LogShiftEventManualModel>}
     * @memberof PatchShitLogManualRequest
     */
    'eventManuals'?: Array<LogShiftEventManualModel>;
}
/**
 * 
 * @export
 * @interface PatchShitLogManualRequestAllOf
 */
export interface PatchShitLogManualRequestAllOf {
    /**
     * Seznam manuálně zadaných hodnot událostí směny.
     * @type {Array<LogShiftEventManualModel>}
     * @memberof PatchShitLogManualRequestAllOf
     */
    'eventManuals'?: Array<LogShiftEventManualModel>;
}
/**
 * 
 * @export
 * @interface PatchSlotInstanceShiftsRequest
 */
export interface PatchSlotInstanceShiftsRequest {
    /**
     * Seznam směn vozidel.
     * @type {Array<SetShiftModel>}
     * @memberof PatchSlotInstanceShiftsRequest
     */
    'vehicleShifts'?: Array<SetShiftModel>;
    /**
     * Seznam směn řidičů.
     * @type {Array<SetShiftModel>}
     * @memberof PatchSlotInstanceShiftsRequest
     */
    'driverShifts'?: Array<SetShiftModel>;
    /**
     * Seznam směn asistentů.
     * @type {Array<SetShiftModel>}
     * @memberof PatchSlotInstanceShiftsRequest
     */
    'assistantShifts'?: Array<SetShiftModel>;
}
/**
 * 
 * @export
 * @interface PatchSlotInstanceShiftsRequestAllOf
 */
export interface PatchSlotInstanceShiftsRequestAllOf {
    /**
     * Seznam směn vozidel.
     * @type {Array<SetShiftModel>}
     * @memberof PatchSlotInstanceShiftsRequestAllOf
     */
    'vehicleShifts'?: Array<SetShiftModel>;
    /**
     * Seznam směn řidičů.
     * @type {Array<SetShiftModel>}
     * @memberof PatchSlotInstanceShiftsRequestAllOf
     */
    'driverShifts'?: Array<SetShiftModel>;
    /**
     * Seznam směn asistentů.
     * @type {Array<SetShiftModel>}
     * @memberof PatchSlotInstanceShiftsRequestAllOf
     */
    'assistantShifts'?: Array<SetShiftModel>;
}
/**
 * 
 * @export
 * @interface PatchSlotNameRequest
 */
export interface PatchSlotNameRequest {
    /**
     * Název slotu 
     * @type {string}
     * @memberof PatchSlotNameRequest
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface PatchUserAvatarImageRequest
 */
export interface PatchUserAvatarImageRequest {
    /**
     * ID profilového obrázku
     * @type {number}
     * @memberof PatchUserAvatarImageRequest
     */
    'avatarImageId'?: number;
}
/**
 * 
 * @export
 * @interface RefreshToken
 */
export interface RefreshToken {
    /**
     * 
     * @type {string}
     * @memberof RefreshToken
     */
    'value'?: string;
    /**
     * 
     * @type {string}
     * @memberof RefreshToken
     */
    'expiryDate'?: string;
}
/**
 * 
 * @export
 * @interface RefreshTokenModel
 */
export interface RefreshTokenModel {
    /**
     * ID tokenu.
     * @type {number}
     * @memberof RefreshTokenModel
     */
    'id'?: number;
    /**
     * Hodnota tokenu.
     * @type {string}
     * @memberof RefreshTokenModel
     */
    'value'?: string;
    /**
     * Datum a čas vypršení platnosti tokenu.
     * @type {string}
     * @memberof RefreshTokenModel
     */
    'expires'?: string;
    /**
     * 
     * @type {RefreshTokenModelUser}
     * @memberof RefreshTokenModel
     */
    'user'?: RefreshTokenModelUser;
    /**
     * ID Uživatele.
     * @type {number}
     * @memberof RefreshTokenModel
     */
    'userId'?: number;
}
/**
 * @type RefreshTokenModelUser
 * Uživatel.
 * @export
 */
export type RefreshTokenModelUser = UserModel;

/**
 * 
 * @export
 * @interface RefreshTokenRequest
 */
export interface RefreshTokenRequest {
    /**
     * Unikátní identifikátor pro obnovení tokenu.
     * @type {string}
     * @memberof RefreshTokenRequest
     */
    'refreshToken': string;
    /**
     * Přístupový token pro ověření uživatele.
     * @type {string}
     * @memberof RefreshTokenRequest
     */
    'accessToken': string;
}
/**
 * 
 * @export
 * @interface RefreshTokenResponse
 */
export interface RefreshTokenResponse {
    /**
     * 
     * @type {RefreshToken}
     * @memberof RefreshTokenResponse
     */
    'refreshToken'?: RefreshToken;
    /**
     * 
     * @type {JwtToken}
     * @memberof RefreshTokenResponse
     */
    'accessToken'?: JwtToken;
}
/**
 * 
 * @export
 * @interface RideCustomerModel
 */
export interface RideCustomerModel {
    /**
     * ID zákazníka
     * @type {number}
     * @memberof RideCustomerModel
     */
    'id'?: number;
    /**
     * Jméno zákazníka
     * @type {string}
     * @memberof RideCustomerModel
     */
    'name'?: string;
    /**
     * Telefonní číslo zákazníka
     * @type {string}
     * @memberof RideCustomerModel
     */
    'phoneNumber'?: string;
    /**
     * Poplatek zákazníka
     * @type {number}
     * @memberof RideCustomerModel
     */
    'fee'?: number;
    /**
     * Věk zákazníka
     * @type {number}
     * @memberof RideCustomerModel
     */
    'age'?: number;
    /**
     * Číslo ZTP karty
     * @type {string}
     * @memberof RideCustomerModel
     */
    'cardNumber'?: string;
    /**
     * Trvalé bydliště zákazníka
     * @type {string}
     * @memberof RideCustomerModel
     */
    'residenceAddress'?: string;
    /**
     * 
     * @type {RideCustomerModelBillingZone}
     * @memberof RideCustomerModel
     */
    'billingZone'?: RideCustomerModelBillingZone | null;
    /**
     * ID pomůcky zákazníka
     * @type {number}
     * @memberof RideCustomerModel
     */
    'handicapEquipmentId'?: number;
}
/**
 * @type RideCustomerModelBillingZone
 * Zóna adresy
 * @export
 */
export type RideCustomerModelBillingZone = BillingZone;

/**
 * 
 * @export
 * @interface RideFromToTime
 */
export interface RideFromToTime {
    /**
     * Čas vyzvednutí.
     * @type {string}
     * @memberof RideFromToTime
     */
    'pickup'?: string | null;
    /**
     * Čas vyložení.
     * @type {string}
     * @memberof RideFromToTime
     */
    'dropoff'?: string | null;
    /**
     * 
     * @type {OrderDayModelPrimaryTime}
     * @memberof RideFromToTime
     */
    'primaryTime'?: OrderDayModelPrimaryTime;
    /**
     * Konstantní hodnota určující konzistenci času.
     * @type {boolean}
     * @memberof RideFromToTime
     */
    'consistent'?: boolean;
}
/**
 * 
 * @export
 * @interface RidePlanningModel
 */
export interface RidePlanningModel {
    /**
     * ID plánované jízdy.
     * @type {number}
     * @memberof RidePlanningModel
     */
    'id'?: number;
    /**
     * 
     * @type {RidePlanningModelState}
     * @memberof RidePlanningModel
     */
    'state'?: RidePlanningModelState;
    /**
     * Datum a čas vyzvednutí.
     * @type {string}
     * @memberof RidePlanningModel
     */
    'pickup'?: string;
    /**
     * Datum a čas vyložení.
     * @type {string}
     * @memberof RidePlanningModel
     */
    'dropoff'?: string;
    /**
     * Datum a čas doručení poslední objednávky s náhradním výpočtem.
     * @type {string}
     * @memberof RidePlanningModel
     */
    'dropoffDisplay'?: string;
    /**
     * 
     * @type {RidePlanningModelOrderType}
     * @memberof RidePlanningModel
     */
    'orderType'?: RidePlanningModelOrderType | null;
    /**
     * Identifikátor instance slotu spojeného s plánovanou jízdou.
     * @type {number}
     * @memberof RidePlanningModel
     */
    'slotInstanceId'?: number | null;
    /**
     * Seznam instancí objednávek spojených s plánovanou jízdou.
     * @type {Array<GetOrderInstanceModel>}
     * @memberof RidePlanningModel
     */
    'orderInstances'?: Array<GetOrderInstanceModel>;
    /**
     * Indikátor, zda má plánovaná jízda definovanou dobu trvání.
     * @type {boolean}
     * @memberof RidePlanningModel
     */
    'hasDuration'?: boolean;
    /**
     * Indikátor, zda je plánovaná jízda nad kapacitou.
     * @type {boolean}
     * @memberof RidePlanningModel
     */
    'isOverCapacity'?: boolean;
    /**
     * 
     * @type {RidePlanningModelSlot}
     * @memberof RidePlanningModel
     */
    'slot'?: RidePlanningModelSlot | null;
    /**
     * Seznam zástávek spojený s plánovanou jízdou.
     * @type {Array<RideStopModel>}
     * @memberof RidePlanningModel
     */
    'stops'?: Array<RideStopModel>;
}
/**
 * @type RidePlanningModelOrderType
 * Typ objedávky.
 * @export
 */
export type RidePlanningModelOrderType = OrderType;

/**
 * @type RidePlanningModelSlot
 * Slot spojený s plánovanou jízdou.
 * @export
 */
export type RidePlanningModelSlot = SlotRideModel;

/**
 * @type RidePlanningModelState
 * Stav plánované jízdy.
 * @export
 */
export type RidePlanningModelState = OrderInstanceState;

/**
 * 
 * @export
 * @interface RideStatisticsCountModel
 */
export interface RideStatisticsCountModel {
    /**
     * 
     * @type {RideStatisticsCountModelState}
     * @memberof RideStatisticsCountModel
     */
    'state'?: RideStatisticsCountModelState;
    /**
     * Počet jízd v tomto stavu
     * @type {number}
     * @memberof RideStatisticsCountModel
     */
    'count'?: number;
}
/**
 * @type RideStatisticsCountModelState
 * Stav instance objednávky
 * @export
 */
export type RideStatisticsCountModelState = OrderInstanceState;

/**
 * 
 * @export
 * @interface RideStopModel
 */
export interface RideStopModel {
    /**
     * Adresa zastávky.
     * @type {string}
     * @memberof RideStopModel
     */
    'address'?: string | null;
    /**
     * Zkrácená adresa zastávky.
     * @type {string}
     * @memberof RideStopModel
     */
    'addressShort'?: string | null;
    /**
     * 
     * @type {RideStopModelAddressModel}
     * @memberof RideStopModel
     */
    'addressModel'?: RideStopModelAddressModel | null;
    /**
     * Čas zastávky.
     * @type {string}
     * @memberof RideStopModel
     */
    'stopTime'?: string;
    /**
     * ID dne objednávky spojené s zastávkou.
     * @type {number}
     * @memberof RideStopModel
     */
    'orderDay'?: number | null;
    /**
     * Jméno zákazníka.
     * @type {string}
     * @memberof RideStopModel
     */
    'customer'?: string | null;
    /**
     * Celé jméno zákazníka.
     * @type {string}
     * @memberof RideStopModel
     */
    'customerFullName'?: string | null;
    /**
     * 
     * @type {RideStopModelStopType}
     * @memberof RideStopModel
     */
    'stopType'?: RideStopModelStopType;
    /**
     * 
     * @type {RideStopModelPrimaryTime}
     * @memberof RideStopModel
     */
    'primaryTime'?: RideStopModelPrimaryTime | null;
}
/**
 * @type RideStopModelAddressModel
 * Model adresy zastávky.
 * @export
 */
export type RideStopModelAddressModel = AddressModel;

/**
 * @type RideStopModelPrimaryTime
 * Primární čas.
 * @export
 */
export type RideStopModelPrimaryTime = TimeType;

/**
 * @type RideStopModelStopType
 * Typ zastávky.
 * @export
 */
export type RideStopModelStopType = StopType;

/**
 * 
 * @export
 * @interface RoleModel
 */
export interface RoleModel {
    /**
     * Název
     * @type {string}
     * @memberof RoleModel
     */
    'name'?: string;
    /**
     * Id přepravce
     * @type {Array<number>}
     * @memberof RoleModel
     */
    'carrierIds'?: Array<number>;
}
/**
 * 
 * @export
 * @interface SaveAddressResponse
 */
export interface SaveAddressResponse {
    /**
     * Zpráva o provedné akci
     * @type {string}
     * @memberof SaveAddressResponse
     */
    'message'?: string;
    /**
     * ID adresy
     * @type {number}
     * @memberof SaveAddressResponse
     */
    'id'?: number;
}
/**
 * 
 * @export
 * @interface SaveCalendarDayModel
 */
export interface SaveCalendarDayModel {
    /**
     * Datum v kalendáři
     * @type {string}
     * @memberof SaveCalendarDayModel
     */
    'date'?: string;
    /**
     * Id provozního dne
     * @type {number}
     * @memberof SaveCalendarDayModel
     */
    'operatingDayId'?: number;
}
/**
 * 
 * @export
 * @interface SaveCalendarRequest
 */
export interface SaveCalendarRequest {
    /**
     * 
     * @type {Array<SaveCalendarDayModel>}
     * @memberof SaveCalendarRequest
     */
    'days'?: Array<SaveCalendarDayModel>;
}
/**
 * 
 * @export
 * @interface SaveCalendarResponse
 */
export interface SaveCalendarResponse {
    /**
     * Zpráva o provedené akci
     * @type {string}
     * @memberof SaveCalendarResponse
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface SaveCarrierRequest
 */
export interface SaveCarrierRequest {
    /**
     * Identifikátor dopravce. Pokud je hodnota 0 nebo null, vytvoří se nový dopravce.
     * @type {number}
     * @memberof SaveCarrierRequest
     */
    'id'?: number;
    /**
     * Plné jméno dopravce.
     * @type {string}
     * @memberof SaveCarrierRequest
     */
    'nameFull': string;
    /**
     * Zkrácené jméno dopravce.
     * @type {string}
     * @memberof SaveCarrierRequest
     */
    'nameShort'?: string;
    /**
     * Zkratka nebo značka dopravce.
     * @type {string}
     * @memberof SaveCarrierRequest
     */
    'abbreviation': string;
    /**
     * 
     * @type {SaveCarrierRequestAddress}
     * @memberof SaveCarrierRequest
     */
    'address'?: SaveCarrierRequestAddress;
    /**
     * Identifikátor obrázku/logo dopravce.
     * @type {number}
     * @memberof SaveCarrierRequest
     */
    'logoId'?: number | null;
}
/**
 * @type SaveCarrierRequestAddress
 * Adresa dopravce.
 * @export
 */
export type SaveCarrierRequestAddress = AddressModel;

/**
 * 
 * @export
 * @interface SaveCarrierResponse
 */
export interface SaveCarrierResponse {
    /**
     * Zpráva potvrzující vytvoření dopravce.
     * @type {string}
     * @memberof SaveCarrierResponse
     */
    'message'?: string;
    /**
     * Identifikátor vytvořeného nebo aktualizovaného dopravce.
     * @type {number}
     * @memberof SaveCarrierResponse
     */
    'id'?: number;
}
/**
 * 
 * @export
 * @interface SaveCustomerEventRequest
 */
export interface SaveCustomerEventRequest {
    /**
     * ID objednávky
     * @type {number}
     * @memberof SaveCustomerEventRequest
     */
    'orderInstanceId': number;
    /**
     * Typ události
     * @type {EventType}
     * @memberof SaveCustomerEventRequest
     */
    'eventType'?: EventType;
}
/**
 * 
 * @export
 * @interface SaveCustomerEventRequestAllOf
 */
export interface SaveCustomerEventRequestAllOf {
    /**
     * ID objednávky
     * @type {number}
     * @memberof SaveCustomerEventRequestAllOf
     */
    'orderInstanceId'?: number;
    /**
     * Typ události
     * @type {EventType}
     * @memberof SaveCustomerEventRequestAllOf
     */
    'eventType'?: EventType;
}
/**
 * 
 * @export
 * @interface SaveCustomerEventResponse
 */
export interface SaveCustomerEventResponse {
    /**
     * ID události
     * @type {number}
     * @memberof SaveCustomerEventResponse
     */
    'eventId'?: number;
}
/**
 * 
 * @export
 * @interface SaveCustomerRequest
 */
export interface SaveCustomerRequest {
    /**
     * ID zákazníka.
     * @type {number}
     * @memberof SaveCustomerRequest
     */
    'id'?: number;
    /**
     * Jméno zákazníka.
     * @type {string}
     * @memberof SaveCustomerRequest
     */
    'firstName': string;
    /**
     * Příjmení zákazníka.
     * @type {string}
     * @memberof SaveCustomerRequest
     */
    'lastName': string;
    /**
     * Datum narození zákazníka.
     * @type {string}
     * @memberof SaveCustomerRequest
     */
    'dateOfBirth': string;
    /**
     * Telefonní číslo zákazníka.
     * @type {string}
     * @memberof SaveCustomerRequest
     */
    'phoneNumber': string;
    /**
     * Emailová adresa zákazníka.
     * @type {string}
     * @memberof SaveCustomerRequest
     */
    'email': string;
    /**
     * Preferovaný způsob komunikace pomocí emailu.
     * @type {boolean}
     * @memberof SaveCustomerRequest
     */
    'isEmailPreferred'?: boolean;
    /**
     * Jméno opatrovníka.
     * @type {string}
     * @memberof SaveCustomerRequest
     */
    'guardianFirstName'?: string | null;
    /**
     * Příjmení opatrovníka.
     * @type {string}
     * @memberof SaveCustomerRequest
     */
    'guardianLastName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SaveCustomerRequest
     */
    'cardNumber': string;
    /**
     * 
     * @type {CardType}
     * @memberof SaveCustomerRequest
     */
    'cardType': CardType;
    /**
     * Popis handicapu zákazníka.
     * @type {string}
     * @memberof SaveCustomerRequest
     */
    'handicapDescription'?: string | null;
    /**
     * ID vybavení handicapovaného.
     * @type {number}
     * @memberof SaveCustomerRequest
     */
    'handicapEquipmentId': number;
    /**
     * Veřejný komentář.
     * @type {string}
     * @memberof SaveCustomerRequest
     */
    'generalComment'?: string | null;
    /**
     * Interní komentář.
     * @type {string}
     * @memberof SaveCustomerRequest
     */
    'internalComment'?: string | null;
    /**
     * Stav aktivace zákazníka.
     * @type {boolean}
     * @memberof SaveCustomerRequest
     */
    'isActive'?: boolean;
    /**
     * Kolekce modelů kontaktů zákazníka.
     * @type {Array<CustomerContactModel>}
     * @memberof SaveCustomerRequest
     */
    'contacts'?: Array<CustomerContactModel>;
    /**
     * Kolekce modelů adres zákazníka.
     * @type {Array<AddressModel>}
     * @memberof SaveCustomerRequest
     */
    'addresses': Array<AddressModel>;
}
/**
 * 
 * @export
 * @interface SaveCustomerResponse
 */
export interface SaveCustomerResponse {
    /**
     * 
     * @type {string}
     * @memberof SaveCustomerResponse
     */
    'message'?: string;
    /**
     * ID zákazníka
     * @type {number}
     * @memberof SaveCustomerResponse
     */
    'id'?: number;
}
/**
 * 
 * @export
 * @interface SaveOperatingDayRequest
 */
export interface SaveOperatingDayRequest {
    /**
     * Id provozního dne
     * @type {number}
     * @memberof SaveOperatingDayRequest
     */
    'id'?: number;
    /**
     * Název provozního dne
     * @type {string}
     * @memberof SaveOperatingDayRequest
     */
    'name': string;
    /**
     * Barva provozního dne
     * @type {string}
     * @memberof SaveOperatingDayRequest
     */
    'color'?: string | null;
}
/**
 * 
 * @export
 * @interface SaveOperatingDayResponse
 */
export interface SaveOperatingDayResponse {
    /**
     * Zpráva o provedené akci
     * @type {string}
     * @memberof SaveOperatingDayResponse
     */
    'message'?: string;
    /**
     * Id provozního dne
     * @type {number}
     * @memberof SaveOperatingDayResponse
     */
    'id'?: number;
}
/**
 * 
 * @export
 * @interface SaveOrderInstanceRequest
 */
export interface SaveOrderInstanceRequest {
    /**
     * Id instance objednávky.
     * @type {number}
     * @memberof SaveOrderInstanceRequest
     */
    'id'?: number;
    /**
     * Datum a čas vyzvednutí.
     * @type {string}
     * @memberof SaveOrderInstanceRequest
     */
    'pickup'?: string;
    /**
     * Datum a čas vyložení.
     * @type {string}
     * @memberof SaveOrderInstanceRequest
     */
    'dropoff'?: string;
    /**
     * 
     * @type {SetOrderInstanceModelEscort}
     * @memberof SaveOrderInstanceRequest
     */
    'escort'?: SetOrderInstanceModelEscort | null;
    /**
     * Id pomůcky pro handicapované
     * @type {number}
     * @memberof SaveOrderInstanceRequest
     */
    'handicapEquipmentId'?: number;
    /**
     * Id doprovodné pomůcky pro handicapované
     * @type {number}
     * @memberof SaveOrderInstanceRequest
     */
    'escortHandicapEquipmentId'?: number | null;
    /**
     * Přítomnost doprovodného psa.
     * @type {boolean}
     * @memberof SaveOrderInstanceRequest
     */
    'escortDog'?: boolean;
    /**
     * Příruční zavazadlo
     * @type {boolean}
     * @memberof SaveOrderInstanceRequest
     */
    'escortLuggage'?: boolean;
    /**
     * 
     * @type {SetOrderInstanceModelState}
     * @memberof SaveOrderInstanceRequest
     */
    'state'?: SetOrderInstanceModelState;
    /**
     * Poznámka.
     * @type {string}
     * @memberof SaveOrderInstanceRequest
     */
    'note'?: string | null;
}
/**
 * 
 * @export
 * @interface SaveOrderInstanceResponse
 */
export interface SaveOrderInstanceResponse {
    /**
     * Zpráva o provedené akci
     * @type {string}
     * @memberof SaveOrderInstanceResponse
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface SaveOrderRequest
 */
export interface SaveOrderRequest {
    /**
     * ID objednávky.
     * @type {number}
     * @memberof SaveOrderRequest
     */
    'id'?: number;
    /**
     * 
     * @type {AcceptanceType}
     * @memberof SaveOrderRequest
     */
    'acceptanceType': AcceptanceType;
    /**
     * ID zákazníka.
     * @type {number}
     * @memberof SaveOrderRequest
     */
    'customerId': number;
    /**
     * 
     * @type {OrderModelEscort}
     * @memberof SaveOrderRequest
     */
    'escort'?: OrderModelEscort;
    /**
     * ID doprovodné pomůcky pro handicapovaného.
     * @type {number}
     * @memberof SaveOrderRequest
     */
    'escortHandicapEquipmentId'?: number | null;
    /**
     * Přítomnost doprovodného psa.
     * @type {boolean}
     * @memberof SaveOrderRequest
     */
    'escortDog'?: boolean;
    /**
     * Přítomnost zavazadel.
     * @type {boolean}
     * @memberof SaveOrderRequest
     */
    'escortLuggage'?: boolean;
    /**
     * 
     * @type {OrderModelAddressFrom}
     * @memberof SaveOrderRequest
     */
    'addressFrom'?: OrderModelAddressFrom | null;
    /**
     * 
     * @type {OrderModelAddressTo}
     * @memberof SaveOrderRequest
     */
    'addressTo'?: OrderModelAddressTo | null;
    /**
     * 
     * @type {OrderModelOrderType}
     * @memberof SaveOrderRequest
     */
    'orderType'?: OrderModelOrderType;
    /**
     * 
     * @type {OrderModelRepetitionFrequency}
     * @memberof SaveOrderRequest
     */
    'repetitionFrequency'?: OrderModelRepetitionFrequency;
    /**
     * 
     * @type {OrderModelState}
     * @memberof SaveOrderRequest
     */
    'state'?: OrderModelState;
    /**
     * Identifikátor slot instance pro odběr.
     * @type {number}
     * @memberof SaveOrderRequest
     */
    'slotInstanceId'?: number | null;
    /**
     * Identifikátor slot instance pro doručení.
     * @type {number}
     * @memberof SaveOrderRequest
     */
    'slotInstanceReturnId'?: number | null;
    /**
     * Datum.
     * @type {string}
     * @memberof SaveOrderRequest
     */
    'date': string;
    /**
     * Datum do.
     * @type {string}
     * @memberof SaveOrderRequest
     */
    'dateTo': string;
    /**
     * Interní poznámka.
     * @type {string}
     * @memberof SaveOrderRequest
     */
    'noteInternal'?: string;
    /**
     * Poznámka pro řidiče.
     * @type {string}
     * @memberof SaveOrderRequest
     */
    'noteDriver'?: string;
    /**
     * Doba trvání.
     * @type {number}
     * @memberof SaveOrderRequest
     */
    'duration'?: number;
    /**
     * Seznam časů objednávek.
     * @type {Array<OrderDayModel>}
     * @memberof SaveOrderRequest
     */
    'times'?: Array<OrderDayModel>;
    /**
     * Indikuje, zda je možné upravit adresy v objednávce.
     * @type {boolean}
     * @memberof SaveOrderRequest
     */
    'canChangeAddresses'?: boolean;
    /**
     * Datum poslední aktualizace
     * @type {string}
     * @memberof SaveOrderRequest
     */
    'updateSince'?: string;
}
/**
 * 
 * @export
 * @interface SaveOrderRequestAllOf
 */
export interface SaveOrderRequestAllOf {
    /**
     * Datum poslední aktualizace
     * @type {string}
     * @memberof SaveOrderRequestAllOf
     */
    'updateSince'?: string;
}
/**
 * 
 * @export
 * @interface SaveOrderResponse
 */
export interface SaveOrderResponse {
    /**
     * 
     * @type {string}
     * @memberof SaveOrderResponse
     */
    'message'?: string;
    /**
     * 
     * @type {number}
     * @memberof SaveOrderResponse
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof SaveOrderResponse
     */
    'instancesAffected'?: number;
}
/**
 * 
 * @export
 * @interface SaveSlotInstanceNoteResponse
 */
export interface SaveSlotInstanceNoteResponse {
    /**
     * Oznamení o provedené akci
     * @type {string}
     * @memberof SaveSlotInstanceNoteResponse
     */
    'message'?: string;
    /**
     * ID instance slotu
     * @type {number}
     * @memberof SaveSlotInstanceNoteResponse
     */
    'id'?: number;
}
/**
 * 
 * @export
 * @interface SaveSlotInstanceRequest
 */
export interface SaveSlotInstanceRequest {
    /**
     * ID instance slotu.
     * @type {number}
     * @memberof SaveSlotInstanceRequest
     */
    'id'?: number;
    /**
     * Název instance slotu.
     * @type {string}
     * @memberof SaveSlotInstanceRequest
     */
    'name': string;
    /**
     * ID dopravce.
     * @type {number}
     * @memberof SaveSlotInstanceRequest
     */
    'carrierId': number;
    /**
     * ID typu vozidla.
     * @type {number}
     * @memberof SaveSlotInstanceRequest
     */
    'vehicleTypeId': number;
    /**
     * Datum instance slotu.
     * @type {string}
     * @memberof SaveSlotInstanceRequest
     */
    'date'?: string;
    /**
     * Začátek instance slotu.
     * @type {string}
     * @memberof SaveSlotInstanceRequest
     */
    'start'?: string;
    /**
     * Konec instance slotu.
     * @type {string}
     * @memberof SaveSlotInstanceRequest
     */
    'end'?: string;
    /**
     * Začátek intervalu asistenta.
     * @type {string}
     * @memberof SaveSlotInstanceRequest
     */
    'assistantStart'?: string | null;
    /**
     * Konec intervalu asistenta.
     * @type {string}
     * @memberof SaveSlotInstanceRequest
     */
    'assistantEnd'?: string | null;
}
/**
 * 
 * @export
 * @interface SaveSlotInstanceResponse
 */
export interface SaveSlotInstanceResponse {
    /**
     * Zpráva o uložení instance slotu.
     * @type {string}
     * @memberof SaveSlotInstanceResponse
     */
    'message'?: string;
    /**
     * ID uložené instance slotu.
     * @type {number}
     * @memberof SaveSlotInstanceResponse
     */
    'id'?: number;
}
/**
 * 
 * @export
 * @interface SaveSlotRequest
 */
export interface SaveSlotRequest {
    /**
     * ID slotu 
     * @type {number}
     * @memberof SaveSlotRequest
     */
    'id'?: number;
    /**
     * Název slotu 
     * @type {string}
     * @memberof SaveSlotRequest
     */
    'name': string;
    /**
     * ID provozního dne 
     * @type {number}
     * @memberof SaveSlotRequest
     */
    'operatingDayId'?: number;
    /**
     * ID dopravce 
     * @type {number}
     * @memberof SaveSlotRequest
     */
    'carrierId'?: number;
    /**
     * ID typu vozidla 
     * @type {number}
     * @memberof SaveSlotRequest
     */
    'vehicleTypeId'?: number;
    /**
     * Počáteční čas
     * @type {string}
     * @memberof SaveSlotRequest
     */
    'start'?: string;
    /**
     * Konečný čas
     * @type {string}
     * @memberof SaveSlotRequest
     */
    'end'?: string;
    /**
     * Počáteční čas asistenta
     * @type {string}
     * @memberof SaveSlotRequest
     */
    'assistantStart'?: string | null;
    /**
     * Konečný čas asistenta
     * @type {string}
     * @memberof SaveSlotRequest
     */
    'assistantEnd'?: string | null;
    /**
     * Sekvence
     * @type {number}
     * @memberof SaveSlotRequest
     */
    'sequence'?: number;
    /**
     * Je aktivní
     * @type {boolean}
     * @memberof SaveSlotRequest
     */
    'isActive'?: boolean;
}
/**
 * 
 * @export
 * @interface SaveSlotResponse
 */
export interface SaveSlotResponse {
    /**
     * Zpráva
     * @type {string}
     * @memberof SaveSlotResponse
     */
    'message'?: string;
    /**
     * ID
     * @type {number}
     * @memberof SaveSlotResponse
     */
    'id'?: number;
}
/**
 * 
 * @export
 * @interface SaveUserRequest
 */
export interface SaveUserRequest {
    /**
     * Id
     * @type {number}
     * @memberof SaveUserRequest
     */
    'id'?: number;
    /**
     * Jméno
     * @type {string}
     * @memberof SaveUserRequest
     */
    'firstName': string;
    /**
     * Příjmení
     * @type {string}
     * @memberof SaveUserRequest
     */
    'lastName': string;
    /**
     * Email
     * @type {string}
     * @memberof SaveUserRequest
     */
    'email': string;
    /**
     * Osobní číslo
     * @type {number}
     * @memberof SaveUserRequest
     */
    'personalNumber'?: number | null;
    /**
     * Telefonní číslo
     * @type {string}
     * @memberof SaveUserRequest
     */
    'phoneNumber'?: string | null;
    /**
     * 
     * @type {UserModelAddress}
     * @memberof SaveUserRequest
     */
    'address'?: UserModelAddress | null;
    /**
     * Id profilového obrázku
     * @type {number}
     * @memberof SaveUserRequest
     */
    'avatarImageId'?: number | null;
    /**
     * Role
     * @type {Array<RoleModel>}
     * @memberof SaveUserRequest
     */
    'roles': Array<RoleModel>;
    /**
     * Aktivní
     * @type {boolean}
     * @memberof SaveUserRequest
     */
    'isActive'?: boolean;
    /**
     * Seznam refresh tokenů
     * @type {Array<RefreshTokenModel>}
     * @memberof SaveUserRequest
     */
    'refreshTokenModels'?: Array<RefreshTokenModel>;
}
/**
 * 
 * @export
 * @interface SaveUserResponse
 */
export interface SaveUserResponse {
    /**
     * 
     * @type {string}
     * @memberof SaveUserResponse
     */
    'message'?: string;
    /**
     * 
     * @type {number}
     * @memberof SaveUserResponse
     */
    'id'?: number;
}
/**
 * 
 * @export
 * @interface SaveVehicleRequest
 */
export interface SaveVehicleRequest {
    /**
     * ID vozidla
     * @type {number}
     * @memberof SaveVehicleRequest
     */
    'id'?: number;
    /**
     * Název vozidla.
     * @type {string}
     * @memberof SaveVehicleRequest
     */
    'name': string;
    /**
     * ID typu vozidla.
     * @type {number}
     * @memberof SaveVehicleRequest
     */
    'typeId': number;
    /**
     * Registrační značka vozidla.
     * @type {string}
     * @memberof SaveVehicleRequest
     */
    'plateNumber': string;
    /**
     * Registrační číslo vozidla.
     * @type {number}
     * @memberof SaveVehicleRequest
     */
    'registrationNumber': number;
    /**
     * VIN (Vehicle Identification Number) vozidla.
     * @type {string}
     * @memberof SaveVehicleRequest
     */
    'vin': string;
    /**
     * Telefonní číslo spojené s vozidlem.
     * @type {string}
     * @memberof SaveVehicleRequest
     */
    'phoneNumber'?: string;
    /**
     * ID přepravce.
     * @type {number}
     * @memberof SaveVehicleRequest
     */
    'carrierId': number;
    /**
     * Kód GpsDozor pro vozidlo.
     * @type {string}
     * @memberof SaveVehicleRequest
     */
    'gpsDozorCode': string;
}
/**
 * 
 * @export
 * @interface SaveVehicleResponse
 */
export interface SaveVehicleResponse {
    /**
     * 
     * @type {string}
     * @memberof SaveVehicleResponse
     */
    'message'?: string;
    /**
     * 
     * @type {number}
     * @memberof SaveVehicleResponse
     */
    'id'?: number;
}
/**
 * 
 * @export
 * @interface SaveVehicleTypeEquipmentRequest
 */
export interface SaveVehicleTypeEquipmentRequest {
    /**
     * Seznam modelů s množstvím vybavení.
     * @type {Array<EquipmentAmountModel>}
     * @memberof SaveVehicleTypeEquipmentRequest
     */
    'equipments'?: Array<EquipmentAmountModel>;
}
/**
 * 
 * @export
 * @interface SaveVehicleTypeEquipmentResponse
 */
export interface SaveVehicleTypeEquipmentResponse {
    /**
     * Zpráva o úspěšném uložení varianty obsazení vozidla.
     * @type {string}
     * @memberof SaveVehicleTypeEquipmentResponse
     */
    'message'?: string;
    /**
     * ID typu vozidla.
     * @type {number}
     * @memberof SaveVehicleTypeEquipmentResponse
     */
    'typeId'?: number;
    /**
     * ID varianty vybavení.
     * @type {number}
     * @memberof SaveVehicleTypeEquipmentResponse
     */
    'variantId'?: number;
}
/**
 * 
 * @export
 * @interface SaveVehicleTypeRequest
 */
export interface SaveVehicleTypeRequest {
    /**
     * ID typu vozidla (pro aktualizaci, když je známé).
     * @type {number}
     * @memberof SaveVehicleTypeRequest
     */
    'id'?: number;
    /**
     * Název typu vozidla.
     * @type {string}
     * @memberof SaveVehicleTypeRequest
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface SaveVehicleTypeResponse
 */
export interface SaveVehicleTypeResponse {
    /**
     * Zpráva oznamující úspěšné uložení typu vozidla.
     * @type {string}
     * @memberof SaveVehicleTypeResponse
     */
    'message'?: string;
    /**
     * ID uloženého typu vozidla.
     * @type {number}
     * @memberof SaveVehicleTypeResponse
     */
    'id'?: number;
}
/**
 * 
 * @export
 * @interface SequenceSlotsRequest
 */
export interface SequenceSlotsRequest {
    /**
     * 
     * @type {Array<SlotSequence>}
     * @memberof SequenceSlotsRequest
     */
    'sequences': Array<SlotSequence>;
}
/**
 * 
 * @export
 * @interface SetOrderInstanceModel
 */
export interface SetOrderInstanceModel {
    /**
     * Id instance objednávky.
     * @type {number}
     * @memberof SetOrderInstanceModel
     */
    'id'?: number;
    /**
     * Datum a čas vyzvednutí.
     * @type {string}
     * @memberof SetOrderInstanceModel
     */
    'pickup'?: string;
    /**
     * Datum a čas vyložení.
     * @type {string}
     * @memberof SetOrderInstanceModel
     */
    'dropoff'?: string;
    /**
     * 
     * @type {SetOrderInstanceModelEscort}
     * @memberof SetOrderInstanceModel
     */
    'escort'?: SetOrderInstanceModelEscort | null;
    /**
     * Id pomůcky pro handicapované
     * @type {number}
     * @memberof SetOrderInstanceModel
     */
    'handicapEquipmentId'?: number;
    /**
     * Id doprovodné pomůcky pro handicapované
     * @type {number}
     * @memberof SetOrderInstanceModel
     */
    'escortHandicapEquipmentId'?: number | null;
    /**
     * Přítomnost doprovodného psa.
     * @type {boolean}
     * @memberof SetOrderInstanceModel
     */
    'escortDog'?: boolean;
    /**
     * Příruční zavazadlo
     * @type {boolean}
     * @memberof SetOrderInstanceModel
     */
    'escortLuggage'?: boolean;
    /**
     * 
     * @type {SetOrderInstanceModelState}
     * @memberof SetOrderInstanceModel
     */
    'state'?: SetOrderInstanceModelState;
    /**
     * Poznámka.
     * @type {string}
     * @memberof SetOrderInstanceModel
     */
    'note'?: string | null;
}
/**
 * @type SetOrderInstanceModelEscort
 * Typ doprovodu.
 * @export
 */
export type SetOrderInstanceModelEscort = Escort;

/**
 * @type SetOrderInstanceModelState
 * Stav instance objednávky.
 * @export
 */
export type SetOrderInstanceModelState = OrderInstanceState;

/**
 * 
 * @export
 * @interface SetShiftModel
 */
export interface SetShiftModel {
    /**
     * Id
     * @type {number}
     * @memberof SetShiftModel
     */
    'id'?: number;
    /**
     * Id objektu
     * @type {number}
     * @memberof SetShiftModel
     */
    'objectId'?: number;
    /**
     * Počáteční čas
     * @type {string}
     * @memberof SetShiftModel
     */
    'start'?: string;
    /**
     * Koncový čas
     * @type {string}
     * @memberof SetShiftModel
     */
    'end'?: string;
    /**
     * Text
     * @type {string}
     * @memberof SetShiftModel
     */
    'text'?: string;
    /**
     * Je odkazováno
     * @type {boolean}
     * @memberof SetShiftModel
     */
    'isReferenced'?: boolean;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ShiftType = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2
} as const;

export type ShiftType = typeof ShiftType[keyof typeof ShiftType];


/**
 * 
 * @export
 * @interface SingleOrderHandicapEquipmentModel
 */
export interface SingleOrderHandicapEquipmentModel {
    /**
     * 
     * @type {number}
     * @memberof SingleOrderHandicapEquipmentModel
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof SingleOrderHandicapEquipmentModel
     */
    'abbr'?: string;
    /**
     * 
     * @type {string}
     * @memberof SingleOrderHandicapEquipmentModel
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface SlotInstanceModel
 */
export interface SlotInstanceModel {
    /**
     * Id
     * @type {number}
     * @memberof SlotInstanceModel
     */
    'id'?: number;
    /**
     * Id slotu
     * @type {number}
     * @memberof SlotInstanceModel
     */
    'slotId'?: number;
    /**
     * Název
     * @type {string}
     * @memberof SlotInstanceModel
     */
    'name'?: string;
    /**
     * Zkratka přepravce
     * @type {string}
     * @memberof SlotInstanceModel
     */
    'carrierNameShort'?: string;
    /**
     * Název typu vozidla
     * @type {string}
     * @memberof SlotInstanceModel
     */
    'vehicleTypeName'?: string;
    /**
     * Počáteční datum a čas
     * @type {string}
     * @memberof SlotInstanceModel
     */
    'start'?: string;
    /**
     * Koncové datum a čas
     * @type {string}
     * @memberof SlotInstanceModel
     */
    'end'?: string;
    /**
     * Počáteční datum a čas asistenta
     * @type {string}
     * @memberof SlotInstanceModel
     */
    'assistantStart'?: string | null;
    /**
     * Koncové datum a čas asistenta
     * @type {string}
     * @memberof SlotInstanceModel
     */
    'assistantEnd'?: string | null;
    /**
     * Aktivní
     * @type {boolean}
     * @memberof SlotInstanceModel
     */
    'isActive'?: boolean;
    /**
     * Id dopravce
     * @type {number}
     * @memberof SlotInstanceModel
     */
    'carrierId'?: number | null;
    /**
     * Id typu vozidla
     * @type {number}
     * @memberof SlotInstanceModel
     */
    'vehicleTypeId'?: number | null;
    /**
     * Seznam směn vozidla
     * @type {Array<GetShiftModel>}
     * @memberof SlotInstanceModel
     */
    'vehicleShifts'?: Array<GetShiftModel>;
    /**
     * Seznam směn řidiče
     * @type {Array<GetShiftModel>}
     * @memberof SlotInstanceModel
     */
    'driverShifts'?: Array<GetShiftModel>;
    /**
     * Seznam směn asistenta
     * @type {Array<GetShiftModel>}
     * @memberof SlotInstanceModel
     */
    'assistantShifts'?: Array<GetShiftModel>;
    /**
     * Seznam poznámek k instanci slotu
     * @type {Array<SlotInstanceNoteModel>}
     * @memberof SlotInstanceModel
     */
    'notes'?: Array<SlotInstanceNoteModel>;
}
/**
 * 
 * @export
 * @interface SlotInstanceNoteModel
 */
export interface SlotInstanceNoteModel {
    /**
     * Id
     * @type {number}
     * @memberof SlotInstanceNoteModel
     */
    'id'?: number;
    /**
     * Id instance slotu
     * @type {number}
     * @memberof SlotInstanceNoteModel
     */
    'slotInstanceId'?: number;
    /**
     * Počáteční datum a čas
     * @type {string}
     * @memberof SlotInstanceNoteModel
     */
    'start'?: string;
    /**
     * Datum ukončení
     * @type {string}
     * @memberof SlotInstanceNoteModel
     */
    'end'?: string;
    /**
     * Text
     * @type {string}
     * @memberof SlotInstanceNoteModel
     */
    'text'?: string;
    /**
     * Text pro řidiče
     * @type {string}
     * @memberof SlotInstanceNoteModel
     */
    'driverText'?: string;
}
/**
 * 
 * @export
 * @interface SlotInstancePlanningModel
 */
export interface SlotInstancePlanningModel {
    /**
     * Identifikátor instance slotu.
     * @type {number}
     * @memberof SlotInstancePlanningModel
     */
    'id'?: number;
    /**
     * Identifikátor slotu.
     * @type {number}
     * @memberof SlotInstancePlanningModel
     */
    'slotId'?: number;
    /**
     * Název instance slotu.
     * @type {string}
     * @memberof SlotInstancePlanningModel
     */
    'name'?: string;
    /**
     * Identifikátor typu vozidla.
     * @type {number}
     * @memberof SlotInstancePlanningModel
     */
    'vehicleTypeId'?: number | null;
    /**
     * Název typu vozidla.
     * @type {string}
     * @memberof SlotInstancePlanningModel
     */
    'vehicleTypeName'?: string;
    /**
     * Počáteční datum instance slotu.
     * @type {string}
     * @memberof SlotInstancePlanningModel
     */
    'start'?: string;
    /**
     * Konečné datum instance slotu.
     * @type {string}
     * @memberof SlotInstancePlanningModel
     */
    'end'?: string;
    /**
     * Indikuje, zda je instance slotu aktivní.
     * @type {boolean}
     * @memberof SlotInstancePlanningModel
     */
    'isActive'?: boolean;
    /**
     * Pořadí instance slotu.
     * @type {number}
     * @memberof SlotInstancePlanningModel
     */
    'sequence'?: number;
    /**
     * Seznam směn vozidla.
     * @type {Array<GetShiftPlanningModel>}
     * @memberof SlotInstancePlanningModel
     */
    'vehicleShifts'?: Array<GetShiftPlanningModel>;
    /**
     * Seznam směn řidiče.
     * @type {Array<GetShiftPlanningModel>}
     * @memberof SlotInstancePlanningModel
     */
    'driverShifts'?: Array<GetShiftPlanningModel>;
    /**
     * Seznam směn asistenta.
     * @type {Array<GetShiftPlanningModel>}
     * @memberof SlotInstancePlanningModel
     */
    'assistantShifts'?: Array<GetShiftPlanningModel>;
    /**
     * Seznam poznámek k instanci slotu.
     * @type {Array<SlotInstanceNoteModel>}
     * @memberof SlotInstancePlanningModel
     */
    'notes'?: Array<SlotInstanceNoteModel>;
}
/**
 * 
 * @export
 * @interface SlotInstanceShiftLogModel
 */
export interface SlotInstanceShiftLogModel {
    /**
     * Id
     * @type {number}
     * @memberof SlotInstanceShiftLogModel
     */
    'id'?: number;
    /**
     * Id¨slotu
     * @type {number}
     * @memberof SlotInstanceShiftLogModel
     */
    'slotId'?: number | null;
    /**
     * Název
     * @type {string}
     * @memberof SlotInstanceShiftLogModel
     */
    'name'?: string;
    /**
     * Krátký název dopravce
     * @type {string}
     * @memberof SlotInstanceShiftLogModel
     */
    'carrierNameShort'?: string;
    /**
     * Název typu vozidla
     * @type {string}
     * @memberof SlotInstanceShiftLogModel
     */
    'vehicleTypeName'?: string;
    /**
     * Datum a čas zahájení
     * @type {string}
     * @memberof SlotInstanceShiftLogModel
     */
    'start'?: string;
    /**
     * Datum a čas ukončení
     * @type {string}
     * @memberof SlotInstanceShiftLogModel
     */
    'end'?: string;
}
/**
 * 
 * @export
 * @interface SlotRideModel
 */
export interface SlotRideModel {
    /**
     * Název slotu.
     * @type {string}
     * @memberof SlotRideModel
     */
    'slotName'?: string;
    /**
     * ID typu vozidla.
     * @type {number}
     * @memberof SlotRideModel
     */
    'vehicleTypeId'?: number;
}
/**
 * 
 * @export
 * @interface SlotSequence
 */
export interface SlotSequence {
    /**
     * ID slotu 
     * @type {number}
     * @memberof SlotSequence
     */
    'slotId'?: number;
    /**
     * Sekvence 
     * @type {number}
     * @memberof SlotSequence
     */
    'sequence'?: number;
}
/**
 * 
 * @export
 * @interface StartLogShiftRequest
 */
export interface StartLogShiftRequest {
    /**
     * ID řidičské směny.
     * @type {number}
     * @memberof StartLogShiftRequest
     */
    'driverShiftId': number;
    /**
     * ID vozové směny.
     * @type {number}
     * @memberof StartLogShiftRequest
     */
    'vehicleShiftId': number;
    /**
     * Zda jde o simulovanou směnu.
     * @type {boolean}
     * @memberof StartLogShiftRequest
     */
    'simulated'?: boolean | null;
    /**
     * Datum a čas pro zahájení směny (pouze pro simulovanou směnu).
     * @type {string}
     * @memberof StartLogShiftRequest
     */
    'dateTime': string;
}
/**
 * 
 * @export
 * @interface StartLogShiftResponse
 */
export interface StartLogShiftResponse {
    /**
     * ID zahájené směny.
     * @type {number}
     * @memberof StartLogShiftResponse
     */
    'id'?: number;
}
/**
 * 
 * @export
 * @interface StartableShift
 */
export interface StartableShift {
    /**
     * ID řidiče směny
     * @type {number}
     * @memberof StartableShift
     */
    'driverShiftId'?: number;
    /**
     * ID vozidla směny
     * @type {number}
     * @memberof StartableShift
     */
    'vehicleShiftId'?: number;
    /**
     * Čas začátku vozidla
     * @type {string}
     * @memberof StartableShift
     */
    'vehicleStartTime'?: string;
    /**
     * Název vozidla
     * @type {string}
     * @memberof StartableShift
     */
    'vehicleName'?: string;
    /**
     * Registrační číslo vozidla
     * @type {number}
     * @memberof StartableShift
     */
    'registrationNumber'?: number;
}
/**
 * 
 * @export
 * @interface StopModel
 */
export interface StopModel {
    /**
     * Identifikátor entity OrderDay, ze které vznikla instance objednávky
     * @type {number}
     * @memberof StopModel
     */
    'orderDayId'?: number;
    /**
     * 
     * @type {StopModelStopType}
     * @memberof StopModel
     */
    'stopType'?: StopModelStopType;
}
/**
 * @type StopModelStopType
 * Naložení/vyložení
 * @export
 */
export type StopModelStopType = StopType;

/**
 * 
 * @export
 * @enum {string}
 */

export const StopType = {
    NUMBER_1: 1,
    NUMBER_2: 2
} as const;

export type StopType = typeof StopType[keyof typeof StopType];


/**
 * 
 * @export
 * @interface SwitchVehicleShiftRequest
 */
export interface SwitchVehicleShiftRequest {
    /**
     * ID řidičské směny.
     * @type {number}
     * @memberof SwitchVehicleShiftRequest
     */
    'driverShiftId': number;
    /**
     * ID směny vozidla.
     * @type {number}
     * @memberof SwitchVehicleShiftRequest
     */
    'vehicleShiftId': number;
    /**
     * ID nové směny vozidla.
     * @type {number}
     * @memberof SwitchVehicleShiftRequest
     */
    'newVehicleShiftId': number;
}
/**
 * 
 * @export
 * @interface SwitchVehicleShiftResponse
 */
export interface SwitchVehicleShiftResponse {
    /**
     * ID nové směny vozidla.
     * @type {number}
     * @memberof SwitchVehicleShiftResponse
     */
    'id'?: number;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const TimeType = {
    NUMBER_1: 1,
    NUMBER_2: 2
} as const;

export type TimeType = typeof TimeType[keyof typeof TimeType];


/**
 * 
 * @export
 * @interface UnjoinRideRequest
 */
export interface UnjoinRideRequest {
    /**
     * ID instance objednávky
     * @type {number}
     * @memberof UnjoinRideRequest
     */
    'instanceId': number;
    /**
     * 
     * @type {UnjoinRideRequestType}
     * @memberof UnjoinRideRequest
     */
    'type'?: UnjoinRideRequestType;
}
/**
 * @type UnjoinRideRequestType
 * Typ rozdělení
 * @export
 */
export type UnjoinRideRequestType = UnjoinType;

/**
 * 
 * @export
 * @interface UnjoinRideResponse
 */
export interface UnjoinRideResponse {
    /**
     * Počet ovlivněných jízd
     * @type {number}
     * @memberof UnjoinRideResponse
     */
    'affected'?: number;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const UnjoinType = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3,
    NUMBER_4: 4,
    NUMBER_5: 5,
    NUMBER_6: 6
} as const;

export type UnjoinType = typeof UnjoinType[keyof typeof UnjoinType];


/**
 * 
 * @export
 * @interface UploadImageResponse
 */
export interface UploadImageResponse {
    /**
     * ID nahrávaného obrázku
     * @type {number}
     * @memberof UploadImageResponse
     */
    'imageId'?: number;
    /**
     * Zpráva o stavu provedené akce
     * @type {string}
     * @memberof UploadImageResponse
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface UserModel
 */
export interface UserModel {
    /**
     * Id
     * @type {number}
     * @memberof UserModel
     */
    'id'?: number;
    /**
     * Jméno
     * @type {string}
     * @memberof UserModel
     */
    'firstName': string;
    /**
     * Příjmení
     * @type {string}
     * @memberof UserModel
     */
    'lastName': string;
    /**
     * Email
     * @type {string}
     * @memberof UserModel
     */
    'email': string;
    /**
     * Osobní číslo
     * @type {number}
     * @memberof UserModel
     */
    'personalNumber'?: number | null;
    /**
     * Telefonní číslo
     * @type {string}
     * @memberof UserModel
     */
    'phoneNumber'?: string | null;
    /**
     * 
     * @type {UserModelAddress}
     * @memberof UserModel
     */
    'address'?: UserModelAddress | null;
    /**
     * Id profilového obrázku
     * @type {number}
     * @memberof UserModel
     */
    'avatarImageId'?: number | null;
    /**
     * Role
     * @type {Array<RoleModel>}
     * @memberof UserModel
     */
    'roles': Array<RoleModel>;
    /**
     * Aktivní
     * @type {boolean}
     * @memberof UserModel
     */
    'isActive'?: boolean;
    /**
     * Seznam refresh tokenů
     * @type {Array<RefreshTokenModel>}
     * @memberof UserModel
     */
    'refreshTokenModels'?: Array<RefreshTokenModel>;
}
/**
 * @type UserModelAddress
 * Adresa
 * @export
 */
export type UserModelAddress = AddressModel;

/**
 * 
 * @export
 * @interface VehicleTypeModel
 */
export interface VehicleTypeModel {
    /**
     * Id
     * @type {number}
     * @memberof VehicleTypeModel
     */
    'id'?: number;
    /**
     * Název
     * @type {string}
     * @memberof VehicleTypeModel
     */
    'name'?: string;
    /**
     * Seznam variant pomůcek pro handicapované
     * @type {Array<EquipmentMultisetModel>}
     * @memberof VehicleTypeModel
     */
    'equipmentVariants'?: Array<EquipmentMultisetModel>;
}

/**
 * AddressApi - axios parameter creator
 * @export
 */
export const AddressApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Tento endpoint umožňuje autorizovaným uživatelům získat seznam adres
         * @summary Vrátí seznam adres
         * @param {boolean} isActive Je adresa aktivní
         * @param {number} page 
         * @param {number} pageSize 
         * @param {boolean} isDescendingSort 
         * @param {number} [customerId] ID zakázníka, pod kterého spadají hledané adresy
         * @param {string} [name] Jméno zákazníka, pod kterého spadají hledané adresy
         * @param {string} [street] Název ulice, pod kterou spadají hledané adresy
         * @param {Array<number>} [addressTypes] Seznam typů adres, které chcete vrátit
         * @param {string} [sortPropertyName] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAddresses: async (isActive: boolean, page: number, pageSize: number, isDescendingSort: boolean, customerId?: number, name?: string, street?: string, addressTypes?: Array<number>, sortPropertyName?: string, search?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'isActive' is not null or undefined
            assertParamExists('getAddresses', 'isActive', isActive)
            // verify required parameter 'page' is not null or undefined
            assertParamExists('getAddresses', 'page', page)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('getAddresses', 'pageSize', pageSize)
            // verify required parameter 'isDescendingSort' is not null or undefined
            assertParamExists('getAddresses', 'isDescendingSort', isDescendingSort)
            const localVarPath = `/address`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (customerId !== undefined) {
                localVarQueryParameter['CustomerId'] = customerId;
            }

            if (name !== undefined) {
                localVarQueryParameter['Name'] = name;
            }

            if (street !== undefined) {
                localVarQueryParameter['Street'] = street;
            }

            if (addressTypes) {
                localVarQueryParameter['AddressTypes'] = addressTypes;
            }

            if (isActive !== undefined) {
                localVarQueryParameter['IsActive'] = isActive;
            }

            if (page !== undefined) {
                localVarQueryParameter['Page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (sortPropertyName !== undefined) {
                localVarQueryParameter['SortPropertyName'] = sortPropertyName;
            }

            if (isDescendingSort !== undefined) {
                localVarQueryParameter['IsDescendingSort'] = isDescendingSort;
            }

            if (search !== undefined) {
                localVarQueryParameter['Search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Tento endpoint umožňuje aktualizaci geografických informací pro konkrétní adresu. Aktualizace může obsahovat informace o zeměpisné šířce a délce (Latitude a Longitude), a identifikátoru místa v Google Maps (GooglePlaceId). Pokud adresa nemá přiřazené geografické informace, může být prostřednictvím tohoto endpointu aktualizována.
         * @summary Aktualizace geografických informací adresy
         * @param {PatchAddressGeocodingRequest} patchAddressGeocodingRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchAddressGeocoding: async (patchAddressGeocodingRequest: PatchAddressGeocodingRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patchAddressGeocodingRequest' is not null or undefined
            assertParamExists('patchAddressGeocoding', 'patchAddressGeocodingRequest', patchAddressGeocodingRequest)
            const localVarPath = `/address/geocoding`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchAddressGeocodingRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Tento endpoint uloží adresu do databáze
         * @summary Uloží adresu
         * @param {AddressModel} addressModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveAddress: async (addressModel: AddressModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'addressModel' is not null or undefined
            assertParamExists('saveAddress', 'addressModel', addressModel)
            const localVarPath = `/address`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addressModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AddressApi - functional programming interface
 * @export
 */
export const AddressApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AddressApiAxiosParamCreator(configuration)
    return {
        /**
         * Tento endpoint umožňuje autorizovaným uživatelům získat seznam adres
         * @summary Vrátí seznam adres
         * @param {boolean} isActive Je adresa aktivní
         * @param {number} page 
         * @param {number} pageSize 
         * @param {boolean} isDescendingSort 
         * @param {number} [customerId] ID zakázníka, pod kterého spadají hledané adresy
         * @param {string} [name] Jméno zákazníka, pod kterého spadají hledané adresy
         * @param {string} [street] Název ulice, pod kterou spadají hledané adresy
         * @param {Array<number>} [addressTypes] Seznam typů adres, které chcete vrátit
         * @param {string} [sortPropertyName] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAddresses(isActive: boolean, page: number, pageSize: number, isDescendingSort: boolean, customerId?: number, name?: string, street?: string, addressTypes?: Array<number>, sortPropertyName?: string, search?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAddressesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAddresses(isActive, page, pageSize, isDescendingSort, customerId, name, street, addressTypes, sortPropertyName, search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Tento endpoint umožňuje aktualizaci geografických informací pro konkrétní adresu. Aktualizace může obsahovat informace o zeměpisné šířce a délce (Latitude a Longitude), a identifikátoru místa v Google Maps (GooglePlaceId). Pokud adresa nemá přiřazené geografické informace, může být prostřednictvím tohoto endpointu aktualizována.
         * @summary Aktualizace geografických informací adresy
         * @param {PatchAddressGeocodingRequest} patchAddressGeocodingRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchAddressGeocoding(patchAddressGeocodingRequest: PatchAddressGeocodingRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchAddressGeocoding(patchAddressGeocodingRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Tento endpoint uloží adresu do databáze
         * @summary Uloží adresu
         * @param {AddressModel} addressModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveAddress(addressModel: AddressModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SaveAddressResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveAddress(addressModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AddressApi - factory interface
 * @export
 */
export const AddressApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AddressApiFp(configuration)
    return {
        /**
         * Tento endpoint umožňuje autorizovaným uživatelům získat seznam adres
         * @summary Vrátí seznam adres
         * @param {boolean} isActive Je adresa aktivní
         * @param {number} page 
         * @param {number} pageSize 
         * @param {boolean} isDescendingSort 
         * @param {number} [customerId] ID zakázníka, pod kterého spadají hledané adresy
         * @param {string} [name] Jméno zákazníka, pod kterého spadají hledané adresy
         * @param {string} [street] Název ulice, pod kterou spadají hledané adresy
         * @param {Array<number>} [addressTypes] Seznam typů adres, které chcete vrátit
         * @param {string} [sortPropertyName] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAddresses(isActive: boolean, page: number, pageSize: number, isDescendingSort: boolean, customerId?: number, name?: string, street?: string, addressTypes?: Array<number>, sortPropertyName?: string, search?: string, options?: any): AxiosPromise<GetAddressesResponse> {
            return localVarFp.getAddresses(isActive, page, pageSize, isDescendingSort, customerId, name, street, addressTypes, sortPropertyName, search, options).then((request) => request(axios, basePath));
        },
        /**
         * Tento endpoint umožňuje aktualizaci geografických informací pro konkrétní adresu. Aktualizace může obsahovat informace o zeměpisné šířce a délce (Latitude a Longitude), a identifikátoru místa v Google Maps (GooglePlaceId). Pokud adresa nemá přiřazené geografické informace, může být prostřednictvím tohoto endpointu aktualizována.
         * @summary Aktualizace geografických informací adresy
         * @param {PatchAddressGeocodingRequest} patchAddressGeocodingRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchAddressGeocoding(patchAddressGeocodingRequest: PatchAddressGeocodingRequest, options?: any): AxiosPromise<void> {
            return localVarFp.patchAddressGeocoding(patchAddressGeocodingRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Tento endpoint uloží adresu do databáze
         * @summary Uloží adresu
         * @param {AddressModel} addressModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveAddress(addressModel: AddressModel, options?: any): AxiosPromise<SaveAddressResponse> {
            return localVarFp.saveAddress(addressModel, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AddressApi - object-oriented interface
 * @export
 * @class AddressApi
 * @extends {BaseAPI}
 */
export class AddressApi extends BaseAPI {
    /**
     * Tento endpoint umožňuje autorizovaným uživatelům získat seznam adres
     * @summary Vrátí seznam adres
     * @param {boolean} isActive Je adresa aktivní
     * @param {number} page 
     * @param {number} pageSize 
     * @param {boolean} isDescendingSort 
     * @param {number} [customerId] ID zakázníka, pod kterého spadají hledané adresy
     * @param {string} [name] Jméno zákazníka, pod kterého spadají hledané adresy
     * @param {string} [street] Název ulice, pod kterou spadají hledané adresy
     * @param {Array<number>} [addressTypes] Seznam typů adres, které chcete vrátit
     * @param {string} [sortPropertyName] 
     * @param {string} [search] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AddressApi
     */
    public getAddresses(isActive: boolean, page: number, pageSize: number, isDescendingSort: boolean, customerId?: number, name?: string, street?: string, addressTypes?: Array<number>, sortPropertyName?: string, search?: string, options?: AxiosRequestConfig) {
        return AddressApiFp(this.configuration).getAddresses(isActive, page, pageSize, isDescendingSort, customerId, name, street, addressTypes, sortPropertyName, search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Tento endpoint umožňuje aktualizaci geografických informací pro konkrétní adresu. Aktualizace může obsahovat informace o zeměpisné šířce a délce (Latitude a Longitude), a identifikátoru místa v Google Maps (GooglePlaceId). Pokud adresa nemá přiřazené geografické informace, může být prostřednictvím tohoto endpointu aktualizována.
     * @summary Aktualizace geografických informací adresy
     * @param {PatchAddressGeocodingRequest} patchAddressGeocodingRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AddressApi
     */
    public patchAddressGeocoding(patchAddressGeocodingRequest: PatchAddressGeocodingRequest, options?: AxiosRequestConfig) {
        return AddressApiFp(this.configuration).patchAddressGeocoding(patchAddressGeocodingRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Tento endpoint uloží adresu do databáze
     * @summary Uloží adresu
     * @param {AddressModel} addressModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AddressApi
     */
    public saveAddress(addressModel: AddressModel, options?: AxiosRequestConfig) {
        return AddressApiFp(this.configuration).saveAddress(addressModel, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AuditsApi - axios parameter creator
 * @export
 */
export const AuditsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Tento endpoint umožňuje získání auditních záznamů pro konkrétní entitu. Auditní záznamy obsahují informace o změnách v datech, jako jsou revize, kdo provedl změny a kdy byly provedeny.
         * @summary Získání auditních záznamů pro entitu
         * @param {number} id ID entity pro získání auditních záznamů.
         * @param {string} entityType Typ entity (např. zákazník, produkt), pro kterou chcete získat auditní záznamy.
         * @param {number} page 
         * @param {number} pageSize 
         * @param {boolean} isDescendingSort 
         * @param {string} [sortPropertyName] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEntityAudit: async (id: number, entityType: string, page: number, pageSize: number, isDescendingSort: boolean, sortPropertyName?: string, search?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getEntityAudit', 'id', id)
            // verify required parameter 'entityType' is not null or undefined
            assertParamExists('getEntityAudit', 'entityType', entityType)
            // verify required parameter 'page' is not null or undefined
            assertParamExists('getEntityAudit', 'page', page)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('getEntityAudit', 'pageSize', pageSize)
            // verify required parameter 'isDescendingSort' is not null or undefined
            assertParamExists('getEntityAudit', 'isDescendingSort', isDescendingSort)
            const localVarPath = `/audits/entity/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (entityType !== undefined) {
                localVarQueryParameter['EntityType'] = entityType;
            }

            if (page !== undefined) {
                localVarQueryParameter['Page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (sortPropertyName !== undefined) {
                localVarQueryParameter['SortPropertyName'] = sortPropertyName;
            }

            if (isDescendingSort !== undefined) {
                localVarQueryParameter['IsDescendingSort'] = isDescendingSort;
            }

            if (search !== undefined) {
                localVarQueryParameter['Search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuditsApi - functional programming interface
 * @export
 */
export const AuditsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuditsApiAxiosParamCreator(configuration)
    return {
        /**
         * Tento endpoint umožňuje získání auditních záznamů pro konkrétní entitu. Auditní záznamy obsahují informace o změnách v datech, jako jsou revize, kdo provedl změny a kdy byly provedeny.
         * @summary Získání auditních záznamů pro entitu
         * @param {number} id ID entity pro získání auditních záznamů.
         * @param {string} entityType Typ entity (např. zákazník, produkt), pro kterou chcete získat auditní záznamy.
         * @param {number} page 
         * @param {number} pageSize 
         * @param {boolean} isDescendingSort 
         * @param {string} [sortPropertyName] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEntityAudit(id: number, entityType: string, page: number, pageSize: number, isDescendingSort: boolean, sortPropertyName?: string, search?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCutomerAuditResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEntityAudit(id, entityType, page, pageSize, isDescendingSort, sortPropertyName, search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AuditsApi - factory interface
 * @export
 */
export const AuditsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuditsApiFp(configuration)
    return {
        /**
         * Tento endpoint umožňuje získání auditních záznamů pro konkrétní entitu. Auditní záznamy obsahují informace o změnách v datech, jako jsou revize, kdo provedl změny a kdy byly provedeny.
         * @summary Získání auditních záznamů pro entitu
         * @param {number} id ID entity pro získání auditních záznamů.
         * @param {string} entityType Typ entity (např. zákazník, produkt), pro kterou chcete získat auditní záznamy.
         * @param {number} page 
         * @param {number} pageSize 
         * @param {boolean} isDescendingSort 
         * @param {string} [sortPropertyName] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEntityAudit(id: number, entityType: string, page: number, pageSize: number, isDescendingSort: boolean, sortPropertyName?: string, search?: string, options?: any): AxiosPromise<GetCutomerAuditResponse> {
            return localVarFp.getEntityAudit(id, entityType, page, pageSize, isDescendingSort, sortPropertyName, search, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AuditsApi - object-oriented interface
 * @export
 * @class AuditsApi
 * @extends {BaseAPI}
 */
export class AuditsApi extends BaseAPI {
    /**
     * Tento endpoint umožňuje získání auditních záznamů pro konkrétní entitu. Auditní záznamy obsahují informace o změnách v datech, jako jsou revize, kdo provedl změny a kdy byly provedeny.
     * @summary Získání auditních záznamů pro entitu
     * @param {number} id ID entity pro získání auditních záznamů.
     * @param {string} entityType Typ entity (např. zákazník, produkt), pro kterou chcete získat auditní záznamy.
     * @param {number} page 
     * @param {number} pageSize 
     * @param {boolean} isDescendingSort 
     * @param {string} [sortPropertyName] 
     * @param {string} [search] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuditsApi
     */
    public getEntityAudit(id: number, entityType: string, page: number, pageSize: number, isDescendingSort: boolean, sortPropertyName?: string, search?: string, options?: AxiosRequestConfig) {
        return AuditsApiFp(this.configuration).getEntityAudit(id, entityType, page, pageSize, isDescendingSort, sortPropertyName, search, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CarrierApi - axios parameter creator
 * @export
 */
export const CarrierApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Tento endpoint umožňuje odstranit existujícího dopravce.
         * @summary Odstranění dopravce
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCarrier: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteCarrier', 'id', id)
            const localVarPath = `/carrier/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Tento endpoint umožòuje získat informace o konkrétním dopravci. Zahrnuje údaje o dopravci, jako jsou jméno, adresa a další.
         * @summary Vrátí informace o dopravci
         * @param {number} id ID dopravce
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCarrier: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getCarrier', 'id', id)
            const localVarPath = `/carrier/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Tento endpoint umožňuje získat seznam dopravců. Zahrnuje informace o jednotlivých dopravcích.
         * @summary Vrátí seznam dopravců
         * @param {boolean} isActive Stav aktivity, true pro aktivaci, false pro deaktivaci.
         * @param {number} page 
         * @param {number} pageSize 
         * @param {boolean} isDescendingSort 
         * @param {string} [sortPropertyName] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCarriers: async (isActive: boolean, page: number, pageSize: number, isDescendingSort: boolean, sortPropertyName?: string, search?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'isActive' is not null or undefined
            assertParamExists('getCarriers', 'isActive', isActive)
            // verify required parameter 'page' is not null or undefined
            assertParamExists('getCarriers', 'page', page)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('getCarriers', 'pageSize', pageSize)
            // verify required parameter 'isDescendingSort' is not null or undefined
            assertParamExists('getCarriers', 'isDescendingSort', isDescendingSort)
            const localVarPath = `/carrier`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (isActive !== undefined) {
                localVarQueryParameter['IsActive'] = isActive;
            }

            if (page !== undefined) {
                localVarQueryParameter['Page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (sortPropertyName !== undefined) {
                localVarQueryParameter['SortPropertyName'] = sortPropertyName;
            }

            if (isDescendingSort !== undefined) {
                localVarQueryParameter['IsDescendingSort'] = isDescendingSort;
            }

            if (search !== undefined) {
                localVarQueryParameter['Search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Tento endpoint umožňuje aktualizovat stav aktivity dopravce, čímž lze deaktivovat nebo aktivovat účet dopravce.
         * @summary Aktualizace stavu aktivity dopravce
         * @param {number} id ID dopravce.
         * @param {PatchIsActiveRequest6} patchIsActiveRequest6 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchCarrierIsActive: async (id: number, patchIsActiveRequest6: PatchIsActiveRequest6, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('patchCarrierIsActive', 'id', id)
            // verify required parameter 'patchIsActiveRequest6' is not null or undefined
            assertParamExists('patchCarrierIsActive', 'patchIsActiveRequest6', patchIsActiveRequest6)
            const localVarPath = `/carrier/{id}/isactive`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchIsActiveRequest6, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Tento endpoint umožňuje vytvoření nového záznamu dopravce nebo aktualizaci stávajícího záznamu v systému. Operace vyžaduje oprávnění dispatcher, administrator nebo carrier (pouze pro vlastní záznamy).
         * @summary Vytvoření nebo aktualizace záznamu dopravce
         * @param {SaveCarrierRequest} saveCarrierRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveCarrier: async (saveCarrierRequest: SaveCarrierRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'saveCarrierRequest' is not null or undefined
            assertParamExists('saveCarrier', 'saveCarrierRequest', saveCarrierRequest)
            const localVarPath = `/carrier`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(saveCarrierRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CarrierApi - functional programming interface
 * @export
 */
export const CarrierApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CarrierApiAxiosParamCreator(configuration)
    return {
        /**
         * Tento endpoint umožňuje odstranit existujícího dopravce.
         * @summary Odstranění dopravce
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCarrier(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCarrier(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Tento endpoint umožòuje získat informace o konkrétním dopravci. Zahrnuje údaje o dopravci, jako jsou jméno, adresa a další.
         * @summary Vrátí informace o dopravci
         * @param {number} id ID dopravce
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCarrier(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CarrierModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCarrier(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Tento endpoint umožňuje získat seznam dopravců. Zahrnuje informace o jednotlivých dopravcích.
         * @summary Vrátí seznam dopravců
         * @param {boolean} isActive Stav aktivity, true pro aktivaci, false pro deaktivaci.
         * @param {number} page 
         * @param {number} pageSize 
         * @param {boolean} isDescendingSort 
         * @param {string} [sortPropertyName] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCarriers(isActive: boolean, page: number, pageSize: number, isDescendingSort: boolean, sortPropertyName?: string, search?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCarriersResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCarriers(isActive, page, pageSize, isDescendingSort, sortPropertyName, search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Tento endpoint umožňuje aktualizovat stav aktivity dopravce, čímž lze deaktivovat nebo aktivovat účet dopravce.
         * @summary Aktualizace stavu aktivity dopravce
         * @param {number} id ID dopravce.
         * @param {PatchIsActiveRequest6} patchIsActiveRequest6 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchCarrierIsActive(id: number, patchIsActiveRequest6: PatchIsActiveRequest6, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchCarrierIsActive(id, patchIsActiveRequest6, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Tento endpoint umožňuje vytvoření nového záznamu dopravce nebo aktualizaci stávajícího záznamu v systému. Operace vyžaduje oprávnění dispatcher, administrator nebo carrier (pouze pro vlastní záznamy).
         * @summary Vytvoření nebo aktualizace záznamu dopravce
         * @param {SaveCarrierRequest} saveCarrierRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveCarrier(saveCarrierRequest: SaveCarrierRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SaveCarrierResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveCarrier(saveCarrierRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CarrierApi - factory interface
 * @export
 */
export const CarrierApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CarrierApiFp(configuration)
    return {
        /**
         * Tento endpoint umožňuje odstranit existujícího dopravce.
         * @summary Odstranění dopravce
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCarrier(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteCarrier(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Tento endpoint umožòuje získat informace o konkrétním dopravci. Zahrnuje údaje o dopravci, jako jsou jméno, adresa a další.
         * @summary Vrátí informace o dopravci
         * @param {number} id ID dopravce
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCarrier(id: number, options?: any): AxiosPromise<CarrierModel> {
            return localVarFp.getCarrier(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Tento endpoint umožňuje získat seznam dopravců. Zahrnuje informace o jednotlivých dopravcích.
         * @summary Vrátí seznam dopravců
         * @param {boolean} isActive Stav aktivity, true pro aktivaci, false pro deaktivaci.
         * @param {number} page 
         * @param {number} pageSize 
         * @param {boolean} isDescendingSort 
         * @param {string} [sortPropertyName] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCarriers(isActive: boolean, page: number, pageSize: number, isDescendingSort: boolean, sortPropertyName?: string, search?: string, options?: any): AxiosPromise<GetCarriersResponse> {
            return localVarFp.getCarriers(isActive, page, pageSize, isDescendingSort, sortPropertyName, search, options).then((request) => request(axios, basePath));
        },
        /**
         * Tento endpoint umožňuje aktualizovat stav aktivity dopravce, čímž lze deaktivovat nebo aktivovat účet dopravce.
         * @summary Aktualizace stavu aktivity dopravce
         * @param {number} id ID dopravce.
         * @param {PatchIsActiveRequest6} patchIsActiveRequest6 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchCarrierIsActive(id: number, patchIsActiveRequest6: PatchIsActiveRequest6, options?: any): AxiosPromise<void> {
            return localVarFp.patchCarrierIsActive(id, patchIsActiveRequest6, options).then((request) => request(axios, basePath));
        },
        /**
         * Tento endpoint umožňuje vytvoření nového záznamu dopravce nebo aktualizaci stávajícího záznamu v systému. Operace vyžaduje oprávnění dispatcher, administrator nebo carrier (pouze pro vlastní záznamy).
         * @summary Vytvoření nebo aktualizace záznamu dopravce
         * @param {SaveCarrierRequest} saveCarrierRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveCarrier(saveCarrierRequest: SaveCarrierRequest, options?: any): AxiosPromise<SaveCarrierResponse> {
            return localVarFp.saveCarrier(saveCarrierRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CarrierApi - object-oriented interface
 * @export
 * @class CarrierApi
 * @extends {BaseAPI}
 */
export class CarrierApi extends BaseAPI {
    /**
     * Tento endpoint umožňuje odstranit existujícího dopravce.
     * @summary Odstranění dopravce
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CarrierApi
     */
    public deleteCarrier(id: number, options?: AxiosRequestConfig) {
        return CarrierApiFp(this.configuration).deleteCarrier(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Tento endpoint umožòuje získat informace o konkrétním dopravci. Zahrnuje údaje o dopravci, jako jsou jméno, adresa a další.
     * @summary Vrátí informace o dopravci
     * @param {number} id ID dopravce
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CarrierApi
     */
    public getCarrier(id: number, options?: AxiosRequestConfig) {
        return CarrierApiFp(this.configuration).getCarrier(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Tento endpoint umožňuje získat seznam dopravců. Zahrnuje informace o jednotlivých dopravcích.
     * @summary Vrátí seznam dopravců
     * @param {boolean} isActive Stav aktivity, true pro aktivaci, false pro deaktivaci.
     * @param {number} page 
     * @param {number} pageSize 
     * @param {boolean} isDescendingSort 
     * @param {string} [sortPropertyName] 
     * @param {string} [search] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CarrierApi
     */
    public getCarriers(isActive: boolean, page: number, pageSize: number, isDescendingSort: boolean, sortPropertyName?: string, search?: string, options?: AxiosRequestConfig) {
        return CarrierApiFp(this.configuration).getCarriers(isActive, page, pageSize, isDescendingSort, sortPropertyName, search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Tento endpoint umožňuje aktualizovat stav aktivity dopravce, čímž lze deaktivovat nebo aktivovat účet dopravce.
     * @summary Aktualizace stavu aktivity dopravce
     * @param {number} id ID dopravce.
     * @param {PatchIsActiveRequest6} patchIsActiveRequest6 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CarrierApi
     */
    public patchCarrierIsActive(id: number, patchIsActiveRequest6: PatchIsActiveRequest6, options?: AxiosRequestConfig) {
        return CarrierApiFp(this.configuration).patchCarrierIsActive(id, patchIsActiveRequest6, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Tento endpoint umožňuje vytvoření nového záznamu dopravce nebo aktualizaci stávajícího záznamu v systému. Operace vyžaduje oprávnění dispatcher, administrator nebo carrier (pouze pro vlastní záznamy).
     * @summary Vytvoření nebo aktualizace záznamu dopravce
     * @param {SaveCarrierRequest} saveCarrierRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CarrierApi
     */
    public saveCarrier(saveCarrierRequest: SaveCarrierRequest, options?: AxiosRequestConfig) {
        return CarrierApiFp(this.configuration).saveCarrier(saveCarrierRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CustomerApi - axios parameter creator
 * @export
 */
export const CustomerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Tento endpoint umožňuje odstranit zákazníka. Pro odstranění zákazníka musíte mít oprávnění administrátora.
         * @summary Odstranit zákazníka
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCustomer: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteCustomer', 'id', id)
            const localVarPath = `/customer/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Tento endpoint umožňuje získat informace o konkrétním zákazníkovi. Zahrnuje údaje o zákazníkovi, jako jsou jméno, adresa a další.
         * @summary Vrátí detail zákazníka
         * @param {number} id ID zákazníka
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomer: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getCustomer', 'id', id)
            const localVarPath = `/customer/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Tento endpoint umožňuje získat seznam zákazníků. Zahrnuje informace o jednotlivých zákaznících.
         * @summary Vrátí seznam zákazníků
         * @param {boolean} isActive Stav aktivity zákazníků.
         * @param {number} page 
         * @param {number} pageSize 
         * @param {boolean} isDescendingSort 
         * @param {string} [name] Jméno nebo název zákazníka.
         * @param {string} [phoneNumber] Telefonní číslo zákazníka.
         * @param {string} [email] E-mailová adresa zákazníka.
         * @param {string} [sortPropertyName] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomers: async (isActive: boolean, page: number, pageSize: number, isDescendingSort: boolean, name?: string, phoneNumber?: string, email?: string, sortPropertyName?: string, search?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'isActive' is not null or undefined
            assertParamExists('getCustomers', 'isActive', isActive)
            // verify required parameter 'page' is not null or undefined
            assertParamExists('getCustomers', 'page', page)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('getCustomers', 'pageSize', pageSize)
            // verify required parameter 'isDescendingSort' is not null or undefined
            assertParamExists('getCustomers', 'isDescendingSort', isDescendingSort)
            const localVarPath = `/customer`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (isActive !== undefined) {
                localVarQueryParameter['IsActive'] = isActive;
            }

            if (name !== undefined) {
                localVarQueryParameter['Name'] = name;
            }

            if (phoneNumber !== undefined) {
                localVarQueryParameter['PhoneNumber'] = phoneNumber;
            }

            if (email !== undefined) {
                localVarQueryParameter['Email'] = email;
            }

            if (page !== undefined) {
                localVarQueryParameter['Page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (sortPropertyName !== undefined) {
                localVarQueryParameter['SortPropertyName'] = sortPropertyName;
            }

            if (isDescendingSort !== undefined) {
                localVarQueryParameter['IsDescendingSort'] = isDescendingSort;
            }

            if (search !== undefined) {
                localVarQueryParameter['Search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Tento endpoint umožňuje aktualizaci aktivity zákazníka v systému.
         * @summary Aktualizace aktivity zákazníka
         * @param {number} id ID zakázníka.
         * @param {PatchCustomerIsActiveRequest} patchCustomerIsActiveRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchCustomerIsActive: async (id: number, patchCustomerIsActiveRequest: PatchCustomerIsActiveRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('patchCustomerIsActive', 'id', id)
            // verify required parameter 'patchCustomerIsActiveRequest' is not null or undefined
            assertParamExists('patchCustomerIsActive', 'patchCustomerIsActiveRequest', patchCustomerIsActiveRequest)
            const localVarPath = `/customer/{id}/isactive`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchCustomerIsActiveRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Tento endpoint umožňuje vytvoření nebo aktualizaci zákazníka v systému.
         * @summary Uložení zákazníka
         * @param {SaveCustomerRequest} saveCustomerRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveCustomer: async (saveCustomerRequest: SaveCustomerRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'saveCustomerRequest' is not null or undefined
            assertParamExists('saveCustomer', 'saveCustomerRequest', saveCustomerRequest)
            const localVarPath = `/customer`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(saveCustomerRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CustomerApi - functional programming interface
 * @export
 */
export const CustomerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CustomerApiAxiosParamCreator(configuration)
    return {
        /**
         * Tento endpoint umožňuje odstranit zákazníka. Pro odstranění zákazníka musíte mít oprávnění administrátora.
         * @summary Odstranit zákazníka
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCustomer(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCustomer(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Tento endpoint umožňuje získat informace o konkrétním zákazníkovi. Zahrnuje údaje o zákazníkovi, jako jsou jméno, adresa a další.
         * @summary Vrátí detail zákazníka
         * @param {number} id ID zákazníka
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCustomer(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCustomerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCustomer(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Tento endpoint umožňuje získat seznam zákazníků. Zahrnuje informace o jednotlivých zákaznících.
         * @summary Vrátí seznam zákazníků
         * @param {boolean} isActive Stav aktivity zákazníků.
         * @param {number} page 
         * @param {number} pageSize 
         * @param {boolean} isDescendingSort 
         * @param {string} [name] Jméno nebo název zákazníka.
         * @param {string} [phoneNumber] Telefonní číslo zákazníka.
         * @param {string} [email] E-mailová adresa zákazníka.
         * @param {string} [sortPropertyName] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCustomers(isActive: boolean, page: number, pageSize: number, isDescendingSort: boolean, name?: string, phoneNumber?: string, email?: string, sortPropertyName?: string, search?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCustomersResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCustomers(isActive, page, pageSize, isDescendingSort, name, phoneNumber, email, sortPropertyName, search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Tento endpoint umožňuje aktualizaci aktivity zákazníka v systému.
         * @summary Aktualizace aktivity zákazníka
         * @param {number} id ID zakázníka.
         * @param {PatchCustomerIsActiveRequest} patchCustomerIsActiveRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchCustomerIsActive(id: number, patchCustomerIsActiveRequest: PatchCustomerIsActiveRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchCustomerIsActive(id, patchCustomerIsActiveRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Tento endpoint umožňuje vytvoření nebo aktualizaci zákazníka v systému.
         * @summary Uložení zákazníka
         * @param {SaveCustomerRequest} saveCustomerRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveCustomer(saveCustomerRequest: SaveCustomerRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SaveCustomerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveCustomer(saveCustomerRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CustomerApi - factory interface
 * @export
 */
export const CustomerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CustomerApiFp(configuration)
    return {
        /**
         * Tento endpoint umožňuje odstranit zákazníka. Pro odstranění zákazníka musíte mít oprávnění administrátora.
         * @summary Odstranit zákazníka
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCustomer(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteCustomer(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Tento endpoint umožňuje získat informace o konkrétním zákazníkovi. Zahrnuje údaje o zákazníkovi, jako jsou jméno, adresa a další.
         * @summary Vrátí detail zákazníka
         * @param {number} id ID zákazníka
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomer(id: number, options?: any): AxiosPromise<GetCustomerResponse> {
            return localVarFp.getCustomer(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Tento endpoint umožňuje získat seznam zákazníků. Zahrnuje informace o jednotlivých zákaznících.
         * @summary Vrátí seznam zákazníků
         * @param {boolean} isActive Stav aktivity zákazníků.
         * @param {number} page 
         * @param {number} pageSize 
         * @param {boolean} isDescendingSort 
         * @param {string} [name] Jméno nebo název zákazníka.
         * @param {string} [phoneNumber] Telefonní číslo zákazníka.
         * @param {string} [email] E-mailová adresa zákazníka.
         * @param {string} [sortPropertyName] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomers(isActive: boolean, page: number, pageSize: number, isDescendingSort: boolean, name?: string, phoneNumber?: string, email?: string, sortPropertyName?: string, search?: string, options?: any): AxiosPromise<GetCustomersResponse> {
            return localVarFp.getCustomers(isActive, page, pageSize, isDescendingSort, name, phoneNumber, email, sortPropertyName, search, options).then((request) => request(axios, basePath));
        },
        /**
         * Tento endpoint umožňuje aktualizaci aktivity zákazníka v systému.
         * @summary Aktualizace aktivity zákazníka
         * @param {number} id ID zakázníka.
         * @param {PatchCustomerIsActiveRequest} patchCustomerIsActiveRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchCustomerIsActive(id: number, patchCustomerIsActiveRequest: PatchCustomerIsActiveRequest, options?: any): AxiosPromise<void> {
            return localVarFp.patchCustomerIsActive(id, patchCustomerIsActiveRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Tento endpoint umožňuje vytvoření nebo aktualizaci zákazníka v systému.
         * @summary Uložení zákazníka
         * @param {SaveCustomerRequest} saveCustomerRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveCustomer(saveCustomerRequest: SaveCustomerRequest, options?: any): AxiosPromise<SaveCustomerResponse> {
            return localVarFp.saveCustomer(saveCustomerRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CustomerApi - object-oriented interface
 * @export
 * @class CustomerApi
 * @extends {BaseAPI}
 */
export class CustomerApi extends BaseAPI {
    /**
     * Tento endpoint umožňuje odstranit zákazníka. Pro odstranění zákazníka musíte mít oprávnění administrátora.
     * @summary Odstranit zákazníka
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public deleteCustomer(id: number, options?: AxiosRequestConfig) {
        return CustomerApiFp(this.configuration).deleteCustomer(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Tento endpoint umožňuje získat informace o konkrétním zákazníkovi. Zahrnuje údaje o zákazníkovi, jako jsou jméno, adresa a další.
     * @summary Vrátí detail zákazníka
     * @param {number} id ID zákazníka
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public getCustomer(id: number, options?: AxiosRequestConfig) {
        return CustomerApiFp(this.configuration).getCustomer(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Tento endpoint umožňuje získat seznam zákazníků. Zahrnuje informace o jednotlivých zákaznících.
     * @summary Vrátí seznam zákazníků
     * @param {boolean} isActive Stav aktivity zákazníků.
     * @param {number} page 
     * @param {number} pageSize 
     * @param {boolean} isDescendingSort 
     * @param {string} [name] Jméno nebo název zákazníka.
     * @param {string} [phoneNumber] Telefonní číslo zákazníka.
     * @param {string} [email] E-mailová adresa zákazníka.
     * @param {string} [sortPropertyName] 
     * @param {string} [search] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public getCustomers(isActive: boolean, page: number, pageSize: number, isDescendingSort: boolean, name?: string, phoneNumber?: string, email?: string, sortPropertyName?: string, search?: string, options?: AxiosRequestConfig) {
        return CustomerApiFp(this.configuration).getCustomers(isActive, page, pageSize, isDescendingSort, name, phoneNumber, email, sortPropertyName, search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Tento endpoint umožňuje aktualizaci aktivity zákazníka v systému.
     * @summary Aktualizace aktivity zákazníka
     * @param {number} id ID zakázníka.
     * @param {PatchCustomerIsActiveRequest} patchCustomerIsActiveRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public patchCustomerIsActive(id: number, patchCustomerIsActiveRequest: PatchCustomerIsActiveRequest, options?: AxiosRequestConfig) {
        return CustomerApiFp(this.configuration).patchCustomerIsActive(id, patchCustomerIsActiveRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Tento endpoint umožňuje vytvoření nebo aktualizaci zákazníka v systému.
     * @summary Uložení zákazníka
     * @param {SaveCustomerRequest} saveCustomerRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public saveCustomer(saveCustomerRequest: SaveCustomerRequest, options?: AxiosRequestConfig) {
        return CustomerApiFp(this.configuration).saveCustomer(saveCustomerRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * EnumsApi - axios parameter creator
 * @export
 */
export const EnumsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Tento endpoint umožňuje odstranit existující typ vozidla. Pokud jsou na tento typ vozidla odkazy (např. v jízdách nebo slotových instancích), odstranění selže s chybou \'Failed\'.
         * @summary Odstranění typu vozidla
         * @param {number} id ID vozidla
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteVehicleType: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteVehicleType', 'id', id)
            const localVarPath = `/enums/vehicletype/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Tento endpoint umožňuje odstranit variantu vybavení pro konkrétní typ vozidla na základě zadaného ID varianty a typu.
         * @summary Odstraní vybavení pro daný typ vozidla
         * @param {number} typeid 
         * @param {number} variantid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteVehicleTypeEquipment: async (typeid: number, variantid: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'typeid' is not null or undefined
            assertParamExists('deleteVehicleTypeEquipment', 'typeid', typeid)
            // verify required parameter 'variantid' is not null or undefined
            assertParamExists('deleteVehicleTypeEquipment', 'variantid', variantid)
            const localVarPath = `/enums/vehicletype/{typeid}/{variantid}`
                .replace(`{${"typeid"}}`, encodeURIComponent(String(typeid)))
                .replace(`{${"variantid"}}`, encodeURIComponent(String(variantid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Tento endpoint vrátí seznam různých pomůcek pro hendikepované.
         * @summary Vrátí seznam pomůcek pro hendikepované
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHandicapEquipment: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/enums/handicapequipment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Tento endpoint vrátí informace o konkrétním typu vozidla na základě zadaného ID.
         * @summary Vrátí informace o typu vozidla
         * @param {number} id Id typu vozidla
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVehicleType: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getVehicleType', 'id', id)
            const localVarPath = `/enums/vehicletype/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Tento endpoint vrátí seznam různých typů vozidel dostupných v systému.
         * @summary Získá seznam typů vozidel
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVehicleTypes: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/enums/vehicletype`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Tento endpoint umožňuje vytvoření nebo aktualizaci typu vozidla v databázi.
         * @summary Uloží nebo aktualizuje typ vozidla
         * @param {SaveVehicleTypeRequest} saveVehicleTypeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveVehicleType: async (saveVehicleTypeRequest: SaveVehicleTypeRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'saveVehicleTypeRequest' is not null or undefined
            assertParamExists('saveVehicleType', 'saveVehicleTypeRequest', saveVehicleTypeRequest)
            const localVarPath = `/enums/vehicletype`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(saveVehicleTypeRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Tento endpoint umožňuje uložit nebo aktualizovat variantu vybavení pro konkrétní typ vozidla. ID varianty vybavení lze specifikovat (pro aktualizaci) nebo použít 0 pro vytvoření nové varianty vybavení.
         * @summary Uloží nebo aktualizuje variantu vybavení pro typ vozidla.
         * @param {number} typeid ID typu vozidla.
         * @param {number} variantid ID varianty vybavení.
         * @param {SaveVehicleTypeEquipmentRequest} saveVehicleTypeEquipmentRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveVehicleTypeEquipment: async (typeid: number, variantid: number, saveVehicleTypeEquipmentRequest: SaveVehicleTypeEquipmentRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'typeid' is not null or undefined
            assertParamExists('saveVehicleTypeEquipment', 'typeid', typeid)
            // verify required parameter 'variantid' is not null or undefined
            assertParamExists('saveVehicleTypeEquipment', 'variantid', variantid)
            // verify required parameter 'saveVehicleTypeEquipmentRequest' is not null or undefined
            assertParamExists('saveVehicleTypeEquipment', 'saveVehicleTypeEquipmentRequest', saveVehicleTypeEquipmentRequest)
            const localVarPath = `/enums/vehicletype/{typeid}/{variantid}`
                .replace(`{${"typeid"}}`, encodeURIComponent(String(typeid)))
                .replace(`{${"variantid"}}`, encodeURIComponent(String(variantid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(saveVehicleTypeEquipmentRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EnumsApi - functional programming interface
 * @export
 */
export const EnumsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EnumsApiAxiosParamCreator(configuration)
    return {
        /**
         * Tento endpoint umožňuje odstranit existující typ vozidla. Pokud jsou na tento typ vozidla odkazy (např. v jízdách nebo slotových instancích), odstranění selže s chybou \'Failed\'.
         * @summary Odstranění typu vozidla
         * @param {number} id ID vozidla
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteVehicleType(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteVehicleType(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Tento endpoint umožňuje odstranit variantu vybavení pro konkrétní typ vozidla na základě zadaného ID varianty a typu.
         * @summary Odstraní vybavení pro daný typ vozidla
         * @param {number} typeid 
         * @param {number} variantid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteVehicleTypeEquipment(typeid: number, variantid: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteVehicleTypeEquipmentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteVehicleTypeEquipment(typeid, variantid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Tento endpoint vrátí seznam různých pomůcek pro hendikepované.
         * @summary Vrátí seznam pomůcek pro hendikepované
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getHandicapEquipment(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetHandicapEquipmentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getHandicapEquipment(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Tento endpoint vrátí informace o konkrétním typu vozidla na základě zadaného ID.
         * @summary Vrátí informace o typu vozidla
         * @param {number} id Id typu vozidla
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getVehicleType(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VehicleTypeModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getVehicleType(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Tento endpoint vrátí seznam různých typů vozidel dostupných v systému.
         * @summary Získá seznam typů vozidel
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getVehicleTypes(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<VehicleTypeModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getVehicleTypes(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Tento endpoint umožňuje vytvoření nebo aktualizaci typu vozidla v databázi.
         * @summary Uloží nebo aktualizuje typ vozidla
         * @param {SaveVehicleTypeRequest} saveVehicleTypeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveVehicleType(saveVehicleTypeRequest: SaveVehicleTypeRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SaveVehicleTypeResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveVehicleType(saveVehicleTypeRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Tento endpoint umožňuje uložit nebo aktualizovat variantu vybavení pro konkrétní typ vozidla. ID varianty vybavení lze specifikovat (pro aktualizaci) nebo použít 0 pro vytvoření nové varianty vybavení.
         * @summary Uloží nebo aktualizuje variantu vybavení pro typ vozidla.
         * @param {number} typeid ID typu vozidla.
         * @param {number} variantid ID varianty vybavení.
         * @param {SaveVehicleTypeEquipmentRequest} saveVehicleTypeEquipmentRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveVehicleTypeEquipment(typeid: number, variantid: number, saveVehicleTypeEquipmentRequest: SaveVehicleTypeEquipmentRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SaveVehicleTypeEquipmentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveVehicleTypeEquipment(typeid, variantid, saveVehicleTypeEquipmentRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * EnumsApi - factory interface
 * @export
 */
export const EnumsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EnumsApiFp(configuration)
    return {
        /**
         * Tento endpoint umožňuje odstranit existující typ vozidla. Pokud jsou na tento typ vozidla odkazy (např. v jízdách nebo slotových instancích), odstranění selže s chybou \'Failed\'.
         * @summary Odstranění typu vozidla
         * @param {number} id ID vozidla
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteVehicleType(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteVehicleType(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Tento endpoint umožňuje odstranit variantu vybavení pro konkrétní typ vozidla na základě zadaného ID varianty a typu.
         * @summary Odstraní vybavení pro daný typ vozidla
         * @param {number} typeid 
         * @param {number} variantid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteVehicleTypeEquipment(typeid: number, variantid: number, options?: any): AxiosPromise<DeleteVehicleTypeEquipmentResponse> {
            return localVarFp.deleteVehicleTypeEquipment(typeid, variantid, options).then((request) => request(axios, basePath));
        },
        /**
         * Tento endpoint vrátí seznam různých pomůcek pro hendikepované.
         * @summary Vrátí seznam pomůcek pro hendikepované
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHandicapEquipment(options?: any): AxiosPromise<GetHandicapEquipmentResponse> {
            return localVarFp.getHandicapEquipment(options).then((request) => request(axios, basePath));
        },
        /**
         * Tento endpoint vrátí informace o konkrétním typu vozidla na základě zadaného ID.
         * @summary Vrátí informace o typu vozidla
         * @param {number} id Id typu vozidla
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVehicleType(id: number, options?: any): AxiosPromise<VehicleTypeModel> {
            return localVarFp.getVehicleType(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Tento endpoint vrátí seznam různých typů vozidel dostupných v systému.
         * @summary Získá seznam typů vozidel
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVehicleTypes(options?: any): AxiosPromise<Array<VehicleTypeModel>> {
            return localVarFp.getVehicleTypes(options).then((request) => request(axios, basePath));
        },
        /**
         * Tento endpoint umožňuje vytvoření nebo aktualizaci typu vozidla v databázi.
         * @summary Uloží nebo aktualizuje typ vozidla
         * @param {SaveVehicleTypeRequest} saveVehicleTypeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveVehicleType(saveVehicleTypeRequest: SaveVehicleTypeRequest, options?: any): AxiosPromise<SaveVehicleTypeResponse> {
            return localVarFp.saveVehicleType(saveVehicleTypeRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Tento endpoint umožňuje uložit nebo aktualizovat variantu vybavení pro konkrétní typ vozidla. ID varianty vybavení lze specifikovat (pro aktualizaci) nebo použít 0 pro vytvoření nové varianty vybavení.
         * @summary Uloží nebo aktualizuje variantu vybavení pro typ vozidla.
         * @param {number} typeid ID typu vozidla.
         * @param {number} variantid ID varianty vybavení.
         * @param {SaveVehicleTypeEquipmentRequest} saveVehicleTypeEquipmentRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveVehicleTypeEquipment(typeid: number, variantid: number, saveVehicleTypeEquipmentRequest: SaveVehicleTypeEquipmentRequest, options?: any): AxiosPromise<SaveVehicleTypeEquipmentResponse> {
            return localVarFp.saveVehicleTypeEquipment(typeid, variantid, saveVehicleTypeEquipmentRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EnumsApi - object-oriented interface
 * @export
 * @class EnumsApi
 * @extends {BaseAPI}
 */
export class EnumsApi extends BaseAPI {
    /**
     * Tento endpoint umožňuje odstranit existující typ vozidla. Pokud jsou na tento typ vozidla odkazy (např. v jízdách nebo slotových instancích), odstranění selže s chybou \'Failed\'.
     * @summary Odstranění typu vozidla
     * @param {number} id ID vozidla
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EnumsApi
     */
    public deleteVehicleType(id: number, options?: AxiosRequestConfig) {
        return EnumsApiFp(this.configuration).deleteVehicleType(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Tento endpoint umožňuje odstranit variantu vybavení pro konkrétní typ vozidla na základě zadaného ID varianty a typu.
     * @summary Odstraní vybavení pro daný typ vozidla
     * @param {number} typeid 
     * @param {number} variantid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EnumsApi
     */
    public deleteVehicleTypeEquipment(typeid: number, variantid: number, options?: AxiosRequestConfig) {
        return EnumsApiFp(this.configuration).deleteVehicleTypeEquipment(typeid, variantid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Tento endpoint vrátí seznam různých pomůcek pro hendikepované.
     * @summary Vrátí seznam pomůcek pro hendikepované
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EnumsApi
     */
    public getHandicapEquipment(options?: AxiosRequestConfig) {
        return EnumsApiFp(this.configuration).getHandicapEquipment(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Tento endpoint vrátí informace o konkrétním typu vozidla na základě zadaného ID.
     * @summary Vrátí informace o typu vozidla
     * @param {number} id Id typu vozidla
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EnumsApi
     */
    public getVehicleType(id: number, options?: AxiosRequestConfig) {
        return EnumsApiFp(this.configuration).getVehicleType(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Tento endpoint vrátí seznam různých typů vozidel dostupných v systému.
     * @summary Získá seznam typů vozidel
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EnumsApi
     */
    public getVehicleTypes(options?: AxiosRequestConfig) {
        return EnumsApiFp(this.configuration).getVehicleTypes(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Tento endpoint umožňuje vytvoření nebo aktualizaci typu vozidla v databázi.
     * @summary Uloží nebo aktualizuje typ vozidla
     * @param {SaveVehicleTypeRequest} saveVehicleTypeRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EnumsApi
     */
    public saveVehicleType(saveVehicleTypeRequest: SaveVehicleTypeRequest, options?: AxiosRequestConfig) {
        return EnumsApiFp(this.configuration).saveVehicleType(saveVehicleTypeRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Tento endpoint umožňuje uložit nebo aktualizovat variantu vybavení pro konkrétní typ vozidla. ID varianty vybavení lze specifikovat (pro aktualizaci) nebo použít 0 pro vytvoření nové varianty vybavení.
     * @summary Uloží nebo aktualizuje variantu vybavení pro typ vozidla.
     * @param {number} typeid ID typu vozidla.
     * @param {number} variantid ID varianty vybavení.
     * @param {SaveVehicleTypeEquipmentRequest} saveVehicleTypeEquipmentRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EnumsApi
     */
    public saveVehicleTypeEquipment(typeid: number, variantid: number, saveVehicleTypeEquipmentRequest: SaveVehicleTypeEquipmentRequest, options?: AxiosRequestConfig) {
        return EnumsApiFp(this.configuration).saveVehicleTypeEquipment(typeid, variantid, saveVehicleTypeEquipmentRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ExportApi - axios parameter creator
 * @export
 */
export const ExportApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Tento endpoint umožňuje exportovat informace o vozidlech do GPS Dozoru.
         * @summary Exportovat vozidla do GPS Dozoru
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportGpsDozorVehicles: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/export/gpsdozor`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ExportApi - functional programming interface
 * @export
 */
export const ExportApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ExportApiAxiosParamCreator(configuration)
    return {
        /**
         * Tento endpoint umožňuje exportovat informace o vozidlech do GPS Dozoru.
         * @summary Exportovat vozidla do GPS Dozoru
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async exportGpsDozorVehicles(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.exportGpsDozorVehicles(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ExportApi - factory interface
 * @export
 */
export const ExportApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ExportApiFp(configuration)
    return {
        /**
         * Tento endpoint umožňuje exportovat informace o vozidlech do GPS Dozoru.
         * @summary Exportovat vozidla do GPS Dozoru
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportGpsDozorVehicles(options?: any): AxiosPromise<string> {
            return localVarFp.exportGpsDozorVehicles(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ExportApi - object-oriented interface
 * @export
 * @class ExportApi
 * @extends {BaseAPI}
 */
export class ExportApi extends BaseAPI {
    /**
     * Tento endpoint umožňuje exportovat informace o vozidlech do GPS Dozoru.
     * @summary Exportovat vozidla do GPS Dozoru
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExportApi
     */
    public exportGpsDozorVehicles(options?: AxiosRequestConfig) {
        return ExportApiFp(this.configuration).exportGpsDozorVehicles(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FixdataApi - axios parameter creator
 * @export
 */
export const FixdataApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Tento endpoint slouží k odstranění všech importovaných poznámek.
         * @summary Smazání importovaných poznámek
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteImportedNotes: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/fixdata/notes/delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Tento endpoint umožňuje provést opravu částí města v adresách v databázi.
         * @summary Oprava částí města v adresách
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fixAddressesCityPart: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/fixdata/cityparts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Tento endpoint provádí opravu dat.
         * @summary Oprava dat
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fixData: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/fixdata`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Tento endpoint přidá poznámky k různým slotovým instancím v databázi. Každý případ, jako \'S3\', \'S4.1\', atd., má specifické časové údaje a texty poznámek.
         * @summary Přidání poznámek k slotovým instancím
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insertSlotInstanceNotes: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/fixdata/notes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FixdataApi - functional programming interface
 * @export
 */
export const FixdataApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FixdataApiAxiosParamCreator(configuration)
    return {
        /**
         * Tento endpoint slouží k odstranění všech importovaných poznámek.
         * @summary Smazání importovaných poznámek
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteImportedNotes(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteImportedNotes(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Tento endpoint umožňuje provést opravu částí města v adresách v databázi.
         * @summary Oprava částí města v adresách
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fixAddressesCityPart(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fixAddressesCityPart(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Tento endpoint provádí opravu dat.
         * @summary Oprava dat
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fixData(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fixData(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Tento endpoint přidá poznámky k různým slotovým instancím v databázi. Každý případ, jako \'S3\', \'S4.1\', atd., má specifické časové údaje a texty poznámek.
         * @summary Přidání poznámek k slotovým instancím
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async insertSlotInstanceNotes(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.insertSlotInstanceNotes(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FixdataApi - factory interface
 * @export
 */
export const FixdataApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FixdataApiFp(configuration)
    return {
        /**
         * Tento endpoint slouží k odstranění všech importovaných poznámek.
         * @summary Smazání importovaných poznámek
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteImportedNotes(options?: any): AxiosPromise<void> {
            return localVarFp.deleteImportedNotes(options).then((request) => request(axios, basePath));
        },
        /**
         * Tento endpoint umožňuje provést opravu částí města v adresách v databázi.
         * @summary Oprava částí města v adresách
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fixAddressesCityPart(options?: any): AxiosPromise<void> {
            return localVarFp.fixAddressesCityPart(options).then((request) => request(axios, basePath));
        },
        /**
         * Tento endpoint provádí opravu dat.
         * @summary Oprava dat
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fixData(options?: any): AxiosPromise<void> {
            return localVarFp.fixData(options).then((request) => request(axios, basePath));
        },
        /**
         * Tento endpoint přidá poznámky k různým slotovým instancím v databázi. Každý případ, jako \'S3\', \'S4.1\', atd., má specifické časové údaje a texty poznámek.
         * @summary Přidání poznámek k slotovým instancím
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insertSlotInstanceNotes(options?: any): AxiosPromise<void> {
            return localVarFp.insertSlotInstanceNotes(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FixdataApi - object-oriented interface
 * @export
 * @class FixdataApi
 * @extends {BaseAPI}
 */
export class FixdataApi extends BaseAPI {
    /**
     * Tento endpoint slouží k odstranění všech importovaných poznámek.
     * @summary Smazání importovaných poznámek
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FixdataApi
     */
    public deleteImportedNotes(options?: AxiosRequestConfig) {
        return FixdataApiFp(this.configuration).deleteImportedNotes(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Tento endpoint umožňuje provést opravu částí města v adresách v databázi.
     * @summary Oprava částí města v adresách
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FixdataApi
     */
    public fixAddressesCityPart(options?: AxiosRequestConfig) {
        return FixdataApiFp(this.configuration).fixAddressesCityPart(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Tento endpoint provádí opravu dat.
     * @summary Oprava dat
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FixdataApi
     */
    public fixData(options?: AxiosRequestConfig) {
        return FixdataApiFp(this.configuration).fixData(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Tento endpoint přidá poznámky k různým slotovým instancím v databázi. Každý případ, jako \'S3\', \'S4.1\', atd., má specifické časové údaje a texty poznámek.
     * @summary Přidání poznámek k slotovým instancím
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FixdataApi
     */
    public insertSlotInstanceNotes(options?: AxiosRequestConfig) {
        return FixdataApiFp(this.configuration).insertSlotInstanceNotes(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ImageApi - axios parameter creator
 * @export
 */
export const ImageApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Tento endpoint umožňuje získání obrázku na základě zadaného ID.
         * @summary Vrátí obrázek
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getImage: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getImage', 'id', id)
            const localVarPath = `/image/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Tento endpoint umožňuje nahrát obrázek do aplikace.
         * @summary Nahrát obrázek
         * @param {any} image Nahrávaný obrázek
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadImage: async (image: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'image' is not null or undefined
            assertParamExists('uploadImage', 'image', image)
            const localVarPath = `/image`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication JWTBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (image !== undefined) { 
                localVarFormParams.append('image', image as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ImageApi - functional programming interface
 * @export
 */
export const ImageApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ImageApiAxiosParamCreator(configuration)
    return {
        /**
         * Tento endpoint umožňuje získání obrázku na základě zadaného ID.
         * @summary Vrátí obrázek
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getImage(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getImage(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Tento endpoint umožňuje nahrát obrázek do aplikace.
         * @summary Nahrát obrázek
         * @param {any} image Nahrávaný obrázek
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadImage(image: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UploadImageResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadImage(image, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ImageApi - factory interface
 * @export
 */
export const ImageApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ImageApiFp(configuration)
    return {
        /**
         * Tento endpoint umožňuje získání obrázku na základě zadaného ID.
         * @summary Vrátí obrázek
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getImage(id: number, options?: any): AxiosPromise<string> {
            return localVarFp.getImage(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Tento endpoint umožňuje nahrát obrázek do aplikace.
         * @summary Nahrát obrázek
         * @param {any} image Nahrávaný obrázek
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadImage(image: any, options?: any): AxiosPromise<UploadImageResponse> {
            return localVarFp.uploadImage(image, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ImageApi - object-oriented interface
 * @export
 * @class ImageApi
 * @extends {BaseAPI}
 */
export class ImageApi extends BaseAPI {
    /**
     * Tento endpoint umožňuje získání obrázku na základě zadaného ID.
     * @summary Vrátí obrázek
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImageApi
     */
    public getImage(id: number, options?: AxiosRequestConfig) {
        return ImageApiFp(this.configuration).getImage(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Tento endpoint umožňuje nahrát obrázek do aplikace.
     * @summary Nahrát obrázek
     * @param {any} image Nahrávaný obrázek
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImageApi
     */
    public uploadImage(image: any, options?: AxiosRequestConfig) {
        return ImageApiFp(this.configuration).uploadImage(image, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MapApi - axios parameter creator
 * @export
 */
export const MapApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Tento endpoint umožňuje získat informace o vozidlech pro mapovou službu, včetně dat o GPSDozor.
         * @summary Získání informací o vozidlech pro mapovou službu
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMapVehicles: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/map/vehicle`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MapApi - functional programming interface
 * @export
 */
export const MapApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MapApiAxiosParamCreator(configuration)
    return {
        /**
         * Tento endpoint umožňuje získat informace o vozidlech pro mapovou službu, včetně dat o GPSDozor.
         * @summary Získání informací o vozidlech pro mapovou službu
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMapVehicles(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetMapVehiclesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMapVehicles(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MapApi - factory interface
 * @export
 */
export const MapApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MapApiFp(configuration)
    return {
        /**
         * Tento endpoint umožňuje získat informace o vozidlech pro mapovou službu, včetně dat o GPSDozor.
         * @summary Získání informací o vozidlech pro mapovou službu
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMapVehicles(options?: any): AxiosPromise<GetMapVehiclesResponse> {
            return localVarFp.getMapVehicles(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MapApi - object-oriented interface
 * @export
 * @class MapApi
 * @extends {BaseAPI}
 */
export class MapApi extends BaseAPI {
    /**
     * Tento endpoint umožňuje získat informace o vozidlech pro mapovou službu, včetně dat o GPSDozor.
     * @summary Získání informací o vozidlech pro mapovou službu
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MapApi
     */
    public getMapVehicles(options?: AxiosRequestConfig) {
        return MapApiFp(this.configuration).getMapVehicles(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OperatingdayApi - axios parameter creator
 * @export
 */
export const OperatingdayApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Tento endpoint umožňuje klonovat existující provozní den a vytvořit nový s jiným názvem.
         * @summary Klonování provozního dne
         * @param {number} id ID provozního dne, který chcete klonovat.
         * @param {CloneOperatingDayRequest} cloneOperatingDayRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cloneOperatingDay: async (id: number, cloneOperatingDayRequest: CloneOperatingDayRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('cloneOperatingDay', 'id', id)
            // verify required parameter 'cloneOperatingDayRequest' is not null or undefined
            assertParamExists('cloneOperatingDay', 'cloneOperatingDayRequest', cloneOperatingDayRequest)
            const localVarPath = `/operatingday/{id}/clone`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(cloneOperatingDayRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Tento endpoint umožňuje odstranit provozní den.
         * @summary Smazání provozního dne
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOperatingDay: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteOperatingDay', 'id', id)
            const localVarPath = `/operatingday/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Tento endpoint umožňuje získat kalendář provozních dnů mezi zadanými daty.
         * @summary Získání kalendáře provozních dnů
         * @param {string} from Od
         * @param {string} to Do
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCalendar: async (from: string, to: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'from' is not null or undefined
            assertParamExists('getCalendar', 'from', from)
            // verify required parameter 'to' is not null or undefined
            assertParamExists('getCalendar', 'to', to)
            const localVarPath = `/operatingday/calendar/{from}/{to}`
                .replace(`{${"from"}}`, encodeURIComponent(String(from)))
                .replace(`{${"to"}}`, encodeURIComponent(String(to)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Tento endpoint umožňuje získat provozní den na základě zadaného identifikátoru.
         * @summary Získání provozního dne
         * @param {number} id Id provozního dne
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOperatingDay: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getOperatingDay', 'id', id)
            const localVarPath = `/operatingday/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Tento endpoint umožňuje získat provozní kalendář pro určité datum.
         * @summary Získání provozního kalendáře
         * @param {string} date Datum provozního dne
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOperatingDayCalendar: async (date: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'date' is not null or undefined
            assertParamExists('getOperatingDayCalendar', 'date', date)
            const localVarPath = `/operatingday/calendar`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (date !== undefined) {
                localVarQueryParameter['Date'] = (date as any instanceof Date) ?
                    (date as any).toISOString().substr(0,10) :
                    date;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Tento endpoint umožňuje získat seznam provozních dní z databáze.
         * @summary Získání provozních dní
         * @param {boolean} excludeInActive Flag pro vyloučení neaktivních provozních dnů.
         * @param {number} page 
         * @param {number} pageSize 
         * @param {boolean} isDescendingSort 
         * @param {string} [sortPropertyName] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOperatingDays: async (excludeInActive: boolean, page: number, pageSize: number, isDescendingSort: boolean, sortPropertyName?: string, search?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'excludeInActive' is not null or undefined
            assertParamExists('getOperatingDays', 'excludeInActive', excludeInActive)
            // verify required parameter 'page' is not null or undefined
            assertParamExists('getOperatingDays', 'page', page)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('getOperatingDays', 'pageSize', pageSize)
            // verify required parameter 'isDescendingSort' is not null or undefined
            assertParamExists('getOperatingDays', 'isDescendingSort', isDescendingSort)
            const localVarPath = `/operatingday`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (excludeInActive !== undefined) {
                localVarQueryParameter['ExcludeInActive'] = excludeInActive;
            }

            if (page !== undefined) {
                localVarQueryParameter['Page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (sortPropertyName !== undefined) {
                localVarQueryParameter['SortPropertyName'] = sortPropertyName;
            }

            if (isDescendingSort !== undefined) {
                localVarQueryParameter['IsDescendingSort'] = isDescendingSort;
            }

            if (search !== undefined) {
                localVarQueryParameter['Search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Tento endpoint slouží k deaktivaci provozního dne v systému. Deaktivace nemusí být povolena pro provozní dny, které jsou použity v budoucnu v kalendáři.
         * @summary Deaktivace provozního dne
         * @param {number} id ID provozního dne
         * @param {PatchIsActiveRequest5} patchIsActiveRequest5 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchOperatingDayIsActive: async (id: number, patchIsActiveRequest5: PatchIsActiveRequest5, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('patchOperatingDayIsActive', 'id', id)
            // verify required parameter 'patchIsActiveRequest5' is not null or undefined
            assertParamExists('patchOperatingDayIsActive', 'patchIsActiveRequest5', patchIsActiveRequest5)
            const localVarPath = `/operatingday/{id}/isactive`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchIsActiveRequest5, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Tento endpoint umožňuje uložit kalendář provozních dnů.
         * @summary Uložení kalendáře provozních dnů
         * @param {SaveCalendarRequest} saveCalendarRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveCalendar: async (saveCalendarRequest: SaveCalendarRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'saveCalendarRequest' is not null or undefined
            assertParamExists('saveCalendar', 'saveCalendarRequest', saveCalendarRequest)
            const localVarPath = `/operatingday/calendar`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(saveCalendarRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Tento endpoint umožňuje uložit nebo aktualizovat provozní den.
         * @summary Uložení provozního dne
         * @param {SaveOperatingDayRequest} saveOperatingDayRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveOperatingDay: async (saveOperatingDayRequest: SaveOperatingDayRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'saveOperatingDayRequest' is not null or undefined
            assertParamExists('saveOperatingDay', 'saveOperatingDayRequest', saveOperatingDayRequest)
            const localVarPath = `/operatingday`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(saveOperatingDayRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OperatingdayApi - functional programming interface
 * @export
 */
export const OperatingdayApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OperatingdayApiAxiosParamCreator(configuration)
    return {
        /**
         * Tento endpoint umožňuje klonovat existující provozní den a vytvořit nový s jiným názvem.
         * @summary Klonování provozního dne
         * @param {number} id ID provozního dne, který chcete klonovat.
         * @param {CloneOperatingDayRequest} cloneOperatingDayRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cloneOperatingDay(id: number, cloneOperatingDayRequest: CloneOperatingDayRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CloneOperatingDayResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cloneOperatingDay(id, cloneOperatingDayRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Tento endpoint umožňuje odstranit provozní den.
         * @summary Smazání provozního dne
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteOperatingDay(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteOperatingDay(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Tento endpoint umožňuje získat kalendář provozních dnů mezi zadanými daty.
         * @summary Získání kalendáře provozních dnů
         * @param {string} from Od
         * @param {string} to Do
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCalendar(from: string, to: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCalendar(from, to, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Tento endpoint umožňuje získat provozní den na základě zadaného identifikátoru.
         * @summary Získání provozního dne
         * @param {number} id Id provozního dne
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOperatingDay(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OperatingDayModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOperatingDay(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Tento endpoint umožňuje získat provozní kalendář pro určité datum.
         * @summary Získání provozního kalendáře
         * @param {string} date Datum provozního dne
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOperatingDayCalendar(date: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetOperatingDayCalendarResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOperatingDayCalendar(date, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Tento endpoint umožňuje získat seznam provozních dní z databáze.
         * @summary Získání provozních dní
         * @param {boolean} excludeInActive Flag pro vyloučení neaktivních provozních dnů.
         * @param {number} page 
         * @param {number} pageSize 
         * @param {boolean} isDescendingSort 
         * @param {string} [sortPropertyName] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOperatingDays(excludeInActive: boolean, page: number, pageSize: number, isDescendingSort: boolean, sortPropertyName?: string, search?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OfFAnonymousType2OfListOfOperatingDayModelAndInt32>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOperatingDays(excludeInActive, page, pageSize, isDescendingSort, sortPropertyName, search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Tento endpoint slouží k deaktivaci provozního dne v systému. Deaktivace nemusí být povolena pro provozní dny, které jsou použity v budoucnu v kalendáři.
         * @summary Deaktivace provozního dne
         * @param {number} id ID provozního dne
         * @param {PatchIsActiveRequest5} patchIsActiveRequest5 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchOperatingDayIsActive(id: number, patchIsActiveRequest5: PatchIsActiveRequest5, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchOperatingDayIsActive(id, patchIsActiveRequest5, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Tento endpoint umožňuje uložit kalendář provozních dnů.
         * @summary Uložení kalendáře provozních dnů
         * @param {SaveCalendarRequest} saveCalendarRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveCalendar(saveCalendarRequest: SaveCalendarRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SaveCalendarResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveCalendar(saveCalendarRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Tento endpoint umožňuje uložit nebo aktualizovat provozní den.
         * @summary Uložení provozního dne
         * @param {SaveOperatingDayRequest} saveOperatingDayRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveOperatingDay(saveOperatingDayRequest: SaveOperatingDayRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SaveOperatingDayResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveOperatingDay(saveOperatingDayRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OperatingdayApi - factory interface
 * @export
 */
export const OperatingdayApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OperatingdayApiFp(configuration)
    return {
        /**
         * Tento endpoint umožňuje klonovat existující provozní den a vytvořit nový s jiným názvem.
         * @summary Klonování provozního dne
         * @param {number} id ID provozního dne, který chcete klonovat.
         * @param {CloneOperatingDayRequest} cloneOperatingDayRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cloneOperatingDay(id: number, cloneOperatingDayRequest: CloneOperatingDayRequest, options?: any): AxiosPromise<CloneOperatingDayResponse> {
            return localVarFp.cloneOperatingDay(id, cloneOperatingDayRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Tento endpoint umožňuje odstranit provozní den.
         * @summary Smazání provozního dne
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOperatingDay(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteOperatingDay(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Tento endpoint umožňuje získat kalendář provozních dnů mezi zadanými daty.
         * @summary Získání kalendáře provozních dnů
         * @param {string} from Od
         * @param {string} to Do
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCalendar(from: string, to: string, options?: any): AxiosPromise<Array<number>> {
            return localVarFp.getCalendar(from, to, options).then((request) => request(axios, basePath));
        },
        /**
         * Tento endpoint umožňuje získat provozní den na základě zadaného identifikátoru.
         * @summary Získání provozního dne
         * @param {number} id Id provozního dne
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOperatingDay(id: number, options?: any): AxiosPromise<OperatingDayModel> {
            return localVarFp.getOperatingDay(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Tento endpoint umožňuje získat provozní kalendář pro určité datum.
         * @summary Získání provozního kalendáře
         * @param {string} date Datum provozního dne
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOperatingDayCalendar(date: string, options?: any): AxiosPromise<GetOperatingDayCalendarResponse> {
            return localVarFp.getOperatingDayCalendar(date, options).then((request) => request(axios, basePath));
        },
        /**
         * Tento endpoint umožňuje získat seznam provozních dní z databáze.
         * @summary Získání provozních dní
         * @param {boolean} excludeInActive Flag pro vyloučení neaktivních provozních dnů.
         * @param {number} page 
         * @param {number} pageSize 
         * @param {boolean} isDescendingSort 
         * @param {string} [sortPropertyName] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOperatingDays(excludeInActive: boolean, page: number, pageSize: number, isDescendingSort: boolean, sortPropertyName?: string, search?: string, options?: any): AxiosPromise<OfFAnonymousType2OfListOfOperatingDayModelAndInt32> {
            return localVarFp.getOperatingDays(excludeInActive, page, pageSize, isDescendingSort, sortPropertyName, search, options).then((request) => request(axios, basePath));
        },
        /**
         * Tento endpoint slouží k deaktivaci provozního dne v systému. Deaktivace nemusí být povolena pro provozní dny, které jsou použity v budoucnu v kalendáři.
         * @summary Deaktivace provozního dne
         * @param {number} id ID provozního dne
         * @param {PatchIsActiveRequest5} patchIsActiveRequest5 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchOperatingDayIsActive(id: number, patchIsActiveRequest5: PatchIsActiveRequest5, options?: any): AxiosPromise<void> {
            return localVarFp.patchOperatingDayIsActive(id, patchIsActiveRequest5, options).then((request) => request(axios, basePath));
        },
        /**
         * Tento endpoint umožňuje uložit kalendář provozních dnů.
         * @summary Uložení kalendáře provozních dnů
         * @param {SaveCalendarRequest} saveCalendarRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveCalendar(saveCalendarRequest: SaveCalendarRequest, options?: any): AxiosPromise<SaveCalendarResponse> {
            return localVarFp.saveCalendar(saveCalendarRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Tento endpoint umožňuje uložit nebo aktualizovat provozní den.
         * @summary Uložení provozního dne
         * @param {SaveOperatingDayRequest} saveOperatingDayRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveOperatingDay(saveOperatingDayRequest: SaveOperatingDayRequest, options?: any): AxiosPromise<SaveOperatingDayResponse> {
            return localVarFp.saveOperatingDay(saveOperatingDayRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OperatingdayApi - object-oriented interface
 * @export
 * @class OperatingdayApi
 * @extends {BaseAPI}
 */
export class OperatingdayApi extends BaseAPI {
    /**
     * Tento endpoint umožňuje klonovat existující provozní den a vytvořit nový s jiným názvem.
     * @summary Klonování provozního dne
     * @param {number} id ID provozního dne, který chcete klonovat.
     * @param {CloneOperatingDayRequest} cloneOperatingDayRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperatingdayApi
     */
    public cloneOperatingDay(id: number, cloneOperatingDayRequest: CloneOperatingDayRequest, options?: AxiosRequestConfig) {
        return OperatingdayApiFp(this.configuration).cloneOperatingDay(id, cloneOperatingDayRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Tento endpoint umožňuje odstranit provozní den.
     * @summary Smazání provozního dne
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperatingdayApi
     */
    public deleteOperatingDay(id: number, options?: AxiosRequestConfig) {
        return OperatingdayApiFp(this.configuration).deleteOperatingDay(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Tento endpoint umožňuje získat kalendář provozních dnů mezi zadanými daty.
     * @summary Získání kalendáře provozních dnů
     * @param {string} from Od
     * @param {string} to Do
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperatingdayApi
     */
    public getCalendar(from: string, to: string, options?: AxiosRequestConfig) {
        return OperatingdayApiFp(this.configuration).getCalendar(from, to, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Tento endpoint umožňuje získat provozní den na základě zadaného identifikátoru.
     * @summary Získání provozního dne
     * @param {number} id Id provozního dne
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperatingdayApi
     */
    public getOperatingDay(id: number, options?: AxiosRequestConfig) {
        return OperatingdayApiFp(this.configuration).getOperatingDay(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Tento endpoint umožňuje získat provozní kalendář pro určité datum.
     * @summary Získání provozního kalendáře
     * @param {string} date Datum provozního dne
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperatingdayApi
     */
    public getOperatingDayCalendar(date: string, options?: AxiosRequestConfig) {
        return OperatingdayApiFp(this.configuration).getOperatingDayCalendar(date, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Tento endpoint umožňuje získat seznam provozních dní z databáze.
     * @summary Získání provozních dní
     * @param {boolean} excludeInActive Flag pro vyloučení neaktivních provozních dnů.
     * @param {number} page 
     * @param {number} pageSize 
     * @param {boolean} isDescendingSort 
     * @param {string} [sortPropertyName] 
     * @param {string} [search] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperatingdayApi
     */
    public getOperatingDays(excludeInActive: boolean, page: number, pageSize: number, isDescendingSort: boolean, sortPropertyName?: string, search?: string, options?: AxiosRequestConfig) {
        return OperatingdayApiFp(this.configuration).getOperatingDays(excludeInActive, page, pageSize, isDescendingSort, sortPropertyName, search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Tento endpoint slouží k deaktivaci provozního dne v systému. Deaktivace nemusí být povolena pro provozní dny, které jsou použity v budoucnu v kalendáři.
     * @summary Deaktivace provozního dne
     * @param {number} id ID provozního dne
     * @param {PatchIsActiveRequest5} patchIsActiveRequest5 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperatingdayApi
     */
    public patchOperatingDayIsActive(id: number, patchIsActiveRequest5: PatchIsActiveRequest5, options?: AxiosRequestConfig) {
        return OperatingdayApiFp(this.configuration).patchOperatingDayIsActive(id, patchIsActiveRequest5, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Tento endpoint umožňuje uložit kalendář provozních dnů.
     * @summary Uložení kalendáře provozních dnů
     * @param {SaveCalendarRequest} saveCalendarRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperatingdayApi
     */
    public saveCalendar(saveCalendarRequest: SaveCalendarRequest, options?: AxiosRequestConfig) {
        return OperatingdayApiFp(this.configuration).saveCalendar(saveCalendarRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Tento endpoint umožňuje uložit nebo aktualizovat provozní den.
     * @summary Uložení provozního dne
     * @param {SaveOperatingDayRequest} saveOperatingDayRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperatingdayApi
     */
    public saveOperatingDay(saveOperatingDayRequest: SaveOperatingDayRequest, options?: AxiosRequestConfig) {
        return OperatingdayApiFp(this.configuration).saveOperatingDay(saveOperatingDayRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OrderApi - axios parameter creator
 * @export
 */
export const OrderApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Tento endpoint umožňuje zrušit objednávku.
         * @summary Zrušení objednávky
         * @param {CancelOrderRequest} cancelOrderRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelOrder: async (cancelOrderRequest: CancelOrderRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'cancelOrderRequest' is not null or undefined
            assertParamExists('cancelOrder', 'cancelOrderRequest', cancelOrderRequest)
            const localVarPath = `/order/cancel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(cancelOrderRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Tento endpoint umožňuje klonovat existující objednávku.
         * @summary Klonování objednávky
         * @param {number} id ID objednávky
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cloneOrder: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('cloneOrder', 'id', id)
            const localVarPath = `/order/{id}/clone`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Tento endpoint umožňuje získat detaily objednávky.
         * @summary Získání objednávky
         * @param {number} id ID objednávky
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrder: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getOrder', 'id', id)
            const localVarPath = `/order/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Tento endpoint umožňuje získat seznam objednávek.
         * @summary Získání seznamu objednávek
         * @param {number} page 
         * @param {number} pageSize 
         * @param {boolean} isDescendingSort 
         * @param {number} [customerId] ID zákazníka
         * @param {string} [addressFrom] Adresa - odkud
         * @param {string} [addressTo] Adresa - kam
         * @param {string} [date] Datum
         * @param {Array<number>} [orderTypes] Seznam typů objednávek
         * @param {Array<number>} [states] Seznam stavů
         * @param {string} [sortPropertyName] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrders: async (page: number, pageSize: number, isDescendingSort: boolean, customerId?: number, addressFrom?: string, addressTo?: string, date?: string, orderTypes?: Array<number>, states?: Array<number>, sortPropertyName?: string, search?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'page' is not null or undefined
            assertParamExists('getOrders', 'page', page)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('getOrders', 'pageSize', pageSize)
            // verify required parameter 'isDescendingSort' is not null or undefined
            assertParamExists('getOrders', 'isDescendingSort', isDescendingSort)
            const localVarPath = `/order`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (customerId !== undefined) {
                localVarQueryParameter['CustomerId'] = customerId;
            }

            if (addressFrom !== undefined) {
                localVarQueryParameter['AddressFrom'] = addressFrom;
            }

            if (addressTo !== undefined) {
                localVarQueryParameter['AddressTo'] = addressTo;
            }

            if (date !== undefined) {
                localVarQueryParameter['Date'] = (date as any instanceof Date) ?
                    (date as any).toISOString().substr(0,10) :
                    date;
            }

            if (orderTypes) {
                localVarQueryParameter['OrderTypes'] = orderTypes;
            }

            if (states) {
                localVarQueryParameter['States'] = states;
            }

            if (page !== undefined) {
                localVarQueryParameter['Page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (sortPropertyName !== undefined) {
                localVarQueryParameter['SortPropertyName'] = sortPropertyName;
            }

            if (isDescendingSort !== undefined) {
                localVarQueryParameter['IsDescendingSort'] = isDescendingSort;
            }

            if (search !== undefined) {
                localVarQueryParameter['Search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Používá se pro úpravu objednávek otevřených k plánování
         * @summary Aktualizuje pole objednávky, která nemají vliv na instance objednávky
         * @param {PatchOrderNoninstantiablesRequest} patchOrderNoninstantiablesRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchOrderNoninstantiables: async (patchOrderNoninstantiablesRequest: PatchOrderNoninstantiablesRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patchOrderNoninstantiablesRequest' is not null or undefined
            assertParamExists('patchOrderNoninstantiables', 'patchOrderNoninstantiablesRequest', patchOrderNoninstantiablesRequest)
            const localVarPath = `/order/noninstantiables`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchOrderNoninstantiablesRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Tento endpoint umožňuje administrátorovi nebo dispečerovi uložit objednávku.
         * @summary Uložit objednávku
         * @param {SaveOrderRequest} saveOrderRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveOrder: async (saveOrderRequest: SaveOrderRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'saveOrderRequest' is not null or undefined
            assertParamExists('saveOrder', 'saveOrderRequest', saveOrderRequest)
            const localVarPath = `/order`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(saveOrderRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrderApi - functional programming interface
 * @export
 */
export const OrderApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OrderApiAxiosParamCreator(configuration)
    return {
        /**
         * Tento endpoint umožňuje zrušit objednávku.
         * @summary Zrušení objednávky
         * @param {CancelOrderRequest} cancelOrderRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cancelOrder(cancelOrderRequest: CancelOrderRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CancelOrderResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cancelOrder(cancelOrderRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Tento endpoint umožňuje klonovat existující objednávku.
         * @summary Klonování objednávky
         * @param {number} id ID objednávky
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cloneOrder(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CloneOrderResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cloneOrder(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Tento endpoint umožňuje získat detaily objednávky.
         * @summary Získání objednávky
         * @param {number} id ID objednávky
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrder(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetOrderResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOrder(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Tento endpoint umožňuje získat seznam objednávek.
         * @summary Získání seznamu objednávek
         * @param {number} page 
         * @param {number} pageSize 
         * @param {boolean} isDescendingSort 
         * @param {number} [customerId] ID zákazníka
         * @param {string} [addressFrom] Adresa - odkud
         * @param {string} [addressTo] Adresa - kam
         * @param {string} [date] Datum
         * @param {Array<number>} [orderTypes] Seznam typů objednávek
         * @param {Array<number>} [states] Seznam stavů
         * @param {string} [sortPropertyName] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrders(page: number, pageSize: number, isDescendingSort: boolean, customerId?: number, addressFrom?: string, addressTo?: string, date?: string, orderTypes?: Array<number>, states?: Array<number>, sortPropertyName?: string, search?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetOrdersResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOrders(page, pageSize, isDescendingSort, customerId, addressFrom, addressTo, date, orderTypes, states, sortPropertyName, search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Používá se pro úpravu objednávek otevřených k plánování
         * @summary Aktualizuje pole objednávky, která nemají vliv na instance objednávky
         * @param {PatchOrderNoninstantiablesRequest} patchOrderNoninstantiablesRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchOrderNoninstantiables(patchOrderNoninstantiablesRequest: PatchOrderNoninstantiablesRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchOrderNoninstantiables(patchOrderNoninstantiablesRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Tento endpoint umožňuje administrátorovi nebo dispečerovi uložit objednávku.
         * @summary Uložit objednávku
         * @param {SaveOrderRequest} saveOrderRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveOrder(saveOrderRequest: SaveOrderRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SaveOrderResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveOrder(saveOrderRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OrderApi - factory interface
 * @export
 */
export const OrderApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OrderApiFp(configuration)
    return {
        /**
         * Tento endpoint umožňuje zrušit objednávku.
         * @summary Zrušení objednávky
         * @param {CancelOrderRequest} cancelOrderRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelOrder(cancelOrderRequest: CancelOrderRequest, options?: any): AxiosPromise<CancelOrderResponse> {
            return localVarFp.cancelOrder(cancelOrderRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Tento endpoint umožňuje klonovat existující objednávku.
         * @summary Klonování objednávky
         * @param {number} id ID objednávky
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cloneOrder(id: number, options?: any): AxiosPromise<CloneOrderResponse> {
            return localVarFp.cloneOrder(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Tento endpoint umožňuje získat detaily objednávky.
         * @summary Získání objednávky
         * @param {number} id ID objednávky
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrder(id: number, options?: any): AxiosPromise<GetOrderResponse> {
            return localVarFp.getOrder(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Tento endpoint umožňuje získat seznam objednávek.
         * @summary Získání seznamu objednávek
         * @param {number} page 
         * @param {number} pageSize 
         * @param {boolean} isDescendingSort 
         * @param {number} [customerId] ID zákazníka
         * @param {string} [addressFrom] Adresa - odkud
         * @param {string} [addressTo] Adresa - kam
         * @param {string} [date] Datum
         * @param {Array<number>} [orderTypes] Seznam typů objednávek
         * @param {Array<number>} [states] Seznam stavů
         * @param {string} [sortPropertyName] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrders(page: number, pageSize: number, isDescendingSort: boolean, customerId?: number, addressFrom?: string, addressTo?: string, date?: string, orderTypes?: Array<number>, states?: Array<number>, sortPropertyName?: string, search?: string, options?: any): AxiosPromise<GetOrdersResponse> {
            return localVarFp.getOrders(page, pageSize, isDescendingSort, customerId, addressFrom, addressTo, date, orderTypes, states, sortPropertyName, search, options).then((request) => request(axios, basePath));
        },
        /**
         * Používá se pro úpravu objednávek otevřených k plánování
         * @summary Aktualizuje pole objednávky, která nemají vliv na instance objednávky
         * @param {PatchOrderNoninstantiablesRequest} patchOrderNoninstantiablesRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchOrderNoninstantiables(patchOrderNoninstantiablesRequest: PatchOrderNoninstantiablesRequest, options?: any): AxiosPromise<void> {
            return localVarFp.patchOrderNoninstantiables(patchOrderNoninstantiablesRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Tento endpoint umožňuje administrátorovi nebo dispečerovi uložit objednávku.
         * @summary Uložit objednávku
         * @param {SaveOrderRequest} saveOrderRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveOrder(saveOrderRequest: SaveOrderRequest, options?: any): AxiosPromise<SaveOrderResponse> {
            return localVarFp.saveOrder(saveOrderRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OrderApi - object-oriented interface
 * @export
 * @class OrderApi
 * @extends {BaseAPI}
 */
export class OrderApi extends BaseAPI {
    /**
     * Tento endpoint umožňuje zrušit objednávku.
     * @summary Zrušení objednávky
     * @param {CancelOrderRequest} cancelOrderRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public cancelOrder(cancelOrderRequest: CancelOrderRequest, options?: AxiosRequestConfig) {
        return OrderApiFp(this.configuration).cancelOrder(cancelOrderRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Tento endpoint umožňuje klonovat existující objednávku.
     * @summary Klonování objednávky
     * @param {number} id ID objednávky
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public cloneOrder(id: number, options?: AxiosRequestConfig) {
        return OrderApiFp(this.configuration).cloneOrder(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Tento endpoint umožňuje získat detaily objednávky.
     * @summary Získání objednávky
     * @param {number} id ID objednávky
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public getOrder(id: number, options?: AxiosRequestConfig) {
        return OrderApiFp(this.configuration).getOrder(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Tento endpoint umožňuje získat seznam objednávek.
     * @summary Získání seznamu objednávek
     * @param {number} page 
     * @param {number} pageSize 
     * @param {boolean} isDescendingSort 
     * @param {number} [customerId] ID zákazníka
     * @param {string} [addressFrom] Adresa - odkud
     * @param {string} [addressTo] Adresa - kam
     * @param {string} [date] Datum
     * @param {Array<number>} [orderTypes] Seznam typů objednávek
     * @param {Array<number>} [states] Seznam stavů
     * @param {string} [sortPropertyName] 
     * @param {string} [search] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public getOrders(page: number, pageSize: number, isDescendingSort: boolean, customerId?: number, addressFrom?: string, addressTo?: string, date?: string, orderTypes?: Array<number>, states?: Array<number>, sortPropertyName?: string, search?: string, options?: AxiosRequestConfig) {
        return OrderApiFp(this.configuration).getOrders(page, pageSize, isDescendingSort, customerId, addressFrom, addressTo, date, orderTypes, states, sortPropertyName, search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Používá se pro úpravu objednávek otevřených k plánování
     * @summary Aktualizuje pole objednávky, která nemají vliv na instance objednávky
     * @param {PatchOrderNoninstantiablesRequest} patchOrderNoninstantiablesRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public patchOrderNoninstantiables(patchOrderNoninstantiablesRequest: PatchOrderNoninstantiablesRequest, options?: AxiosRequestConfig) {
        return OrderApiFp(this.configuration).patchOrderNoninstantiables(patchOrderNoninstantiablesRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Tento endpoint umožňuje administrátorovi nebo dispečerovi uložit objednávku.
     * @summary Uložit objednávku
     * @param {SaveOrderRequest} saveOrderRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public saveOrder(saveOrderRequest: SaveOrderRequest, options?: AxiosRequestConfig) {
        return OrderApiFp(this.configuration).saveOrder(saveOrderRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OrderinstanceApi - axios parameter creator
 * @export
 */
export const OrderinstanceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Tento endpoint umožňuje získat instanci objednávky na základě zadaného identifikátoru.
         * @summary Získání instance objednávky
         * @param {number} id Id instance objednávky
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrderInstance: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getOrderInstance', 'id', id)
            const localVarPath = `/orderinstance/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Získání seznamu instancí objednávek
         * @param {number} page 
         * @param {number} pageSize 
         * @param {boolean} isDescendingSort 
         * @param {number} [customerId] ID zákazníka
         * @param {number} [orderId] ID objednávky
         * @param {string} [from] Datum od
         * @param {string} [to] Datum do
         * @param {string} [sortPropertyName] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrderInstances: async (page: number, pageSize: number, isDescendingSort: boolean, customerId?: number, orderId?: number, from?: string, to?: string, sortPropertyName?: string, search?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'page' is not null or undefined
            assertParamExists('getOrderInstances', 'page', page)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('getOrderInstances', 'pageSize', pageSize)
            // verify required parameter 'isDescendingSort' is not null or undefined
            assertParamExists('getOrderInstances', 'isDescendingSort', isDescendingSort)
            const localVarPath = `/orderinstance`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (customerId !== undefined) {
                localVarQueryParameter['CustomerId'] = customerId;
            }

            if (orderId !== undefined) {
                localVarQueryParameter['OrderId'] = orderId;
            }

            if (from !== undefined) {
                localVarQueryParameter['From'] = (from as any instanceof Date) ?
                    (from as any).toISOString() :
                    from;
            }

            if (to !== undefined) {
                localVarQueryParameter['To'] = (to as any instanceof Date) ?
                    (to as any).toISOString() :
                    to;
            }

            if (page !== undefined) {
                localVarQueryParameter['Page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (sortPropertyName !== undefined) {
                localVarQueryParameter['SortPropertyName'] = sortPropertyName;
            }

            if (isDescendingSort !== undefined) {
                localVarQueryParameter['IsDescendingSort'] = isDescendingSort;
            }

            if (search !== undefined) {
                localVarQueryParameter['Search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Aktualizace extra doprovodů instance objednávky
         * @param {number} id Id instance objednávky
         * @param {PatchExtraEscortsRequest} patchExtraEscortsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchOrderInstanceExtraEscorts: async (id: number, patchExtraEscortsRequest: PatchExtraEscortsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('patchOrderInstanceExtraEscorts', 'id', id)
            // verify required parameter 'patchExtraEscortsRequest' is not null or undefined
            assertParamExists('patchOrderInstanceExtraEscorts', 'patchExtraEscortsRequest', patchExtraEscortsRequest)
            const localVarPath = `/orderinstance/{id}/extra-escort`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchExtraEscortsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Tento endpoint umožňuje aktualizaci stavu instance objednávky na základě zadaného požadavku.
         * @summary Aktualizace stavu instance objednávky
         * @param {number} id Id instance objednávky
         * @param {PatchOrderInstanceStateRequest} patchOrderInstanceStateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchOrderInstanceState: async (id: number, patchOrderInstanceStateRequest: PatchOrderInstanceStateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('patchOrderInstanceState', 'id', id)
            // verify required parameter 'patchOrderInstanceStateRequest' is not null or undefined
            assertParamExists('patchOrderInstanceState', 'patchOrderInstanceStateRequest', patchOrderInstanceStateRequest)
            const localVarPath = `/orderinstance/{id}/state`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchOrderInstanceStateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Tento endpoint umožňuje uložit změny v instanci objednávky.
         * @summary Uložení instance objednávky
         * @param {SaveOrderInstanceRequest} saveOrderInstanceRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveOrderInstance: async (saveOrderInstanceRequest: SaveOrderInstanceRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'saveOrderInstanceRequest' is not null or undefined
            assertParamExists('saveOrderInstance', 'saveOrderInstanceRequest', saveOrderInstanceRequest)
            const localVarPath = `/orderinstance`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(saveOrderInstanceRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrderinstanceApi - functional programming interface
 * @export
 */
export const OrderinstanceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OrderinstanceApiAxiosParamCreator(configuration)
    return {
        /**
         * Tento endpoint umožňuje získat instanci objednávky na základě zadaného identifikátoru.
         * @summary Získání instance objednávky
         * @param {number} id Id instance objednávky
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrderInstance(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetOrderInstanceModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOrderInstance(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Získání seznamu instancí objednávek
         * @param {number} page 
         * @param {number} pageSize 
         * @param {boolean} isDescendingSort 
         * @param {number} [customerId] ID zákazníka
         * @param {number} [orderId] ID objednávky
         * @param {string} [from] Datum od
         * @param {string} [to] Datum do
         * @param {string} [sortPropertyName] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrderInstances(page: number, pageSize: number, isDescendingSort: boolean, customerId?: number, orderId?: number, from?: string, to?: string, sortPropertyName?: string, search?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GetOrderInstanceModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOrderInstances(page, pageSize, isDescendingSort, customerId, orderId, from, to, sortPropertyName, search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Aktualizace extra doprovodů instance objednávky
         * @param {number} id Id instance objednávky
         * @param {PatchExtraEscortsRequest} patchExtraEscortsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchOrderInstanceExtraEscorts(id: number, patchExtraEscortsRequest: PatchExtraEscortsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchOrderInstanceExtraEscorts(id, patchExtraEscortsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Tento endpoint umožňuje aktualizaci stavu instance objednávky na základě zadaného požadavku.
         * @summary Aktualizace stavu instance objednávky
         * @param {number} id Id instance objednávky
         * @param {PatchOrderInstanceStateRequest} patchOrderInstanceStateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchOrderInstanceState(id: number, patchOrderInstanceStateRequest: PatchOrderInstanceStateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchOrderInstanceState(id, patchOrderInstanceStateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Tento endpoint umožňuje uložit změny v instanci objednávky.
         * @summary Uložení instance objednávky
         * @param {SaveOrderInstanceRequest} saveOrderInstanceRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveOrderInstance(saveOrderInstanceRequest: SaveOrderInstanceRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SaveOrderInstanceResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveOrderInstance(saveOrderInstanceRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OrderinstanceApi - factory interface
 * @export
 */
export const OrderinstanceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OrderinstanceApiFp(configuration)
    return {
        /**
         * Tento endpoint umožňuje získat instanci objednávky na základě zadaného identifikátoru.
         * @summary Získání instance objednávky
         * @param {number} id Id instance objednávky
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrderInstance(id: number, options?: any): AxiosPromise<GetOrderInstanceModel> {
            return localVarFp.getOrderInstance(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Získání seznamu instancí objednávek
         * @param {number} page 
         * @param {number} pageSize 
         * @param {boolean} isDescendingSort 
         * @param {number} [customerId] ID zákazníka
         * @param {number} [orderId] ID objednávky
         * @param {string} [from] Datum od
         * @param {string} [to] Datum do
         * @param {string} [sortPropertyName] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrderInstances(page: number, pageSize: number, isDescendingSort: boolean, customerId?: number, orderId?: number, from?: string, to?: string, sortPropertyName?: string, search?: string, options?: any): AxiosPromise<Array<GetOrderInstanceModel>> {
            return localVarFp.getOrderInstances(page, pageSize, isDescendingSort, customerId, orderId, from, to, sortPropertyName, search, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Aktualizace extra doprovodů instance objednávky
         * @param {number} id Id instance objednávky
         * @param {PatchExtraEscortsRequest} patchExtraEscortsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchOrderInstanceExtraEscorts(id: number, patchExtraEscortsRequest: PatchExtraEscortsRequest, options?: any): AxiosPromise<void> {
            return localVarFp.patchOrderInstanceExtraEscorts(id, patchExtraEscortsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Tento endpoint umožňuje aktualizaci stavu instance objednávky na základě zadaného požadavku.
         * @summary Aktualizace stavu instance objednávky
         * @param {number} id Id instance objednávky
         * @param {PatchOrderInstanceStateRequest} patchOrderInstanceStateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchOrderInstanceState(id: number, patchOrderInstanceStateRequest: PatchOrderInstanceStateRequest, options?: any): AxiosPromise<void> {
            return localVarFp.patchOrderInstanceState(id, patchOrderInstanceStateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Tento endpoint umožňuje uložit změny v instanci objednávky.
         * @summary Uložení instance objednávky
         * @param {SaveOrderInstanceRequest} saveOrderInstanceRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveOrderInstance(saveOrderInstanceRequest: SaveOrderInstanceRequest, options?: any): AxiosPromise<SaveOrderInstanceResponse> {
            return localVarFp.saveOrderInstance(saveOrderInstanceRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OrderinstanceApi - object-oriented interface
 * @export
 * @class OrderinstanceApi
 * @extends {BaseAPI}
 */
export class OrderinstanceApi extends BaseAPI {
    /**
     * Tento endpoint umožňuje získat instanci objednávky na základě zadaného identifikátoru.
     * @summary Získání instance objednávky
     * @param {number} id Id instance objednávky
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderinstanceApi
     */
    public getOrderInstance(id: number, options?: AxiosRequestConfig) {
        return OrderinstanceApiFp(this.configuration).getOrderInstance(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Získání seznamu instancí objednávek
     * @param {number} page 
     * @param {number} pageSize 
     * @param {boolean} isDescendingSort 
     * @param {number} [customerId] ID zákazníka
     * @param {number} [orderId] ID objednávky
     * @param {string} [from] Datum od
     * @param {string} [to] Datum do
     * @param {string} [sortPropertyName] 
     * @param {string} [search] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderinstanceApi
     */
    public getOrderInstances(page: number, pageSize: number, isDescendingSort: boolean, customerId?: number, orderId?: number, from?: string, to?: string, sortPropertyName?: string, search?: string, options?: AxiosRequestConfig) {
        return OrderinstanceApiFp(this.configuration).getOrderInstances(page, pageSize, isDescendingSort, customerId, orderId, from, to, sortPropertyName, search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Aktualizace extra doprovodů instance objednávky
     * @param {number} id Id instance objednávky
     * @param {PatchExtraEscortsRequest} patchExtraEscortsRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderinstanceApi
     */
    public patchOrderInstanceExtraEscorts(id: number, patchExtraEscortsRequest: PatchExtraEscortsRequest, options?: AxiosRequestConfig) {
        return OrderinstanceApiFp(this.configuration).patchOrderInstanceExtraEscorts(id, patchExtraEscortsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Tento endpoint umožňuje aktualizaci stavu instance objednávky na základě zadaného požadavku.
     * @summary Aktualizace stavu instance objednávky
     * @param {number} id Id instance objednávky
     * @param {PatchOrderInstanceStateRequest} patchOrderInstanceStateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderinstanceApi
     */
    public patchOrderInstanceState(id: number, patchOrderInstanceStateRequest: PatchOrderInstanceStateRequest, options?: AxiosRequestConfig) {
        return OrderinstanceApiFp(this.configuration).patchOrderInstanceState(id, patchOrderInstanceStateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Tento endpoint umožňuje uložit změny v instanci objednávky.
     * @summary Uložení instance objednávky
     * @param {SaveOrderInstanceRequest} saveOrderInstanceRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderinstanceApi
     */
    public saveOrderInstance(saveOrderInstanceRequest: SaveOrderInstanceRequest, options?: AxiosRequestConfig) {
        return OrderinstanceApiFp(this.configuration).saveOrderInstance(saveOrderInstanceRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * RideApi - axios parameter creator
 * @export
 */
export const RideApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Tento endpoint umožňuje získat dostupné typy sloučení pro vybrané jízdy.
         * @summary Vrátí typy sloučení jízd
         * @param {Array<number>} rideIds Id jízd
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJoinTypes: async (rideIds: Array<number>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'rideIds' is not null or undefined
            assertParamExists('getJoinTypes', 'rideIds', rideIds)
            const localVarPath = `/ride/jointypes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (rideIds) {
                localVarQueryParameter['RideIds'] = rideIds;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Tento endpoint umožňuje získat detaily o zadané jízdě.
         * @summary Vrátí detail jízdy
         * @param {number} id Id jízdy
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRide: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getRide', 'id', id)
            const localVarPath = `/ride/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Informace o zákaznících dané jízdy
         * @param {number} id ID jízdy
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRideCustomersInformation: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getRideCustomersInformation', 'id', id)
            const localVarPath = `/ride/{id}/customers`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Tento endpoint umožňuje informaci o počtu jízd podle stavů v zadaném časovém období.
         * @summary Získat statistiky jízd
         * @param {string} start Datum začátku rozsahu
         * @param {string} end Datum konce rozsahu
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRideStatistics: async (start: string, end: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'start' is not null or undefined
            assertParamExists('getRideStatistics', 'start', start)
            // verify required parameter 'end' is not null or undefined
            assertParamExists('getRideStatistics', 'end', end)
            const localVarPath = `/ride/statistics`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (start !== undefined) {
                localVarQueryParameter['Start'] = (start as any instanceof Date) ?
                    (start as any).toISOString() :
                    start;
            }

            if (end !== undefined) {
                localVarQueryParameter['End'] = (end as any instanceof Date) ?
                    (end as any).toISOString() :
                    end;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Tento endpoint umožňuje získat stránkovaný seznam s detaily jízd s filtrací podle vozidle, řidiče, času, slotu, adresy.
         * @summary Vrátí seznam jízd
         * @param {number} page 
         * @param {number} pageSize 
         * @param {boolean} isDescendingSort 
         * @param {number} [customerId] Id zákazníka
         * @param {string} [address] Adresa
         * @param {string} [date] Datum
         * @param {string} [start] Začátek
         * @param {string} [end] Konec
         * @param {number} [driverId] Id řidiče
         * @param {number} [assistantId] Id asistenta
         * @param {number} [vehicleId] Id vozidla
         * @param {number} [slotId] Id slotu
         * @param {Array<number>} [states] Stavy
         * @param {string} [sortPropertyName] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRides: async (page: number, pageSize: number, isDescendingSort: boolean, customerId?: number, address?: string, date?: string, start?: string, end?: string, driverId?: number, assistantId?: number, vehicleId?: number, slotId?: number, states?: Array<number>, sortPropertyName?: string, search?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'page' is not null or undefined
            assertParamExists('getRides', 'page', page)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('getRides', 'pageSize', pageSize)
            // verify required parameter 'isDescendingSort' is not null or undefined
            assertParamExists('getRides', 'isDescendingSort', isDescendingSort)
            const localVarPath = `/ride`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (customerId !== undefined) {
                localVarQueryParameter['CustomerId'] = customerId;
            }

            if (address !== undefined) {
                localVarQueryParameter['Address'] = address;
            }

            if (date !== undefined) {
                localVarQueryParameter['Date'] = (date as any instanceof Date) ?
                    (date as any).toISOString().substr(0,10) :
                    date;
            }

            if (start !== undefined) {
                localVarQueryParameter['Start'] = (start as any instanceof Date) ?
                    (start as any).toISOString() :
                    start;
            }

            if (end !== undefined) {
                localVarQueryParameter['End'] = (end as any instanceof Date) ?
                    (end as any).toISOString() :
                    end;
            }

            if (driverId !== undefined) {
                localVarQueryParameter['DriverId'] = driverId;
            }

            if (assistantId !== undefined) {
                localVarQueryParameter['AssistantId'] = assistantId;
            }

            if (vehicleId !== undefined) {
                localVarQueryParameter['VehicleId'] = vehicleId;
            }

            if (slotId !== undefined) {
                localVarQueryParameter['SlotId'] = slotId;
            }

            if (states) {
                localVarQueryParameter['States'] = states;
            }

            if (page !== undefined) {
                localVarQueryParameter['Page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (sortPropertyName !== undefined) {
                localVarQueryParameter['SortPropertyName'] = sortPropertyName;
            }

            if (isDescendingSort !== undefined) {
                localVarQueryParameter['IsDescendingSort'] = isDescendingSort;
            }

            if (search !== undefined) {
                localVarQueryParameter['Search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Tento endpoint umožňuje získat seznam jízd ve vybraném časovém období pro potřeby plánování.  Narozdíl od entpoint GET/ride získává méně informací z databáze. Jízdy je možné filtrovat podle stavů a podstavů
         * @summary Vrátí seznam jízd pro plánování
         * @param {string} start Datum a čas začátku plánovaného rozsahu jízd.
         * @param {string} end Datum a čas konce plánovaného rozsahu jízd.
         * @param {Array<number>} [states] Seznam stavů jízd, které mají být zahrnuty v plánovaném rozsahu.
         * @param {Array<number>} [cancellationTypes] Seznam typů zrušení jízd, které mají být zahrnuty v plánovaném rozsahu.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRidesPlanning: async (start: string, end: string, states?: Array<number>, cancellationTypes?: Array<number>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'start' is not null or undefined
            assertParamExists('getRidesPlanning', 'start', start)
            // verify required parameter 'end' is not null or undefined
            assertParamExists('getRidesPlanning', 'end', end)
            const localVarPath = `/ride/planning`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (start !== undefined) {
                localVarQueryParameter['Start'] = (start as any instanceof Date) ?
                    (start as any).toISOString() :
                    start;
            }

            if (end !== undefined) {
                localVarQueryParameter['End'] = (end as any instanceof Date) ?
                    (end as any).toISOString() :
                    end;
            }

            if (states) {
                localVarQueryParameter['States'] = states;
            }

            if (cancellationTypes) {
                localVarQueryParameter['CancellationTypes'] = cancellationTypes;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Tento endpoint umožňuje získat typy rozdělení pro danou jízdu nebo instanci objednávky.
         * @summary Získat typy rozdělení jízdy
         * @param {number} id Id
         * @param {number} instanceId Id instance
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUnjoinTypes: async (id: number, instanceId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getUnjoinTypes', 'id', id)
            // verify required parameter 'instanceId' is not null or undefined
            assertParamExists('getUnjoinTypes', 'instanceId', instanceId)
            const localVarPath = `/ride/{id}/unjointypes`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (instanceId !== undefined) {
                localVarQueryParameter['InstanceId'] = instanceId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Tento endpoint umožňuje sloučit vybrané jízdy.  Očekává seznam naložení a vyložení ve zvoleném pořadí, typ sloučení a trvání jízdy po sloučení.
         * @summary Sloučit jízdy
         * @param {JoinRidesRequest} joinRidesRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        joinRides: async (joinRidesRequest: JoinRidesRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'joinRidesRequest' is not null or undefined
            assertParamExists('joinRides', 'joinRidesRequest', joinRidesRequest)
            const localVarPath = `/ride/join`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(joinRidesRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Tento endpoint umožňuje změnit délku jízdy na základě zadaných parametrů.
         * @summary Změna délky jízdy
         * @param {number} id ID jízdy
         * @param {PatchRideDurationRequest} patchRideDurationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchRideDuration: async (id: number, patchRideDurationRequest: PatchRideDurationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('patchRideDuration', 'id', id)
            // verify required parameter 'patchRideDurationRequest' is not null or undefined
            assertParamExists('patchRideDuration', 'patchRideDurationRequest', patchRideDurationRequest)
            const localVarPath = `/ride/{id}/duration`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchRideDurationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Tento endpoint umožňuje změnit délku jízdy na základě zadaných parametrů.
         * @summary Změna délky jízdy
         * @param {number} id ID jízdy
         * @param {PatchRideSlotInstanceRequest} patchRideSlotInstanceRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchRideSlotInstance: async (id: number, patchRideSlotInstanceRequest: PatchRideSlotInstanceRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('patchRideSlotInstance', 'id', id)
            // verify required parameter 'patchRideSlotInstanceRequest' is not null or undefined
            assertParamExists('patchRideSlotInstance', 'patchRideSlotInstanceRequest', patchRideSlotInstanceRequest)
            const localVarPath = `/ride/{id}/slotinstance`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchRideSlotInstanceRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Tento endpoint umožňuje změnit pořadí zastávek v rámci jízdy na základě zadaných parametrů.
         * @summary Změna pořadí zastávek jízdy
         * @param {number} id ID jízdy
         * @param {PatchRideStopSequenceRequest} patchRideStopSequenceRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchRideStopSequence: async (id: number, patchRideStopSequenceRequest: PatchRideStopSequenceRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('patchRideStopSequence', 'id', id)
            // verify required parameter 'patchRideStopSequenceRequest' is not null or undefined
            assertParamExists('patchRideStopSequence', 'patchRideStopSequenceRequest', patchRideStopSequenceRequest)
            const localVarPath = `/ride/{id}/stopsequence`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchRideStopSequenceRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Tento endpoint umožňuje opakované zařazení jízdy do slotu na základě zadaných parametrů.
         * @summary Opakované zařazení jízdy do slotu
         * @param {number} id ID jízdy
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pigeonholeRide: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('pigeonholeRide', 'id', id)
            const localVarPath = `/ride/{id}/pigeonhole`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Tento endpoint umožňuje rozdělit zadanou jízdu na základě zvolených parametrů.
         * @summary Rozdělení jízdy
         * @param {number} id ID jízdy
         * @param {UnjoinRideRequest} unjoinRideRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unjoinRide: async (id: number, unjoinRideRequest: UnjoinRideRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('unjoinRide', 'id', id)
            // verify required parameter 'unjoinRideRequest' is not null or undefined
            assertParamExists('unjoinRide', 'unjoinRideRequest', unjoinRideRequest)
            const localVarPath = `/ride/{id}/unjoin`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(unjoinRideRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RideApi - functional programming interface
 * @export
 */
export const RideApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RideApiAxiosParamCreator(configuration)
    return {
        /**
         * Tento endpoint umožňuje získat dostupné typy sloučení pro vybrané jízdy.
         * @summary Vrátí typy sloučení jízd
         * @param {Array<number>} rideIds Id jízd
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getJoinTypes(rideIds: Array<number>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<JoinType>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getJoinTypes(rideIds, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Tento endpoint umožňuje získat detaily o zadané jízdě.
         * @summary Vrátí detail jízdy
         * @param {number} id Id jízdy
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRide(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetRideModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRide(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Informace o zákaznících dané jízdy
         * @param {number} id ID jízdy
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRideCustomersInformation(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetRideCustomersInformationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRideCustomersInformation(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Tento endpoint umožňuje informaci o počtu jízd podle stavů v zadaném časovém období.
         * @summary Získat statistiky jízd
         * @param {string} start Datum začátku rozsahu
         * @param {string} end Datum konce rozsahu
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRideStatistics(start: string, end: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RideStatisticsCountModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRideStatistics(start, end, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Tento endpoint umožňuje získat stránkovaný seznam s detaily jízd s filtrací podle vozidle, řidiče, času, slotu, adresy.
         * @summary Vrátí seznam jízd
         * @param {number} page 
         * @param {number} pageSize 
         * @param {boolean} isDescendingSort 
         * @param {number} [customerId] Id zákazníka
         * @param {string} [address] Adresa
         * @param {string} [date] Datum
         * @param {string} [start] Začátek
         * @param {string} [end] Konec
         * @param {number} [driverId] Id řidiče
         * @param {number} [assistantId] Id asistenta
         * @param {number} [vehicleId] Id vozidla
         * @param {number} [slotId] Id slotu
         * @param {Array<number>} [states] Stavy
         * @param {string} [sortPropertyName] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRides(page: number, pageSize: number, isDescendingSort: boolean, customerId?: number, address?: string, date?: string, start?: string, end?: string, driverId?: number, assistantId?: number, vehicleId?: number, slotId?: number, states?: Array<number>, sortPropertyName?: string, search?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetRidesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRides(page, pageSize, isDescendingSort, customerId, address, date, start, end, driverId, assistantId, vehicleId, slotId, states, sortPropertyName, search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Tento endpoint umožňuje získat seznam jízd ve vybraném časovém období pro potřeby plánování.  Narozdíl od entpoint GET/ride získává méně informací z databáze. Jízdy je možné filtrovat podle stavů a podstavů
         * @summary Vrátí seznam jízd pro plánování
         * @param {string} start Datum a čas začátku plánovaného rozsahu jízd.
         * @param {string} end Datum a čas konce plánovaného rozsahu jízd.
         * @param {Array<number>} [states] Seznam stavů jízd, které mají být zahrnuty v plánovaném rozsahu.
         * @param {Array<number>} [cancellationTypes] Seznam typů zrušení jízd, které mají být zahrnuty v plánovaném rozsahu.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRidesPlanning(start: string, end: string, states?: Array<number>, cancellationTypes?: Array<number>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetRidesPlanningResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRidesPlanning(start, end, states, cancellationTypes, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Tento endpoint umožňuje získat typy rozdělení pro danou jízdu nebo instanci objednávky.
         * @summary Získat typy rozdělení jízdy
         * @param {number} id Id
         * @param {number} instanceId Id instance
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUnjoinTypes(id: number, instanceId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UnjoinType>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUnjoinTypes(id, instanceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Tento endpoint umožňuje sloučit vybrané jízdy.  Očekává seznam naložení a vyložení ve zvoleném pořadí, typ sloučení a trvání jízdy po sloučení.
         * @summary Sloučit jízdy
         * @param {JoinRidesRequest} joinRidesRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async joinRides(joinRidesRequest: JoinRidesRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<JoinRidesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.joinRides(joinRidesRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Tento endpoint umožňuje změnit délku jízdy na základě zadaných parametrů.
         * @summary Změna délky jízdy
         * @param {number} id ID jízdy
         * @param {PatchRideDurationRequest} patchRideDurationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchRideDuration(id: number, patchRideDurationRequest: PatchRideDurationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<JoinRidesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchRideDuration(id, patchRideDurationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Tento endpoint umožňuje změnit délku jízdy na základě zadaných parametrů.
         * @summary Změna délky jízdy
         * @param {number} id ID jízdy
         * @param {PatchRideSlotInstanceRequest} patchRideSlotInstanceRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchRideSlotInstance(id: number, patchRideSlotInstanceRequest: PatchRideSlotInstanceRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchRideSlotInstance(id, patchRideSlotInstanceRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Tento endpoint umožňuje změnit pořadí zastávek v rámci jízdy na základě zadaných parametrů.
         * @summary Změna pořadí zastávek jízdy
         * @param {number} id ID jízdy
         * @param {PatchRideStopSequenceRequest} patchRideStopSequenceRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchRideStopSequence(id: number, patchRideStopSequenceRequest: PatchRideStopSequenceRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchRideStopSequence(id, patchRideStopSequenceRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Tento endpoint umožňuje opakované zařazení jízdy do slotu na základě zadaných parametrů.
         * @summary Opakované zařazení jízdy do slotu
         * @param {number} id ID jízdy
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pigeonholeRide(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pigeonholeRide(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Tento endpoint umožňuje rozdělit zadanou jízdu na základě zvolených parametrů.
         * @summary Rozdělení jízdy
         * @param {number} id ID jízdy
         * @param {UnjoinRideRequest} unjoinRideRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async unjoinRide(id: number, unjoinRideRequest: UnjoinRideRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UnjoinRideResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.unjoinRide(id, unjoinRideRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RideApi - factory interface
 * @export
 */
export const RideApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RideApiFp(configuration)
    return {
        /**
         * Tento endpoint umožňuje získat dostupné typy sloučení pro vybrané jízdy.
         * @summary Vrátí typy sloučení jízd
         * @param {Array<number>} rideIds Id jízd
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJoinTypes(rideIds: Array<number>, options?: any): AxiosPromise<Array<JoinType>> {
            return localVarFp.getJoinTypes(rideIds, options).then((request) => request(axios, basePath));
        },
        /**
         * Tento endpoint umožňuje získat detaily o zadané jízdě.
         * @summary Vrátí detail jízdy
         * @param {number} id Id jízdy
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRide(id: number, options?: any): AxiosPromise<GetRideModel> {
            return localVarFp.getRide(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Informace o zákaznících dané jízdy
         * @param {number} id ID jízdy
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRideCustomersInformation(id: number, options?: any): AxiosPromise<GetRideCustomersInformationResponse> {
            return localVarFp.getRideCustomersInformation(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Tento endpoint umožňuje informaci o počtu jízd podle stavů v zadaném časovém období.
         * @summary Získat statistiky jízd
         * @param {string} start Datum začátku rozsahu
         * @param {string} end Datum konce rozsahu
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRideStatistics(start: string, end: string, options?: any): AxiosPromise<Array<RideStatisticsCountModel>> {
            return localVarFp.getRideStatistics(start, end, options).then((request) => request(axios, basePath));
        },
        /**
         * Tento endpoint umožňuje získat stránkovaný seznam s detaily jízd s filtrací podle vozidle, řidiče, času, slotu, adresy.
         * @summary Vrátí seznam jízd
         * @param {number} page 
         * @param {number} pageSize 
         * @param {boolean} isDescendingSort 
         * @param {number} [customerId] Id zákazníka
         * @param {string} [address] Adresa
         * @param {string} [date] Datum
         * @param {string} [start] Začátek
         * @param {string} [end] Konec
         * @param {number} [driverId] Id řidiče
         * @param {number} [assistantId] Id asistenta
         * @param {number} [vehicleId] Id vozidla
         * @param {number} [slotId] Id slotu
         * @param {Array<number>} [states] Stavy
         * @param {string} [sortPropertyName] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRides(page: number, pageSize: number, isDescendingSort: boolean, customerId?: number, address?: string, date?: string, start?: string, end?: string, driverId?: number, assistantId?: number, vehicleId?: number, slotId?: number, states?: Array<number>, sortPropertyName?: string, search?: string, options?: any): AxiosPromise<GetRidesResponse> {
            return localVarFp.getRides(page, pageSize, isDescendingSort, customerId, address, date, start, end, driverId, assistantId, vehicleId, slotId, states, sortPropertyName, search, options).then((request) => request(axios, basePath));
        },
        /**
         * Tento endpoint umožňuje získat seznam jízd ve vybraném časovém období pro potřeby plánování.  Narozdíl od entpoint GET/ride získává méně informací z databáze. Jízdy je možné filtrovat podle stavů a podstavů
         * @summary Vrátí seznam jízd pro plánování
         * @param {string} start Datum a čas začátku plánovaného rozsahu jízd.
         * @param {string} end Datum a čas konce plánovaného rozsahu jízd.
         * @param {Array<number>} [states] Seznam stavů jízd, které mají být zahrnuty v plánovaném rozsahu.
         * @param {Array<number>} [cancellationTypes] Seznam typů zrušení jízd, které mají být zahrnuty v plánovaném rozsahu.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRidesPlanning(start: string, end: string, states?: Array<number>, cancellationTypes?: Array<number>, options?: any): AxiosPromise<GetRidesPlanningResponse> {
            return localVarFp.getRidesPlanning(start, end, states, cancellationTypes, options).then((request) => request(axios, basePath));
        },
        /**
         * Tento endpoint umožňuje získat typy rozdělení pro danou jízdu nebo instanci objednávky.
         * @summary Získat typy rozdělení jízdy
         * @param {number} id Id
         * @param {number} instanceId Id instance
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUnjoinTypes(id: number, instanceId: number, options?: any): AxiosPromise<Array<UnjoinType>> {
            return localVarFp.getUnjoinTypes(id, instanceId, options).then((request) => request(axios, basePath));
        },
        /**
         * Tento endpoint umožňuje sloučit vybrané jízdy.  Očekává seznam naložení a vyložení ve zvoleném pořadí, typ sloučení a trvání jízdy po sloučení.
         * @summary Sloučit jízdy
         * @param {JoinRidesRequest} joinRidesRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        joinRides(joinRidesRequest: JoinRidesRequest, options?: any): AxiosPromise<JoinRidesResponse> {
            return localVarFp.joinRides(joinRidesRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Tento endpoint umožňuje změnit délku jízdy na základě zadaných parametrů.
         * @summary Změna délky jízdy
         * @param {number} id ID jízdy
         * @param {PatchRideDurationRequest} patchRideDurationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchRideDuration(id: number, patchRideDurationRequest: PatchRideDurationRequest, options?: any): AxiosPromise<JoinRidesResponse> {
            return localVarFp.patchRideDuration(id, patchRideDurationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Tento endpoint umožňuje změnit délku jízdy na základě zadaných parametrů.
         * @summary Změna délky jízdy
         * @param {number} id ID jízdy
         * @param {PatchRideSlotInstanceRequest} patchRideSlotInstanceRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchRideSlotInstance(id: number, patchRideSlotInstanceRequest: PatchRideSlotInstanceRequest, options?: any): AxiosPromise<void> {
            return localVarFp.patchRideSlotInstance(id, patchRideSlotInstanceRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Tento endpoint umožňuje změnit pořadí zastávek v rámci jízdy na základě zadaných parametrů.
         * @summary Změna pořadí zastávek jízdy
         * @param {number} id ID jízdy
         * @param {PatchRideStopSequenceRequest} patchRideStopSequenceRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchRideStopSequence(id: number, patchRideStopSequenceRequest: PatchRideStopSequenceRequest, options?: any): AxiosPromise<void> {
            return localVarFp.patchRideStopSequence(id, patchRideStopSequenceRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Tento endpoint umožňuje opakované zařazení jízdy do slotu na základě zadaných parametrů.
         * @summary Opakované zařazení jízdy do slotu
         * @param {number} id ID jízdy
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pigeonholeRide(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.pigeonholeRide(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Tento endpoint umožňuje rozdělit zadanou jízdu na základě zvolených parametrů.
         * @summary Rozdělení jízdy
         * @param {number} id ID jízdy
         * @param {UnjoinRideRequest} unjoinRideRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unjoinRide(id: number, unjoinRideRequest: UnjoinRideRequest, options?: any): AxiosPromise<UnjoinRideResponse> {
            return localVarFp.unjoinRide(id, unjoinRideRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RideApi - object-oriented interface
 * @export
 * @class RideApi
 * @extends {BaseAPI}
 */
export class RideApi extends BaseAPI {
    /**
     * Tento endpoint umožňuje získat dostupné typy sloučení pro vybrané jízdy.
     * @summary Vrátí typy sloučení jízd
     * @param {Array<number>} rideIds Id jízd
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RideApi
     */
    public getJoinTypes(rideIds: Array<number>, options?: AxiosRequestConfig) {
        return RideApiFp(this.configuration).getJoinTypes(rideIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Tento endpoint umožňuje získat detaily o zadané jízdě.
     * @summary Vrátí detail jízdy
     * @param {number} id Id jízdy
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RideApi
     */
    public getRide(id: number, options?: AxiosRequestConfig) {
        return RideApiFp(this.configuration).getRide(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Informace o zákaznících dané jízdy
     * @param {number} id ID jízdy
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RideApi
     */
    public getRideCustomersInformation(id: number, options?: AxiosRequestConfig) {
        return RideApiFp(this.configuration).getRideCustomersInformation(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Tento endpoint umožňuje informaci o počtu jízd podle stavů v zadaném časovém období.
     * @summary Získat statistiky jízd
     * @param {string} start Datum začátku rozsahu
     * @param {string} end Datum konce rozsahu
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RideApi
     */
    public getRideStatistics(start: string, end: string, options?: AxiosRequestConfig) {
        return RideApiFp(this.configuration).getRideStatistics(start, end, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Tento endpoint umožňuje získat stránkovaný seznam s detaily jízd s filtrací podle vozidle, řidiče, času, slotu, adresy.
     * @summary Vrátí seznam jízd
     * @param {number} page 
     * @param {number} pageSize 
     * @param {boolean} isDescendingSort 
     * @param {number} [customerId] Id zákazníka
     * @param {string} [address] Adresa
     * @param {string} [date] Datum
     * @param {string} [start] Začátek
     * @param {string} [end] Konec
     * @param {number} [driverId] Id řidiče
     * @param {number} [assistantId] Id asistenta
     * @param {number} [vehicleId] Id vozidla
     * @param {number} [slotId] Id slotu
     * @param {Array<number>} [states] Stavy
     * @param {string} [sortPropertyName] 
     * @param {string} [search] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RideApi
     */
    public getRides(page: number, pageSize: number, isDescendingSort: boolean, customerId?: number, address?: string, date?: string, start?: string, end?: string, driverId?: number, assistantId?: number, vehicleId?: number, slotId?: number, states?: Array<number>, sortPropertyName?: string, search?: string, options?: AxiosRequestConfig) {
        return RideApiFp(this.configuration).getRides(page, pageSize, isDescendingSort, customerId, address, date, start, end, driverId, assistantId, vehicleId, slotId, states, sortPropertyName, search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Tento endpoint umožňuje získat seznam jízd ve vybraném časovém období pro potřeby plánování.  Narozdíl od entpoint GET/ride získává méně informací z databáze. Jízdy je možné filtrovat podle stavů a podstavů
     * @summary Vrátí seznam jízd pro plánování
     * @param {string} start Datum a čas začátku plánovaného rozsahu jízd.
     * @param {string} end Datum a čas konce plánovaného rozsahu jízd.
     * @param {Array<number>} [states] Seznam stavů jízd, které mají být zahrnuty v plánovaném rozsahu.
     * @param {Array<number>} [cancellationTypes] Seznam typů zrušení jízd, které mají být zahrnuty v plánovaném rozsahu.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RideApi
     */
    public getRidesPlanning(start: string, end: string, states?: Array<number>, cancellationTypes?: Array<number>, options?: AxiosRequestConfig) {
        return RideApiFp(this.configuration).getRidesPlanning(start, end, states, cancellationTypes, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Tento endpoint umožňuje získat typy rozdělení pro danou jízdu nebo instanci objednávky.
     * @summary Získat typy rozdělení jízdy
     * @param {number} id Id
     * @param {number} instanceId Id instance
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RideApi
     */
    public getUnjoinTypes(id: number, instanceId: number, options?: AxiosRequestConfig) {
        return RideApiFp(this.configuration).getUnjoinTypes(id, instanceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Tento endpoint umožňuje sloučit vybrané jízdy.  Očekává seznam naložení a vyložení ve zvoleném pořadí, typ sloučení a trvání jízdy po sloučení.
     * @summary Sloučit jízdy
     * @param {JoinRidesRequest} joinRidesRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RideApi
     */
    public joinRides(joinRidesRequest: JoinRidesRequest, options?: AxiosRequestConfig) {
        return RideApiFp(this.configuration).joinRides(joinRidesRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Tento endpoint umožňuje změnit délku jízdy na základě zadaných parametrů.
     * @summary Změna délky jízdy
     * @param {number} id ID jízdy
     * @param {PatchRideDurationRequest} patchRideDurationRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RideApi
     */
    public patchRideDuration(id: number, patchRideDurationRequest: PatchRideDurationRequest, options?: AxiosRequestConfig) {
        return RideApiFp(this.configuration).patchRideDuration(id, patchRideDurationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Tento endpoint umožňuje změnit délku jízdy na základě zadaných parametrů.
     * @summary Změna délky jízdy
     * @param {number} id ID jízdy
     * @param {PatchRideSlotInstanceRequest} patchRideSlotInstanceRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RideApi
     */
    public patchRideSlotInstance(id: number, patchRideSlotInstanceRequest: PatchRideSlotInstanceRequest, options?: AxiosRequestConfig) {
        return RideApiFp(this.configuration).patchRideSlotInstance(id, patchRideSlotInstanceRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Tento endpoint umožňuje změnit pořadí zastávek v rámci jízdy na základě zadaných parametrů.
     * @summary Změna pořadí zastávek jízdy
     * @param {number} id ID jízdy
     * @param {PatchRideStopSequenceRequest} patchRideStopSequenceRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RideApi
     */
    public patchRideStopSequence(id: number, patchRideStopSequenceRequest: PatchRideStopSequenceRequest, options?: AxiosRequestConfig) {
        return RideApiFp(this.configuration).patchRideStopSequence(id, patchRideStopSequenceRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Tento endpoint umožňuje opakované zařazení jízdy do slotu na základě zadaných parametrů.
     * @summary Opakované zařazení jízdy do slotu
     * @param {number} id ID jízdy
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RideApi
     */
    public pigeonholeRide(id: number, options?: AxiosRequestConfig) {
        return RideApiFp(this.configuration).pigeonholeRide(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Tento endpoint umožňuje rozdělit zadanou jízdu na základě zvolených parametrů.
     * @summary Rozdělení jízdy
     * @param {number} id ID jízdy
     * @param {UnjoinRideRequest} unjoinRideRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RideApi
     */
    public unjoinRide(id: number, unjoinRideRequest: UnjoinRideRequest, options?: AxiosRequestConfig) {
        return RideApiFp(this.configuration).unjoinRide(id, unjoinRideRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ShiftApi - axios parameter creator
 * @export
 */
export const ShiftApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Tento endpoint umožňuje získat seznam směn.
         * @summary Získá směny
         * @param {number} page 
         * @param {number} pageSize 
         * @param {boolean} isDescendingSort 
         * @param {string} [start] Datum začátku rozsahu.
         * @param {string} [end] Datum konce rozsahu.
         * @param {number} [driverId] ID řidiče.
         * @param {number} [vehicleId] ID vozidla.
         * @param {number} [assistantId] ID asistenta.
         * @param {number} [carrierId] ID dopravce.
         * @param {number} [slotInstanceId] ID instance slotu.
         * @param {Array<number>} [shiftTypes] Seznam typů směn.
         * @param {string} [sortPropertyName] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getShifts: async (page: number, pageSize: number, isDescendingSort: boolean, start?: string, end?: string, driverId?: number, vehicleId?: number, assistantId?: number, carrierId?: number, slotInstanceId?: number, shiftTypes?: Array<number>, sortPropertyName?: string, search?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'page' is not null or undefined
            assertParamExists('getShifts', 'page', page)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('getShifts', 'pageSize', pageSize)
            // verify required parameter 'isDescendingSort' is not null or undefined
            assertParamExists('getShifts', 'isDescendingSort', isDescendingSort)
            const localVarPath = `/shift`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (start !== undefined) {
                localVarQueryParameter['Start'] = (start as any instanceof Date) ?
                    (start as any).toISOString() :
                    start;
            }

            if (end !== undefined) {
                localVarQueryParameter['End'] = (end as any instanceof Date) ?
                    (end as any).toISOString() :
                    end;
            }

            if (driverId !== undefined) {
                localVarQueryParameter['DriverId'] = driverId;
            }

            if (vehicleId !== undefined) {
                localVarQueryParameter['VehicleId'] = vehicleId;
            }

            if (assistantId !== undefined) {
                localVarQueryParameter['AssistantId'] = assistantId;
            }

            if (carrierId !== undefined) {
                localVarQueryParameter['CarrierId'] = carrierId;
            }

            if (slotInstanceId !== undefined) {
                localVarQueryParameter['SlotInstanceId'] = slotInstanceId;
            }

            if (shiftTypes) {
                localVarQueryParameter['ShiftTypes'] = shiftTypes;
            }

            if (page !== undefined) {
                localVarQueryParameter['Page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (sortPropertyName !== undefined) {
                localVarQueryParameter['SortPropertyName'] = sortPropertyName;
            }

            if (isDescendingSort !== undefined) {
                localVarQueryParameter['IsDescendingSort'] = isDescendingSort;
            }

            if (search !== undefined) {
                localVarQueryParameter['Search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ShiftApi - functional programming interface
 * @export
 */
export const ShiftApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ShiftApiAxiosParamCreator(configuration)
    return {
        /**
         * Tento endpoint umožňuje získat seznam směn.
         * @summary Získá směny
         * @param {number} page 
         * @param {number} pageSize 
         * @param {boolean} isDescendingSort 
         * @param {string} [start] Datum začátku rozsahu.
         * @param {string} [end] Datum konce rozsahu.
         * @param {number} [driverId] ID řidiče.
         * @param {number} [vehicleId] ID vozidla.
         * @param {number} [assistantId] ID asistenta.
         * @param {number} [carrierId] ID dopravce.
         * @param {number} [slotInstanceId] ID instance slotu.
         * @param {Array<number>} [shiftTypes] Seznam typů směn.
         * @param {string} [sortPropertyName] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getShifts(page: number, pageSize: number, isDescendingSort: boolean, start?: string, end?: string, driverId?: number, vehicleId?: number, assistantId?: number, carrierId?: number, slotInstanceId?: number, shiftTypes?: Array<number>, sortPropertyName?: string, search?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetShiftsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getShifts(page, pageSize, isDescendingSort, start, end, driverId, vehicleId, assistantId, carrierId, slotInstanceId, shiftTypes, sortPropertyName, search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ShiftApi - factory interface
 * @export
 */
export const ShiftApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ShiftApiFp(configuration)
    return {
        /**
         * Tento endpoint umožňuje získat seznam směn.
         * @summary Získá směny
         * @param {number} page 
         * @param {number} pageSize 
         * @param {boolean} isDescendingSort 
         * @param {string} [start] Datum začátku rozsahu.
         * @param {string} [end] Datum konce rozsahu.
         * @param {number} [driverId] ID řidiče.
         * @param {number} [vehicleId] ID vozidla.
         * @param {number} [assistantId] ID asistenta.
         * @param {number} [carrierId] ID dopravce.
         * @param {number} [slotInstanceId] ID instance slotu.
         * @param {Array<number>} [shiftTypes] Seznam typů směn.
         * @param {string} [sortPropertyName] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getShifts(page: number, pageSize: number, isDescendingSort: boolean, start?: string, end?: string, driverId?: number, vehicleId?: number, assistantId?: number, carrierId?: number, slotInstanceId?: number, shiftTypes?: Array<number>, sortPropertyName?: string, search?: string, options?: any): AxiosPromise<GetShiftsResponse> {
            return localVarFp.getShifts(page, pageSize, isDescendingSort, start, end, driverId, vehicleId, assistantId, carrierId, slotInstanceId, shiftTypes, sortPropertyName, search, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ShiftApi - object-oriented interface
 * @export
 * @class ShiftApi
 * @extends {BaseAPI}
 */
export class ShiftApi extends BaseAPI {
    /**
     * Tento endpoint umožňuje získat seznam směn.
     * @summary Získá směny
     * @param {number} page 
     * @param {number} pageSize 
     * @param {boolean} isDescendingSort 
     * @param {string} [start] Datum začátku rozsahu.
     * @param {string} [end] Datum konce rozsahu.
     * @param {number} [driverId] ID řidiče.
     * @param {number} [vehicleId] ID vozidla.
     * @param {number} [assistantId] ID asistenta.
     * @param {number} [carrierId] ID dopravce.
     * @param {number} [slotInstanceId] ID instance slotu.
     * @param {Array<number>} [shiftTypes] Seznam typů směn.
     * @param {string} [sortPropertyName] 
     * @param {string} [search] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShiftApi
     */
    public getShifts(page: number, pageSize: number, isDescendingSort: boolean, start?: string, end?: string, driverId?: number, vehicleId?: number, assistantId?: number, carrierId?: number, slotInstanceId?: number, shiftTypes?: Array<number>, sortPropertyName?: string, search?: string, options?: AxiosRequestConfig) {
        return ShiftApiFp(this.configuration).getShifts(page, pageSize, isDescendingSort, start, end, driverId, vehicleId, assistantId, carrierId, slotInstanceId, shiftTypes, sortPropertyName, search, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ShiftlogApi - axios parameter creator
 * @export
 */
export const ShiftlogApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Tento endpoint umožňuje ukončit směnu řidiče, pokud ještě není ukončena.
         * @summary Ukončení směny
         * @param {EndLogShiftRequest} endLogShiftRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        endLogShift: async (endLogShiftRequest: EndLogShiftRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'endLogShiftRequest' is not null or undefined
            assertParamExists('endLogShift', 'endLogShiftRequest', endLogShiftRequest)
            const localVarPath = `/shiftlog/end`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(endLogShiftRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Tento endpoint umožňuje administrátorům a dispečerům vynutit ukončení směny řidiče.
         * @summary Vynucené ukončení směny
         * @param {ForceEndLogShiftRequest} forceEndLogShiftRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forceEndLogShift: async (forceEndLogShiftRequest: ForceEndLogShiftRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forceEndLogShiftRequest' is not null or undefined
            assertParamExists('forceEndLogShift', 'forceEndLogShiftRequest', forceEndLogShiftRequest)
            const localVarPath = `/shiftlog/forceend`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(forceEndLogShiftRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Tento endpoint umožňuje získat aktuální směnu řidiče.
         * @summary Vrátí aktuální směnu řidiče
         * @param {number} driverid ID řidiče
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDriverCurrentLogShift: async (driverid: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'driverid' is not null or undefined
            assertParamExists('getDriverCurrentLogShift', 'driverid', driverid)
            const localVarPath = `/shiftlog/current/{driverid}`
                .replace(`{${"driverid"}}`, encodeURIComponent(String(driverid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Tento endpoint umožňuje získat seznam směn, které lze spustit pro konkrétního řidiče.
         * @summary Vrátí směny, které lze spustit
         * @param {number} driverid ID řidiče
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDriverStartableShifts: async (driverid: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'driverid' is not null or undefined
            assertParamExists('getDriverStartableShifts', 'driverid', driverid)
            const localVarPath = `/shiftlog/startable/{driverid}`
                .replace(`{${"driverid"}}`, encodeURIComponent(String(driverid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Tento endpoint umožňuje získat záznamy o směnách v zadaném časovém rozmezí.
         * @summary Vrátí záznamy směn
         * @param {string} start Datum a čas začátku rozsahu záznamů.
         * @param {string} end Datum a čas konce rozsahu záznamů.
         * @param {number} [driverShiftId] ID směny řidiče (volitelné).
         * @param {number} [vehicleShiftId] ID směny vozidla (volitelné).
         * @param {number} [driverId] ID řidiče (volitelné).
         * @param {number} [vehicleId] ID vozidla (volitelné).
         * @param {number} [slotInstanceId] ID instance slotu (volitelné).
         * @param {number} [slotId] ID slotu (volitelné).
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getShiftLogs: async (start: string, end: string, driverShiftId?: number, vehicleShiftId?: number, driverId?: number, vehicleId?: number, slotInstanceId?: number, slotId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'start' is not null or undefined
            assertParamExists('getShiftLogs', 'start', start)
            // verify required parameter 'end' is not null or undefined
            assertParamExists('getShiftLogs', 'end', end)
            const localVarPath = `/shiftlog`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (start !== undefined) {
                localVarQueryParameter['Start'] = (start as any instanceof Date) ?
                    (start as any).toISOString() :
                    start;
            }

            if (end !== undefined) {
                localVarQueryParameter['End'] = (end as any instanceof Date) ?
                    (end as any).toISOString() :
                    end;
            }

            if (driverShiftId !== undefined) {
                localVarQueryParameter['DriverShiftId'] = driverShiftId;
            }

            if (vehicleShiftId !== undefined) {
                localVarQueryParameter['VehicleShiftId'] = vehicleShiftId;
            }

            if (driverId !== undefined) {
                localVarQueryParameter['DriverId'] = driverId;
            }

            if (vehicleId !== undefined) {
                localVarQueryParameter['VehicleId'] = vehicleId;
            }

            if (slotInstanceId !== undefined) {
                localVarQueryParameter['SlotInstanceId'] = slotInstanceId;
            }

            if (slotId !== undefined) {
                localVarQueryParameter['SlotId'] = slotId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Tento endpoint umožňuje získat záznamy o směnách v zadaném časovém rozmezí.
         * @summary Vrátí záznamy směn
         * @param {string} start Datum začátku časového rozmezí.
         * @param {string} end Datum konce časového rozmezí.
         * @param {boolean} isBoard True, pokud se jedná o hlavní směnu; jinak False.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getShiftLogsSummary: async (start: string, end: string, isBoard: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'start' is not null or undefined
            assertParamExists('getShiftLogsSummary', 'start', start)
            // verify required parameter 'end' is not null or undefined
            assertParamExists('getShiftLogsSummary', 'end', end)
            // verify required parameter 'isBoard' is not null or undefined
            assertParamExists('getShiftLogsSummary', 'isBoard', isBoard)
            const localVarPath = `/shiftlog/summary`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (start !== undefined) {
                localVarQueryParameter['Start'] = (start as any instanceof Date) ?
                    (start as any).toISOString() :
                    start;
            }

            if (end !== undefined) {
                localVarQueryParameter['End'] = (end as any instanceof Date) ?
                    (end as any).toISOString() :
                    end;
            }

            if (isBoard !== undefined) {
                localVarQueryParameter['IsBoard'] = isBoard;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Tento endpoint umožňuje opravit polohu události ve směnném logu na základě aktuálního GPS stavu vozidla.
         * @summary Oprava polohy události ve směnném logu
         * @param {number} eventid ID události
         * @param {PatchFixEventLocationRequest} patchFixEventLocationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchFixEventLocation: async (eventid: number, patchFixEventLocationRequest: PatchFixEventLocationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'eventid' is not null or undefined
            assertParamExists('patchFixEventLocation', 'eventid', eventid)
            // verify required parameter 'patchFixEventLocationRequest' is not null or undefined
            assertParamExists('patchFixEventLocation', 'patchFixEventLocationRequest', patchFixEventLocationRequest)
            const localVarPath = `/shiftlog/event/{eventid}/fixlocation`
                .replace(`{${"eventid"}}`, encodeURIComponent(String(eventid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchFixEventLocationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Tento endpoint umožňuje označit směnu jako uzavřenou.
         * @summary Uzavření směny
         * @param {number} drivershiftid 
         * @param {number} vehicleshiftid 
         * @param {PatchShiftLogClosedRequest} patchShiftLogClosedRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchShiftLogClosed: async (drivershiftid: number, vehicleshiftid: number, patchShiftLogClosedRequest: PatchShiftLogClosedRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'drivershiftid' is not null or undefined
            assertParamExists('patchShiftLogClosed', 'drivershiftid', drivershiftid)
            // verify required parameter 'vehicleshiftid' is not null or undefined
            assertParamExists('patchShiftLogClosed', 'vehicleshiftid', vehicleshiftid)
            // verify required parameter 'patchShiftLogClosedRequest' is not null or undefined
            assertParamExists('patchShiftLogClosed', 'patchShiftLogClosedRequest', patchShiftLogClosedRequest)
            const localVarPath = `/shiftlog/{drivershiftid}/{vehicleshiftid}/closed`
                .replace(`{${"drivershiftid"}}`, encodeURIComponent(String(drivershiftid)))
                .replace(`{${"vehicleshiftid"}}`, encodeURIComponent(String(vehicleshiftid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchShiftLogClosedRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Tento endpoint umožňuje aktualizovat odhady pro události v otevřené směně.
         * @summary Aktualizace odhadů směny
         * @param {number} drivershiftid 
         * @param {number} vehicleshiftid 
         * @param {PatchShiftLogEstimatesRequest} patchShiftLogEstimatesRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchShiftLogEstimates: async (drivershiftid: number, vehicleshiftid: number, patchShiftLogEstimatesRequest: PatchShiftLogEstimatesRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'drivershiftid' is not null or undefined
            assertParamExists('patchShiftLogEstimates', 'drivershiftid', drivershiftid)
            // verify required parameter 'vehicleshiftid' is not null or undefined
            assertParamExists('patchShiftLogEstimates', 'vehicleshiftid', vehicleshiftid)
            // verify required parameter 'patchShiftLogEstimatesRequest' is not null or undefined
            assertParamExists('patchShiftLogEstimates', 'patchShiftLogEstimatesRequest', patchShiftLogEstimatesRequest)
            const localVarPath = `/shiftlog/{drivershiftid}/{vehicleshiftid}/estimates`
                .replace(`{${"drivershiftid"}}`, encodeURIComponent(String(drivershiftid)))
                .replace(`{${"vehicleshiftid"}}`, encodeURIComponent(String(vehicleshiftid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchShiftLogEstimatesRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Tento endpoint umožňuje aktualizovat manuální hodnoty směny na základě manuálního zadání.
         * @summary Aktualizace manuálních hodnot směny
         * @param {number} drivershiftid 
         * @param {number} vehicleshiftid 
         * @param {PatchShitLogManualRequest} patchShitLogManualRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchShiftLogManualValues: async (drivershiftid: number, vehicleshiftid: number, patchShitLogManualRequest: PatchShitLogManualRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'drivershiftid' is not null or undefined
            assertParamExists('patchShiftLogManualValues', 'drivershiftid', drivershiftid)
            // verify required parameter 'vehicleshiftid' is not null or undefined
            assertParamExists('patchShiftLogManualValues', 'vehicleshiftid', vehicleshiftid)
            // verify required parameter 'patchShitLogManualRequest' is not null or undefined
            assertParamExists('patchShiftLogManualValues', 'patchShitLogManualRequest', patchShitLogManualRequest)
            const localVarPath = `/shiftlog/{drivershiftid}/{vehicleshiftid}/manual`
                .replace(`{${"drivershiftid"}}`, encodeURIComponent(String(drivershiftid)))
                .replace(`{${"vehicleshiftid"}}`, encodeURIComponent(String(vehicleshiftid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchShitLogManualRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Tento endpoint umožňuje uložit událost, kterou zákazník nahlásil během jízdy.
         * @summary Uložení zákaznické události
         * @param {number} drivershiftid 
         * @param {number} vehicleshiftid 
         * @param {SaveCustomerEventRequest} saveCustomerEventRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveCustomerEvent: async (drivershiftid: number, vehicleshiftid: number, saveCustomerEventRequest: SaveCustomerEventRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'drivershiftid' is not null or undefined
            assertParamExists('saveCustomerEvent', 'drivershiftid', drivershiftid)
            // verify required parameter 'vehicleshiftid' is not null or undefined
            assertParamExists('saveCustomerEvent', 'vehicleshiftid', vehicleshiftid)
            // verify required parameter 'saveCustomerEventRequest' is not null or undefined
            assertParamExists('saveCustomerEvent', 'saveCustomerEventRequest', saveCustomerEventRequest)
            const localVarPath = `/shiftlog/{drivershiftid}/{vehicleshiftid}/customerevent`
                .replace(`{${"drivershiftid"}}`, encodeURIComponent(String(drivershiftid)))
                .replace(`{${"vehicleshiftid"}}`, encodeURIComponent(String(vehicleshiftid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(saveCustomerEventRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Tento endpoint umožňuje zahájit novou směnu.
         * @summary Začít směnu
         * @param {StartLogShiftRequest} startLogShiftRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startLogShift: async (startLogShiftRequest: StartLogShiftRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'startLogShiftRequest' is not null or undefined
            assertParamExists('startLogShift', 'startLogShiftRequest', startLogShiftRequest)
            const localVarPath = `/shiftlog/start`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(startLogShiftRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Tento endpoint umožňuje přepnutí vozidla v rámci směny.
         * @summary Přepne vozidlo ve směně
         * @param {SwitchVehicleShiftRequest} switchVehicleShiftRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        switchVehicleShift: async (switchVehicleShiftRequest: SwitchVehicleShiftRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'switchVehicleShiftRequest' is not null or undefined
            assertParamExists('switchVehicleShift', 'switchVehicleShiftRequest', switchVehicleShiftRequest)
            const localVarPath = `/shiftlog/switchvehicleshift`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(switchVehicleShiftRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Tento endpoint umožňuje řidičům přepínat přestávku během směny.
         * @summary Přepínání stavu přestávky
         * @param {number} drivershiftid Id směny řidiče
         * @param {number} vehicleshiftid Id směny vozidla
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        toggleBreak: async (drivershiftid: number, vehicleshiftid: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'drivershiftid' is not null or undefined
            assertParamExists('toggleBreak', 'drivershiftid', drivershiftid)
            // verify required parameter 'vehicleshiftid' is not null or undefined
            assertParamExists('toggleBreak', 'vehicleshiftid', vehicleshiftid)
            const localVarPath = `/shiftlog/{drivershiftid}/{vehicleshiftid}/togglebreak`
                .replace(`{${"drivershiftid"}}`, encodeURIComponent(String(drivershiftid)))
                .replace(`{${"vehicleshiftid"}}`, encodeURIComponent(String(vehicleshiftid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ShiftlogApi - functional programming interface
 * @export
 */
export const ShiftlogApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ShiftlogApiAxiosParamCreator(configuration)
    return {
        /**
         * Tento endpoint umožňuje ukončit směnu řidiče, pokud ještě není ukončena.
         * @summary Ukončení směny
         * @param {EndLogShiftRequest} endLogShiftRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async endLogShift(endLogShiftRequest: EndLogShiftRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EndLogShiftResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.endLogShift(endLogShiftRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Tento endpoint umožňuje administrátorům a dispečerům vynutit ukončení směny řidiče.
         * @summary Vynucené ukončení směny
         * @param {ForceEndLogShiftRequest} forceEndLogShiftRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forceEndLogShift(forceEndLogShiftRequest: ForceEndLogShiftRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ForceEndLogShiftResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forceEndLogShift(forceEndLogShiftRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Tento endpoint umožňuje získat aktuální směnu řidiče.
         * @summary Vrátí aktuální směnu řidiče
         * @param {number} driverid ID řidiče
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDriverCurrentLogShift(driverid: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetDriverCurrentLogShiftResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDriverCurrentLogShift(driverid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Tento endpoint umožňuje získat seznam směn, které lze spustit pro konkrétního řidiče.
         * @summary Vrátí směny, které lze spustit
         * @param {number} driverid ID řidiče
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDriverStartableShifts(driverid: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetDriverStartableShiftsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDriverStartableShifts(driverid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Tento endpoint umožňuje získat záznamy o směnách v zadaném časovém rozmezí.
         * @summary Vrátí záznamy směn
         * @param {string} start Datum a čas začátku rozsahu záznamů.
         * @param {string} end Datum a čas konce rozsahu záznamů.
         * @param {number} [driverShiftId] ID směny řidiče (volitelné).
         * @param {number} [vehicleShiftId] ID směny vozidla (volitelné).
         * @param {number} [driverId] ID řidiče (volitelné).
         * @param {number} [vehicleId] ID vozidla (volitelné).
         * @param {number} [slotInstanceId] ID instance slotu (volitelné).
         * @param {number} [slotId] ID slotu (volitelné).
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getShiftLogs(start: string, end: string, driverShiftId?: number, vehicleShiftId?: number, driverId?: number, vehicleId?: number, slotInstanceId?: number, slotId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetShiftLogsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getShiftLogs(start, end, driverShiftId, vehicleShiftId, driverId, vehicleId, slotInstanceId, slotId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Tento endpoint umožňuje získat záznamy o směnách v zadaném časovém rozmezí.
         * @summary Vrátí záznamy směn
         * @param {string} start Datum začátku časového rozmezí.
         * @param {string} end Datum konce časového rozmezí.
         * @param {boolean} isBoard True, pokud se jedná o hlavní směnu; jinak False.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getShiftLogsSummary(start: string, end: string, isBoard: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetShiftLogsSummaryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getShiftLogsSummary(start, end, isBoard, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Tento endpoint umožňuje opravit polohu události ve směnném logu na základě aktuálního GPS stavu vozidla.
         * @summary Oprava polohy události ve směnném logu
         * @param {number} eventid ID události
         * @param {PatchFixEventLocationRequest} patchFixEventLocationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchFixEventLocation(eventid: number, patchFixEventLocationRequest: PatchFixEventLocationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchFixEventLocation(eventid, patchFixEventLocationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Tento endpoint umožňuje označit směnu jako uzavřenou.
         * @summary Uzavření směny
         * @param {number} drivershiftid 
         * @param {number} vehicleshiftid 
         * @param {PatchShiftLogClosedRequest} patchShiftLogClosedRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchShiftLogClosed(drivershiftid: number, vehicleshiftid: number, patchShiftLogClosedRequest: PatchShiftLogClosedRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchShiftLogClosed(drivershiftid, vehicleshiftid, patchShiftLogClosedRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Tento endpoint umožňuje aktualizovat odhady pro události v otevřené směně.
         * @summary Aktualizace odhadů směny
         * @param {number} drivershiftid 
         * @param {number} vehicleshiftid 
         * @param {PatchShiftLogEstimatesRequest} patchShiftLogEstimatesRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchShiftLogEstimates(drivershiftid: number, vehicleshiftid: number, patchShiftLogEstimatesRequest: PatchShiftLogEstimatesRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchShiftLogEstimates(drivershiftid, vehicleshiftid, patchShiftLogEstimatesRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Tento endpoint umožňuje aktualizovat manuální hodnoty směny na základě manuálního zadání.
         * @summary Aktualizace manuálních hodnot směny
         * @param {number} drivershiftid 
         * @param {number} vehicleshiftid 
         * @param {PatchShitLogManualRequest} patchShitLogManualRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchShiftLogManualValues(drivershiftid: number, vehicleshiftid: number, patchShitLogManualRequest: PatchShitLogManualRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchShiftLogManualValues(drivershiftid, vehicleshiftid, patchShitLogManualRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Tento endpoint umožňuje uložit událost, kterou zákazník nahlásil během jízdy.
         * @summary Uložení zákaznické události
         * @param {number} drivershiftid 
         * @param {number} vehicleshiftid 
         * @param {SaveCustomerEventRequest} saveCustomerEventRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveCustomerEvent(drivershiftid: number, vehicleshiftid: number, saveCustomerEventRequest: SaveCustomerEventRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SaveCustomerEventResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveCustomerEvent(drivershiftid, vehicleshiftid, saveCustomerEventRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Tento endpoint umožňuje zahájit novou směnu.
         * @summary Začít směnu
         * @param {StartLogShiftRequest} startLogShiftRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async startLogShift(startLogShiftRequest: StartLogShiftRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StartLogShiftResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.startLogShift(startLogShiftRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Tento endpoint umožňuje přepnutí vozidla v rámci směny.
         * @summary Přepne vozidlo ve směně
         * @param {SwitchVehicleShiftRequest} switchVehicleShiftRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async switchVehicleShift(switchVehicleShiftRequest: SwitchVehicleShiftRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SwitchVehicleShiftResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.switchVehicleShift(switchVehicleShiftRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Tento endpoint umožňuje řidičům přepínat přestávku během směny.
         * @summary Přepínání stavu přestávky
         * @param {number} drivershiftid Id směny řidiče
         * @param {number} vehicleshiftid Id směny vozidla
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async toggleBreak(drivershiftid: number, vehicleshiftid: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StartLogShiftResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.toggleBreak(drivershiftid, vehicleshiftid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ShiftlogApi - factory interface
 * @export
 */
export const ShiftlogApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ShiftlogApiFp(configuration)
    return {
        /**
         * Tento endpoint umožňuje ukončit směnu řidiče, pokud ještě není ukončena.
         * @summary Ukončení směny
         * @param {EndLogShiftRequest} endLogShiftRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        endLogShift(endLogShiftRequest: EndLogShiftRequest, options?: any): AxiosPromise<EndLogShiftResponse> {
            return localVarFp.endLogShift(endLogShiftRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Tento endpoint umožňuje administrátorům a dispečerům vynutit ukončení směny řidiče.
         * @summary Vynucené ukončení směny
         * @param {ForceEndLogShiftRequest} forceEndLogShiftRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forceEndLogShift(forceEndLogShiftRequest: ForceEndLogShiftRequest, options?: any): AxiosPromise<ForceEndLogShiftResponse> {
            return localVarFp.forceEndLogShift(forceEndLogShiftRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Tento endpoint umožňuje získat aktuální směnu řidiče.
         * @summary Vrátí aktuální směnu řidiče
         * @param {number} driverid ID řidiče
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDriverCurrentLogShift(driverid: number, options?: any): AxiosPromise<GetDriverCurrentLogShiftResponse> {
            return localVarFp.getDriverCurrentLogShift(driverid, options).then((request) => request(axios, basePath));
        },
        /**
         * Tento endpoint umožňuje získat seznam směn, které lze spustit pro konkrétního řidiče.
         * @summary Vrátí směny, které lze spustit
         * @param {number} driverid ID řidiče
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDriverStartableShifts(driverid: number, options?: any): AxiosPromise<GetDriverStartableShiftsResponse> {
            return localVarFp.getDriverStartableShifts(driverid, options).then((request) => request(axios, basePath));
        },
        /**
         * Tento endpoint umožňuje získat záznamy o směnách v zadaném časovém rozmezí.
         * @summary Vrátí záznamy směn
         * @param {string} start Datum a čas začátku rozsahu záznamů.
         * @param {string} end Datum a čas konce rozsahu záznamů.
         * @param {number} [driverShiftId] ID směny řidiče (volitelné).
         * @param {number} [vehicleShiftId] ID směny vozidla (volitelné).
         * @param {number} [driverId] ID řidiče (volitelné).
         * @param {number} [vehicleId] ID vozidla (volitelné).
         * @param {number} [slotInstanceId] ID instance slotu (volitelné).
         * @param {number} [slotId] ID slotu (volitelné).
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getShiftLogs(start: string, end: string, driverShiftId?: number, vehicleShiftId?: number, driverId?: number, vehicleId?: number, slotInstanceId?: number, slotId?: number, options?: any): AxiosPromise<GetShiftLogsResponse> {
            return localVarFp.getShiftLogs(start, end, driverShiftId, vehicleShiftId, driverId, vehicleId, slotInstanceId, slotId, options).then((request) => request(axios, basePath));
        },
        /**
         * Tento endpoint umožňuje získat záznamy o směnách v zadaném časovém rozmezí.
         * @summary Vrátí záznamy směn
         * @param {string} start Datum začátku časového rozmezí.
         * @param {string} end Datum konce časového rozmezí.
         * @param {boolean} isBoard True, pokud se jedná o hlavní směnu; jinak False.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getShiftLogsSummary(start: string, end: string, isBoard: boolean, options?: any): AxiosPromise<GetShiftLogsSummaryResponse> {
            return localVarFp.getShiftLogsSummary(start, end, isBoard, options).then((request) => request(axios, basePath));
        },
        /**
         * Tento endpoint umožňuje opravit polohu události ve směnném logu na základě aktuálního GPS stavu vozidla.
         * @summary Oprava polohy události ve směnném logu
         * @param {number} eventid ID události
         * @param {PatchFixEventLocationRequest} patchFixEventLocationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchFixEventLocation(eventid: number, patchFixEventLocationRequest: PatchFixEventLocationRequest, options?: any): AxiosPromise<void> {
            return localVarFp.patchFixEventLocation(eventid, patchFixEventLocationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Tento endpoint umožňuje označit směnu jako uzavřenou.
         * @summary Uzavření směny
         * @param {number} drivershiftid 
         * @param {number} vehicleshiftid 
         * @param {PatchShiftLogClosedRequest} patchShiftLogClosedRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchShiftLogClosed(drivershiftid: number, vehicleshiftid: number, patchShiftLogClosedRequest: PatchShiftLogClosedRequest, options?: any): AxiosPromise<void> {
            return localVarFp.patchShiftLogClosed(drivershiftid, vehicleshiftid, patchShiftLogClosedRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Tento endpoint umožňuje aktualizovat odhady pro události v otevřené směně.
         * @summary Aktualizace odhadů směny
         * @param {number} drivershiftid 
         * @param {number} vehicleshiftid 
         * @param {PatchShiftLogEstimatesRequest} patchShiftLogEstimatesRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchShiftLogEstimates(drivershiftid: number, vehicleshiftid: number, patchShiftLogEstimatesRequest: PatchShiftLogEstimatesRequest, options?: any): AxiosPromise<void> {
            return localVarFp.patchShiftLogEstimates(drivershiftid, vehicleshiftid, patchShiftLogEstimatesRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Tento endpoint umožňuje aktualizovat manuální hodnoty směny na základě manuálního zadání.
         * @summary Aktualizace manuálních hodnot směny
         * @param {number} drivershiftid 
         * @param {number} vehicleshiftid 
         * @param {PatchShitLogManualRequest} patchShitLogManualRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchShiftLogManualValues(drivershiftid: number, vehicleshiftid: number, patchShitLogManualRequest: PatchShitLogManualRequest, options?: any): AxiosPromise<void> {
            return localVarFp.patchShiftLogManualValues(drivershiftid, vehicleshiftid, patchShitLogManualRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Tento endpoint umožňuje uložit událost, kterou zákazník nahlásil během jízdy.
         * @summary Uložení zákaznické události
         * @param {number} drivershiftid 
         * @param {number} vehicleshiftid 
         * @param {SaveCustomerEventRequest} saveCustomerEventRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveCustomerEvent(drivershiftid: number, vehicleshiftid: number, saveCustomerEventRequest: SaveCustomerEventRequest, options?: any): AxiosPromise<SaveCustomerEventResponse> {
            return localVarFp.saveCustomerEvent(drivershiftid, vehicleshiftid, saveCustomerEventRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Tento endpoint umožňuje zahájit novou směnu.
         * @summary Začít směnu
         * @param {StartLogShiftRequest} startLogShiftRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startLogShift(startLogShiftRequest: StartLogShiftRequest, options?: any): AxiosPromise<StartLogShiftResponse> {
            return localVarFp.startLogShift(startLogShiftRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Tento endpoint umožňuje přepnutí vozidla v rámci směny.
         * @summary Přepne vozidlo ve směně
         * @param {SwitchVehicleShiftRequest} switchVehicleShiftRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        switchVehicleShift(switchVehicleShiftRequest: SwitchVehicleShiftRequest, options?: any): AxiosPromise<SwitchVehicleShiftResponse> {
            return localVarFp.switchVehicleShift(switchVehicleShiftRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Tento endpoint umožňuje řidičům přepínat přestávku během směny.
         * @summary Přepínání stavu přestávky
         * @param {number} drivershiftid Id směny řidiče
         * @param {number} vehicleshiftid Id směny vozidla
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        toggleBreak(drivershiftid: number, vehicleshiftid: number, options?: any): AxiosPromise<StartLogShiftResponse> {
            return localVarFp.toggleBreak(drivershiftid, vehicleshiftid, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ShiftlogApi - object-oriented interface
 * @export
 * @class ShiftlogApi
 * @extends {BaseAPI}
 */
export class ShiftlogApi extends BaseAPI {
    /**
     * Tento endpoint umožňuje ukončit směnu řidiče, pokud ještě není ukončena.
     * @summary Ukončení směny
     * @param {EndLogShiftRequest} endLogShiftRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShiftlogApi
     */
    public endLogShift(endLogShiftRequest: EndLogShiftRequest, options?: AxiosRequestConfig) {
        return ShiftlogApiFp(this.configuration).endLogShift(endLogShiftRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Tento endpoint umožňuje administrátorům a dispečerům vynutit ukončení směny řidiče.
     * @summary Vynucené ukončení směny
     * @param {ForceEndLogShiftRequest} forceEndLogShiftRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShiftlogApi
     */
    public forceEndLogShift(forceEndLogShiftRequest: ForceEndLogShiftRequest, options?: AxiosRequestConfig) {
        return ShiftlogApiFp(this.configuration).forceEndLogShift(forceEndLogShiftRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Tento endpoint umožňuje získat aktuální směnu řidiče.
     * @summary Vrátí aktuální směnu řidiče
     * @param {number} driverid ID řidiče
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShiftlogApi
     */
    public getDriverCurrentLogShift(driverid: number, options?: AxiosRequestConfig) {
        return ShiftlogApiFp(this.configuration).getDriverCurrentLogShift(driverid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Tento endpoint umožňuje získat seznam směn, které lze spustit pro konkrétního řidiče.
     * @summary Vrátí směny, které lze spustit
     * @param {number} driverid ID řidiče
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShiftlogApi
     */
    public getDriverStartableShifts(driverid: number, options?: AxiosRequestConfig) {
        return ShiftlogApiFp(this.configuration).getDriverStartableShifts(driverid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Tento endpoint umožňuje získat záznamy o směnách v zadaném časovém rozmezí.
     * @summary Vrátí záznamy směn
     * @param {string} start Datum a čas začátku rozsahu záznamů.
     * @param {string} end Datum a čas konce rozsahu záznamů.
     * @param {number} [driverShiftId] ID směny řidiče (volitelné).
     * @param {number} [vehicleShiftId] ID směny vozidla (volitelné).
     * @param {number} [driverId] ID řidiče (volitelné).
     * @param {number} [vehicleId] ID vozidla (volitelné).
     * @param {number} [slotInstanceId] ID instance slotu (volitelné).
     * @param {number} [slotId] ID slotu (volitelné).
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShiftlogApi
     */
    public getShiftLogs(start: string, end: string, driverShiftId?: number, vehicleShiftId?: number, driverId?: number, vehicleId?: number, slotInstanceId?: number, slotId?: number, options?: AxiosRequestConfig) {
        return ShiftlogApiFp(this.configuration).getShiftLogs(start, end, driverShiftId, vehicleShiftId, driverId, vehicleId, slotInstanceId, slotId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Tento endpoint umožňuje získat záznamy o směnách v zadaném časovém rozmezí.
     * @summary Vrátí záznamy směn
     * @param {string} start Datum začátku časového rozmezí.
     * @param {string} end Datum konce časového rozmezí.
     * @param {boolean} isBoard True, pokud se jedná o hlavní směnu; jinak False.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShiftlogApi
     */
    public getShiftLogsSummary(start: string, end: string, isBoard: boolean, options?: AxiosRequestConfig) {
        return ShiftlogApiFp(this.configuration).getShiftLogsSummary(start, end, isBoard, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Tento endpoint umožňuje opravit polohu události ve směnném logu na základě aktuálního GPS stavu vozidla.
     * @summary Oprava polohy události ve směnném logu
     * @param {number} eventid ID události
     * @param {PatchFixEventLocationRequest} patchFixEventLocationRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShiftlogApi
     */
    public patchFixEventLocation(eventid: number, patchFixEventLocationRequest: PatchFixEventLocationRequest, options?: AxiosRequestConfig) {
        return ShiftlogApiFp(this.configuration).patchFixEventLocation(eventid, patchFixEventLocationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Tento endpoint umožňuje označit směnu jako uzavřenou.
     * @summary Uzavření směny
     * @param {number} drivershiftid 
     * @param {number} vehicleshiftid 
     * @param {PatchShiftLogClosedRequest} patchShiftLogClosedRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShiftlogApi
     */
    public patchShiftLogClosed(drivershiftid: number, vehicleshiftid: number, patchShiftLogClosedRequest: PatchShiftLogClosedRequest, options?: AxiosRequestConfig) {
        return ShiftlogApiFp(this.configuration).patchShiftLogClosed(drivershiftid, vehicleshiftid, patchShiftLogClosedRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Tento endpoint umožňuje aktualizovat odhady pro události v otevřené směně.
     * @summary Aktualizace odhadů směny
     * @param {number} drivershiftid 
     * @param {number} vehicleshiftid 
     * @param {PatchShiftLogEstimatesRequest} patchShiftLogEstimatesRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShiftlogApi
     */
    public patchShiftLogEstimates(drivershiftid: number, vehicleshiftid: number, patchShiftLogEstimatesRequest: PatchShiftLogEstimatesRequest, options?: AxiosRequestConfig) {
        return ShiftlogApiFp(this.configuration).patchShiftLogEstimates(drivershiftid, vehicleshiftid, patchShiftLogEstimatesRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Tento endpoint umožňuje aktualizovat manuální hodnoty směny na základě manuálního zadání.
     * @summary Aktualizace manuálních hodnot směny
     * @param {number} drivershiftid 
     * @param {number} vehicleshiftid 
     * @param {PatchShitLogManualRequest} patchShitLogManualRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShiftlogApi
     */
    public patchShiftLogManualValues(drivershiftid: number, vehicleshiftid: number, patchShitLogManualRequest: PatchShitLogManualRequest, options?: AxiosRequestConfig) {
        return ShiftlogApiFp(this.configuration).patchShiftLogManualValues(drivershiftid, vehicleshiftid, patchShitLogManualRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Tento endpoint umožňuje uložit událost, kterou zákazník nahlásil během jízdy.
     * @summary Uložení zákaznické události
     * @param {number} drivershiftid 
     * @param {number} vehicleshiftid 
     * @param {SaveCustomerEventRequest} saveCustomerEventRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShiftlogApi
     */
    public saveCustomerEvent(drivershiftid: number, vehicleshiftid: number, saveCustomerEventRequest: SaveCustomerEventRequest, options?: AxiosRequestConfig) {
        return ShiftlogApiFp(this.configuration).saveCustomerEvent(drivershiftid, vehicleshiftid, saveCustomerEventRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Tento endpoint umožňuje zahájit novou směnu.
     * @summary Začít směnu
     * @param {StartLogShiftRequest} startLogShiftRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShiftlogApi
     */
    public startLogShift(startLogShiftRequest: StartLogShiftRequest, options?: AxiosRequestConfig) {
        return ShiftlogApiFp(this.configuration).startLogShift(startLogShiftRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Tento endpoint umožňuje přepnutí vozidla v rámci směny.
     * @summary Přepne vozidlo ve směně
     * @param {SwitchVehicleShiftRequest} switchVehicleShiftRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShiftlogApi
     */
    public switchVehicleShift(switchVehicleShiftRequest: SwitchVehicleShiftRequest, options?: AxiosRequestConfig) {
        return ShiftlogApiFp(this.configuration).switchVehicleShift(switchVehicleShiftRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Tento endpoint umožňuje řidičům přepínat přestávku během směny.
     * @summary Přepínání stavu přestávky
     * @param {number} drivershiftid Id směny řidiče
     * @param {number} vehicleshiftid Id směny vozidla
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShiftlogApi
     */
    public toggleBreak(drivershiftid: number, vehicleshiftid: number, options?: AxiosRequestConfig) {
        return ShiftlogApiFp(this.configuration).toggleBreak(drivershiftid, vehicleshiftid, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SlotApi - axios parameter creator
 * @export
 */
export const SlotApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Tento endpoint získá informace o slotu
         * @summary Vrátí slot
         * @param {number} id ID slotu, který chcete získat.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSlot: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getSlot', 'id', id)
            const localVarPath = `/slot/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Tento endpoint vrátí seznam slotů
         * @summary Vrátí seznam slotů
         * @param {boolean} isActive Indikace aktivity slotu.
         * @param {number} operatingDayId ID operačního dne.
         * @param {number} page 
         * @param {number} pageSize 
         * @param {boolean} isDescendingSort 
         * @param {string} [sortPropertyName] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSlots: async (isActive: boolean, operatingDayId: number, page: number, pageSize: number, isDescendingSort: boolean, sortPropertyName?: string, search?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'isActive' is not null or undefined
            assertParamExists('getSlots', 'isActive', isActive)
            // verify required parameter 'operatingDayId' is not null or undefined
            assertParamExists('getSlots', 'operatingDayId', operatingDayId)
            // verify required parameter 'page' is not null or undefined
            assertParamExists('getSlots', 'page', page)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('getSlots', 'pageSize', pageSize)
            // verify required parameter 'isDescendingSort' is not null or undefined
            assertParamExists('getSlots', 'isDescendingSort', isDescendingSort)
            const localVarPath = `/slot`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (isActive !== undefined) {
                localVarQueryParameter['IsActive'] = isActive;
            }

            if (operatingDayId !== undefined) {
                localVarQueryParameter['OperatingDayId'] = operatingDayId;
            }

            if (page !== undefined) {
                localVarQueryParameter['Page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (sortPropertyName !== undefined) {
                localVarQueryParameter['SortPropertyName'] = sortPropertyName;
            }

            if (isDescendingSort !== undefined) {
                localVarQueryParameter['IsDescendingSort'] = isDescendingSort;
            }

            if (search !== undefined) {
                localVarQueryParameter['Search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Tento endpoint umožňuje aktualizaci stavu \'Aktivní\' slotu.
         * @summary Aktualizace aktivity slotu
         * @param {number} id ID slotu, na kterém chcete provést změnu aktivity.
         * @param {PatchIsActiveRequest3} patchIsActiveRequest3 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchSlotIsActive: async (id: number, patchIsActiveRequest3: PatchIsActiveRequest3, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('patchSlotIsActive', 'id', id)
            // verify required parameter 'patchIsActiveRequest3' is not null or undefined
            assertParamExists('patchSlotIsActive', 'patchIsActiveRequest3', patchIsActiveRequest3)
            const localVarPath = `/slot/{id}/isactive`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchIsActiveRequest3, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Tento endpoint umožňuje aktualizaci názvu slotu.
         * @summary Aktualizace názvu slotu
         * @param {number} id ID slotu 
         * @param {PatchSlotNameRequest} patchSlotNameRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchSlotName: async (id: number, patchSlotNameRequest: PatchSlotNameRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('patchSlotName', 'id', id)
            // verify required parameter 'patchSlotNameRequest' is not null or undefined
            assertParamExists('patchSlotName', 'patchSlotNameRequest', patchSlotNameRequest)
            const localVarPath = `/slot/{id}/name`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchSlotNameRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Tento endpoint uloží nově vytvořený slot
         * @summary Uloží slot
         * @param {SaveSlotRequest} saveSlotRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveSlot: async (saveSlotRequest: SaveSlotRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'saveSlotRequest' is not null or undefined
            assertParamExists('saveSlot', 'saveSlotRequest', saveSlotRequest)
            const localVarPath = `/slot`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(saveSlotRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Tento endpoint umožňuje sekvenci slotů.
         * @summary Sekvence slotů
         * @param {SequenceSlotsRequest} sequenceSlotsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sequenceSlots: async (sequenceSlotsRequest: SequenceSlotsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sequenceSlotsRequest' is not null or undefined
            assertParamExists('sequenceSlots', 'sequenceSlotsRequest', sequenceSlotsRequest)
            const localVarPath = `/slot/sequence`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sequenceSlotsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SlotApi - functional programming interface
 * @export
 */
export const SlotApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SlotApiAxiosParamCreator(configuration)
    return {
        /**
         * Tento endpoint získá informace o slotu
         * @summary Vrátí slot
         * @param {number} id ID slotu, který chcete získat.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSlot(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetSlotResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSlot(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Tento endpoint vrátí seznam slotů
         * @summary Vrátí seznam slotů
         * @param {boolean} isActive Indikace aktivity slotu.
         * @param {number} operatingDayId ID operačního dne.
         * @param {number} page 
         * @param {number} pageSize 
         * @param {boolean} isDescendingSort 
         * @param {string} [sortPropertyName] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSlots(isActive: boolean, operatingDayId: number, page: number, pageSize: number, isDescendingSort: boolean, sortPropertyName?: string, search?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetSlotsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSlots(isActive, operatingDayId, page, pageSize, isDescendingSort, sortPropertyName, search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Tento endpoint umožňuje aktualizaci stavu \'Aktivní\' slotu.
         * @summary Aktualizace aktivity slotu
         * @param {number} id ID slotu, na kterém chcete provést změnu aktivity.
         * @param {PatchIsActiveRequest3} patchIsActiveRequest3 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchSlotIsActive(id: number, patchIsActiveRequest3: PatchIsActiveRequest3, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchSlotIsActive(id, patchIsActiveRequest3, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Tento endpoint umožňuje aktualizaci názvu slotu.
         * @summary Aktualizace názvu slotu
         * @param {number} id ID slotu 
         * @param {PatchSlotNameRequest} patchSlotNameRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchSlotName(id: number, patchSlotNameRequest: PatchSlotNameRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchSlotName(id, patchSlotNameRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Tento endpoint uloží nově vytvořený slot
         * @summary Uloží slot
         * @param {SaveSlotRequest} saveSlotRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveSlot(saveSlotRequest: SaveSlotRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SaveSlotResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveSlot(saveSlotRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Tento endpoint umožňuje sekvenci slotů.
         * @summary Sekvence slotů
         * @param {SequenceSlotsRequest} sequenceSlotsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sequenceSlots(sequenceSlotsRequest: SequenceSlotsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sequenceSlots(sequenceSlotsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SlotApi - factory interface
 * @export
 */
export const SlotApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SlotApiFp(configuration)
    return {
        /**
         * Tento endpoint získá informace o slotu
         * @summary Vrátí slot
         * @param {number} id ID slotu, který chcete získat.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSlot(id: number, options?: any): AxiosPromise<GetSlotResponse> {
            return localVarFp.getSlot(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Tento endpoint vrátí seznam slotů
         * @summary Vrátí seznam slotů
         * @param {boolean} isActive Indikace aktivity slotu.
         * @param {number} operatingDayId ID operačního dne.
         * @param {number} page 
         * @param {number} pageSize 
         * @param {boolean} isDescendingSort 
         * @param {string} [sortPropertyName] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSlots(isActive: boolean, operatingDayId: number, page: number, pageSize: number, isDescendingSort: boolean, sortPropertyName?: string, search?: string, options?: any): AxiosPromise<GetSlotsResponse> {
            return localVarFp.getSlots(isActive, operatingDayId, page, pageSize, isDescendingSort, sortPropertyName, search, options).then((request) => request(axios, basePath));
        },
        /**
         * Tento endpoint umožňuje aktualizaci stavu \'Aktivní\' slotu.
         * @summary Aktualizace aktivity slotu
         * @param {number} id ID slotu, na kterém chcete provést změnu aktivity.
         * @param {PatchIsActiveRequest3} patchIsActiveRequest3 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchSlotIsActive(id: number, patchIsActiveRequest3: PatchIsActiveRequest3, options?: any): AxiosPromise<void> {
            return localVarFp.patchSlotIsActive(id, patchIsActiveRequest3, options).then((request) => request(axios, basePath));
        },
        /**
         * Tento endpoint umožňuje aktualizaci názvu slotu.
         * @summary Aktualizace názvu slotu
         * @param {number} id ID slotu 
         * @param {PatchSlotNameRequest} patchSlotNameRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchSlotName(id: number, patchSlotNameRequest: PatchSlotNameRequest, options?: any): AxiosPromise<void> {
            return localVarFp.patchSlotName(id, patchSlotNameRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Tento endpoint uloží nově vytvořený slot
         * @summary Uloží slot
         * @param {SaveSlotRequest} saveSlotRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveSlot(saveSlotRequest: SaveSlotRequest, options?: any): AxiosPromise<SaveSlotResponse> {
            return localVarFp.saveSlot(saveSlotRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Tento endpoint umožňuje sekvenci slotů.
         * @summary Sekvence slotů
         * @param {SequenceSlotsRequest} sequenceSlotsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sequenceSlots(sequenceSlotsRequest: SequenceSlotsRequest, options?: any): AxiosPromise<void> {
            return localVarFp.sequenceSlots(sequenceSlotsRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SlotApi - object-oriented interface
 * @export
 * @class SlotApi
 * @extends {BaseAPI}
 */
export class SlotApi extends BaseAPI {
    /**
     * Tento endpoint získá informace o slotu
     * @summary Vrátí slot
     * @param {number} id ID slotu, který chcete získat.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SlotApi
     */
    public getSlot(id: number, options?: AxiosRequestConfig) {
        return SlotApiFp(this.configuration).getSlot(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Tento endpoint vrátí seznam slotů
     * @summary Vrátí seznam slotů
     * @param {boolean} isActive Indikace aktivity slotu.
     * @param {number} operatingDayId ID operačního dne.
     * @param {number} page 
     * @param {number} pageSize 
     * @param {boolean} isDescendingSort 
     * @param {string} [sortPropertyName] 
     * @param {string} [search] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SlotApi
     */
    public getSlots(isActive: boolean, operatingDayId: number, page: number, pageSize: number, isDescendingSort: boolean, sortPropertyName?: string, search?: string, options?: AxiosRequestConfig) {
        return SlotApiFp(this.configuration).getSlots(isActive, operatingDayId, page, pageSize, isDescendingSort, sortPropertyName, search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Tento endpoint umožňuje aktualizaci stavu \'Aktivní\' slotu.
     * @summary Aktualizace aktivity slotu
     * @param {number} id ID slotu, na kterém chcete provést změnu aktivity.
     * @param {PatchIsActiveRequest3} patchIsActiveRequest3 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SlotApi
     */
    public patchSlotIsActive(id: number, patchIsActiveRequest3: PatchIsActiveRequest3, options?: AxiosRequestConfig) {
        return SlotApiFp(this.configuration).patchSlotIsActive(id, patchIsActiveRequest3, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Tento endpoint umožňuje aktualizaci názvu slotu.
     * @summary Aktualizace názvu slotu
     * @param {number} id ID slotu 
     * @param {PatchSlotNameRequest} patchSlotNameRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SlotApi
     */
    public patchSlotName(id: number, patchSlotNameRequest: PatchSlotNameRequest, options?: AxiosRequestConfig) {
        return SlotApiFp(this.configuration).patchSlotName(id, patchSlotNameRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Tento endpoint uloží nově vytvořený slot
     * @summary Uloží slot
     * @param {SaveSlotRequest} saveSlotRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SlotApi
     */
    public saveSlot(saveSlotRequest: SaveSlotRequest, options?: AxiosRequestConfig) {
        return SlotApiFp(this.configuration).saveSlot(saveSlotRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Tento endpoint umožňuje sekvenci slotů.
     * @summary Sekvence slotů
     * @param {SequenceSlotsRequest} sequenceSlotsRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SlotApi
     */
    public sequenceSlots(sequenceSlotsRequest: SequenceSlotsRequest, options?: AxiosRequestConfig) {
        return SlotApiFp(this.configuration).sequenceSlots(sequenceSlotsRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SlotinstanceApi - axios parameter creator
 * @export
 */
export const SlotinstanceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Tento endpoint umožňuje klonovat směny.
         * @summary Naklonuje Směny
         * @param {string} source Datum zdrojové směny, kterou chcete zduplikovat.
         * @param {string} target Datum cílové směny, kam chcete zduplikovat zdrojovou směnu.
         * @param {CloneShiftsRequest} cloneShiftsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cloneShifts: async (source: string, target: string, cloneShiftsRequest: CloneShiftsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'source' is not null or undefined
            assertParamExists('cloneShifts', 'source', source)
            // verify required parameter 'target' is not null or undefined
            assertParamExists('cloneShifts', 'target', target)
            // verify required parameter 'cloneShiftsRequest' is not null or undefined
            assertParamExists('cloneShifts', 'cloneShiftsRequest', cloneShiftsRequest)
            const localVarPath = `/slotinstance/cloneshifts/{source}/{target}`
                .replace(`{${"source"}}`, encodeURIComponent(String(source)))
                .replace(`{${"target"}}`, encodeURIComponent(String(target)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(cloneShiftsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Tento endpoint umožňuje odstranit instanci slotu.
         * @summary Odstraní instanci slotu
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSlotInstance: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteSlotInstance', 'id', id)
            const localVarPath = `/slotinstance/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Tento endpoint umožňuje získat informace o instanci slotu.
         * @summary Získá instanci slotu
         * @param {number} id ID instance slotu
         * @param {number} page 
         * @param {number} pageSize 
         * @param {boolean} isDescendingSort 
         * @param {string} [sortPropertyName] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSlotInstance: async (id: number, page: number, pageSize: number, isDescendingSort: boolean, sortPropertyName?: string, search?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getSlotInstance', 'id', id)
            // verify required parameter 'page' is not null or undefined
            assertParamExists('getSlotInstance', 'page', page)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('getSlotInstance', 'pageSize', pageSize)
            // verify required parameter 'isDescendingSort' is not null or undefined
            assertParamExists('getSlotInstance', 'isDescendingSort', isDescendingSort)
            const localVarPath = `/slotinstance/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['Page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (sortPropertyName !== undefined) {
                localVarQueryParameter['SortPropertyName'] = sortPropertyName;
            }

            if (isDescendingSort !== undefined) {
                localVarQueryParameter['IsDescendingSort'] = isDescendingSort;
            }

            if (search !== undefined) {
                localVarQueryParameter['Search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Tento endpoint umožňuje získat seznam slotových instancí.
         * @summary Získá seznam slotových instancí
         * @param {string} start Počáteční datum pro vyhledávání slotových instancí.
         * @param {string} end Konečné datum pro vyhledávání slotových instancí.
         * @param {number} page 
         * @param {number} pageSize 
         * @param {boolean} isDescendingSort 
         * @param {string} [sortPropertyName] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSlotInstances: async (start: string, end: string, page: number, pageSize: number, isDescendingSort: boolean, sortPropertyName?: string, search?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'start' is not null or undefined
            assertParamExists('getSlotInstances', 'start', start)
            // verify required parameter 'end' is not null or undefined
            assertParamExists('getSlotInstances', 'end', end)
            // verify required parameter 'page' is not null or undefined
            assertParamExists('getSlotInstances', 'page', page)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('getSlotInstances', 'pageSize', pageSize)
            // verify required parameter 'isDescendingSort' is not null or undefined
            assertParamExists('getSlotInstances', 'isDescendingSort', isDescendingSort)
            const localVarPath = `/slotinstance/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (start !== undefined) {
                localVarQueryParameter['Start'] = (start as any instanceof Date) ?
                    (start as any).toISOString() :
                    start;
            }

            if (end !== undefined) {
                localVarQueryParameter['End'] = (end as any instanceof Date) ?
                    (end as any).toISOString() :
                    end;
            }

            if (page !== undefined) {
                localVarQueryParameter['Page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (sortPropertyName !== undefined) {
                localVarQueryParameter['SortPropertyName'] = sortPropertyName;
            }

            if (isDescendingSort !== undefined) {
                localVarQueryParameter['IsDescendingSort'] = isDescendingSort;
            }

            if (search !== undefined) {
                localVarQueryParameter['Search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Tento endpoint umožňuje získat plánování instancí slotu.
         * @summary Vrátí plánování instancí slotu
         * @param {string} start Počáteční datum pro vyhledávání plánování instancí slotu.
         * @param {string} end Konečné datum pro vyhledávání plánování instancí slotu.
         * @param {number} page 
         * @param {number} pageSize 
         * @param {boolean} isDescendingSort 
         * @param {string} [sortPropertyName] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSlotInstancesPlanning: async (start: string, end: string, page: number, pageSize: number, isDescendingSort: boolean, sortPropertyName?: string, search?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'start' is not null or undefined
            assertParamExists('getSlotInstancesPlanning', 'start', start)
            // verify required parameter 'end' is not null or undefined
            assertParamExists('getSlotInstancesPlanning', 'end', end)
            // verify required parameter 'page' is not null or undefined
            assertParamExists('getSlotInstancesPlanning', 'page', page)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('getSlotInstancesPlanning', 'pageSize', pageSize)
            // verify required parameter 'isDescendingSort' is not null or undefined
            assertParamExists('getSlotInstancesPlanning', 'isDescendingSort', isDescendingSort)
            const localVarPath = `/slotinstance/planning/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (start !== undefined) {
                localVarQueryParameter['Start'] = (start as any instanceof Date) ?
                    (start as any).toISOString() :
                    start;
            }

            if (end !== undefined) {
                localVarQueryParameter['End'] = (end as any instanceof Date) ?
                    (end as any).toISOString() :
                    end;
            }

            if (page !== undefined) {
                localVarQueryParameter['Page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (sortPropertyName !== undefined) {
                localVarQueryParameter['SortPropertyName'] = sortPropertyName;
            }

            if (isDescendingSort !== undefined) {
                localVarQueryParameter['IsDescendingSort'] = isDescendingSort;
            }

            if (search !== undefined) {
                localVarQueryParameter['Search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Tento endpoint umožňuje aktualizovat stav aktivity slotové instance.
         * @summary Aktualizuje stav aktivity slotové instance
         * @param {number} id ID instance slotu, kterou chcete aktualizovat.
         * @param {PatchIsActiveRequest4} patchIsActiveRequest4 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchSlotInstanceIsActive: async (id: number, patchIsActiveRequest4: PatchIsActiveRequest4, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('patchSlotInstanceIsActive', 'id', id)
            // verify required parameter 'patchIsActiveRequest4' is not null or undefined
            assertParamExists('patchSlotInstanceIsActive', 'patchIsActiveRequest4', patchIsActiveRequest4)
            const localVarPath = `/slotinstance/{id}/isactive`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchIsActiveRequest4, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Tento endpoint umožňuje aktualizovat směny pro konkrétní slotovou instanci.
         * @summary Aktualizace směn v rámci slotové instance
         * @param {number} id ID slotové instance.
         * @param {PatchSlotInstanceShiftsRequest} patchSlotInstanceShiftsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchSlotInstanceShifts: async (id: number, patchSlotInstanceShiftsRequest: PatchSlotInstanceShiftsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('patchSlotInstanceShifts', 'id', id)
            // verify required parameter 'patchSlotInstanceShiftsRequest' is not null or undefined
            assertParamExists('patchSlotInstanceShifts', 'patchSlotInstanceShiftsRequest', patchSlotInstanceShiftsRequest)
            const localVarPath = `/slotinstance/{id}/shifts`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchSlotInstanceShiftsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Tento endpoint umožňuje vytvořit nebo aktualizovat instanci slotu.
         * @summary Uložit instanci slotu
         * @param {SaveSlotInstanceRequest} saveSlotInstanceRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveSlotInstance: async (saveSlotInstanceRequest: SaveSlotInstanceRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'saveSlotInstanceRequest' is not null or undefined
            assertParamExists('saveSlotInstance', 'saveSlotInstanceRequest', saveSlotInstanceRequest)
            const localVarPath = `/slotinstance`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(saveSlotInstanceRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SlotinstanceApi - functional programming interface
 * @export
 */
export const SlotinstanceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SlotinstanceApiAxiosParamCreator(configuration)
    return {
        /**
         * Tento endpoint umožňuje klonovat směny.
         * @summary Naklonuje Směny
         * @param {string} source Datum zdrojové směny, kterou chcete zduplikovat.
         * @param {string} target Datum cílové směny, kam chcete zduplikovat zdrojovou směnu.
         * @param {CloneShiftsRequest} cloneShiftsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cloneShifts(source: string, target: string, cloneShiftsRequest: CloneShiftsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cloneShifts(source, target, cloneShiftsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Tento endpoint umožňuje odstranit instanci slotu.
         * @summary Odstraní instanci slotu
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteSlotInstance(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteSlotInstance(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Tento endpoint umožňuje získat informace o instanci slotu.
         * @summary Získá instanci slotu
         * @param {number} id ID instance slotu
         * @param {number} page 
         * @param {number} pageSize 
         * @param {boolean} isDescendingSort 
         * @param {string} [sortPropertyName] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSlotInstance(id: number, page: number, pageSize: number, isDescendingSort: boolean, sortPropertyName?: string, search?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SlotInstanceModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSlotInstance(id, page, pageSize, isDescendingSort, sortPropertyName, search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Tento endpoint umožňuje získat seznam slotových instancí.
         * @summary Získá seznam slotových instancí
         * @param {string} start Počáteční datum pro vyhledávání slotových instancí.
         * @param {string} end Konečné datum pro vyhledávání slotových instancí.
         * @param {number} page 
         * @param {number} pageSize 
         * @param {boolean} isDescendingSort 
         * @param {string} [sortPropertyName] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSlotInstances(start: string, end: string, page: number, pageSize: number, isDescendingSort: boolean, sortPropertyName?: string, search?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SlotInstanceModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSlotInstances(start, end, page, pageSize, isDescendingSort, sortPropertyName, search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Tento endpoint umožňuje získat plánování instancí slotu.
         * @summary Vrátí plánování instancí slotu
         * @param {string} start Počáteční datum pro vyhledávání plánování instancí slotu.
         * @param {string} end Konečné datum pro vyhledávání plánování instancí slotu.
         * @param {number} page 
         * @param {number} pageSize 
         * @param {boolean} isDescendingSort 
         * @param {string} [sortPropertyName] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSlotInstancesPlanning(start: string, end: string, page: number, pageSize: number, isDescendingSort: boolean, sortPropertyName?: string, search?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SlotInstancePlanningModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSlotInstancesPlanning(start, end, page, pageSize, isDescendingSort, sortPropertyName, search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Tento endpoint umožňuje aktualizovat stav aktivity slotové instance.
         * @summary Aktualizuje stav aktivity slotové instance
         * @param {number} id ID instance slotu, kterou chcete aktualizovat.
         * @param {PatchIsActiveRequest4} patchIsActiveRequest4 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchSlotInstanceIsActive(id: number, patchIsActiveRequest4: PatchIsActiveRequest4, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchSlotInstanceIsActive(id, patchIsActiveRequest4, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Tento endpoint umožňuje aktualizovat směny pro konkrétní slotovou instanci.
         * @summary Aktualizace směn v rámci slotové instance
         * @param {number} id ID slotové instance.
         * @param {PatchSlotInstanceShiftsRequest} patchSlotInstanceShiftsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchSlotInstanceShifts(id: number, patchSlotInstanceShiftsRequest: PatchSlotInstanceShiftsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchSlotInstanceShifts(id, patchSlotInstanceShiftsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Tento endpoint umožňuje vytvořit nebo aktualizovat instanci slotu.
         * @summary Uložit instanci slotu
         * @param {SaveSlotInstanceRequest} saveSlotInstanceRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveSlotInstance(saveSlotInstanceRequest: SaveSlotInstanceRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SaveSlotInstanceResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveSlotInstance(saveSlotInstanceRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SlotinstanceApi - factory interface
 * @export
 */
export const SlotinstanceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SlotinstanceApiFp(configuration)
    return {
        /**
         * Tento endpoint umožňuje klonovat směny.
         * @summary Naklonuje Směny
         * @param {string} source Datum zdrojové směny, kterou chcete zduplikovat.
         * @param {string} target Datum cílové směny, kam chcete zduplikovat zdrojovou směnu.
         * @param {CloneShiftsRequest} cloneShiftsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cloneShifts(source: string, target: string, cloneShiftsRequest: CloneShiftsRequest, options?: any): AxiosPromise<void> {
            return localVarFp.cloneShifts(source, target, cloneShiftsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Tento endpoint umožňuje odstranit instanci slotu.
         * @summary Odstraní instanci slotu
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSlotInstance(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteSlotInstance(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Tento endpoint umožňuje získat informace o instanci slotu.
         * @summary Získá instanci slotu
         * @param {number} id ID instance slotu
         * @param {number} page 
         * @param {number} pageSize 
         * @param {boolean} isDescendingSort 
         * @param {string} [sortPropertyName] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSlotInstance(id: number, page: number, pageSize: number, isDescendingSort: boolean, sortPropertyName?: string, search?: string, options?: any): AxiosPromise<SlotInstanceModel> {
            return localVarFp.getSlotInstance(id, page, pageSize, isDescendingSort, sortPropertyName, search, options).then((request) => request(axios, basePath));
        },
        /**
         * Tento endpoint umožňuje získat seznam slotových instancí.
         * @summary Získá seznam slotových instancí
         * @param {string} start Počáteční datum pro vyhledávání slotových instancí.
         * @param {string} end Konečné datum pro vyhledávání slotových instancí.
         * @param {number} page 
         * @param {number} pageSize 
         * @param {boolean} isDescendingSort 
         * @param {string} [sortPropertyName] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSlotInstances(start: string, end: string, page: number, pageSize: number, isDescendingSort: boolean, sortPropertyName?: string, search?: string, options?: any): AxiosPromise<SlotInstanceModel> {
            return localVarFp.getSlotInstances(start, end, page, pageSize, isDescendingSort, sortPropertyName, search, options).then((request) => request(axios, basePath));
        },
        /**
         * Tento endpoint umožňuje získat plánování instancí slotu.
         * @summary Vrátí plánování instancí slotu
         * @param {string} start Počáteční datum pro vyhledávání plánování instancí slotu.
         * @param {string} end Konečné datum pro vyhledávání plánování instancí slotu.
         * @param {number} page 
         * @param {number} pageSize 
         * @param {boolean} isDescendingSort 
         * @param {string} [sortPropertyName] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSlotInstancesPlanning(start: string, end: string, page: number, pageSize: number, isDescendingSort: boolean, sortPropertyName?: string, search?: string, options?: any): AxiosPromise<SlotInstancePlanningModel> {
            return localVarFp.getSlotInstancesPlanning(start, end, page, pageSize, isDescendingSort, sortPropertyName, search, options).then((request) => request(axios, basePath));
        },
        /**
         * Tento endpoint umožňuje aktualizovat stav aktivity slotové instance.
         * @summary Aktualizuje stav aktivity slotové instance
         * @param {number} id ID instance slotu, kterou chcete aktualizovat.
         * @param {PatchIsActiveRequest4} patchIsActiveRequest4 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchSlotInstanceIsActive(id: number, patchIsActiveRequest4: PatchIsActiveRequest4, options?: any): AxiosPromise<void> {
            return localVarFp.patchSlotInstanceIsActive(id, patchIsActiveRequest4, options).then((request) => request(axios, basePath));
        },
        /**
         * Tento endpoint umožňuje aktualizovat směny pro konkrétní slotovou instanci.
         * @summary Aktualizace směn v rámci slotové instance
         * @param {number} id ID slotové instance.
         * @param {PatchSlotInstanceShiftsRequest} patchSlotInstanceShiftsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchSlotInstanceShifts(id: number, patchSlotInstanceShiftsRequest: PatchSlotInstanceShiftsRequest, options?: any): AxiosPromise<void> {
            return localVarFp.patchSlotInstanceShifts(id, patchSlotInstanceShiftsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Tento endpoint umožňuje vytvořit nebo aktualizovat instanci slotu.
         * @summary Uložit instanci slotu
         * @param {SaveSlotInstanceRequest} saveSlotInstanceRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveSlotInstance(saveSlotInstanceRequest: SaveSlotInstanceRequest, options?: any): AxiosPromise<SaveSlotInstanceResponse> {
            return localVarFp.saveSlotInstance(saveSlotInstanceRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SlotinstanceApi - object-oriented interface
 * @export
 * @class SlotinstanceApi
 * @extends {BaseAPI}
 */
export class SlotinstanceApi extends BaseAPI {
    /**
     * Tento endpoint umožňuje klonovat směny.
     * @summary Naklonuje Směny
     * @param {string} source Datum zdrojové směny, kterou chcete zduplikovat.
     * @param {string} target Datum cílové směny, kam chcete zduplikovat zdrojovou směnu.
     * @param {CloneShiftsRequest} cloneShiftsRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SlotinstanceApi
     */
    public cloneShifts(source: string, target: string, cloneShiftsRequest: CloneShiftsRequest, options?: AxiosRequestConfig) {
        return SlotinstanceApiFp(this.configuration).cloneShifts(source, target, cloneShiftsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Tento endpoint umožňuje odstranit instanci slotu.
     * @summary Odstraní instanci slotu
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SlotinstanceApi
     */
    public deleteSlotInstance(id: number, options?: AxiosRequestConfig) {
        return SlotinstanceApiFp(this.configuration).deleteSlotInstance(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Tento endpoint umožňuje získat informace o instanci slotu.
     * @summary Získá instanci slotu
     * @param {number} id ID instance slotu
     * @param {number} page 
     * @param {number} pageSize 
     * @param {boolean} isDescendingSort 
     * @param {string} [sortPropertyName] 
     * @param {string} [search] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SlotinstanceApi
     */
    public getSlotInstance(id: number, page: number, pageSize: number, isDescendingSort: boolean, sortPropertyName?: string, search?: string, options?: AxiosRequestConfig) {
        return SlotinstanceApiFp(this.configuration).getSlotInstance(id, page, pageSize, isDescendingSort, sortPropertyName, search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Tento endpoint umožňuje získat seznam slotových instancí.
     * @summary Získá seznam slotových instancí
     * @param {string} start Počáteční datum pro vyhledávání slotových instancí.
     * @param {string} end Konečné datum pro vyhledávání slotových instancí.
     * @param {number} page 
     * @param {number} pageSize 
     * @param {boolean} isDescendingSort 
     * @param {string} [sortPropertyName] 
     * @param {string} [search] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SlotinstanceApi
     */
    public getSlotInstances(start: string, end: string, page: number, pageSize: number, isDescendingSort: boolean, sortPropertyName?: string, search?: string, options?: AxiosRequestConfig) {
        return SlotinstanceApiFp(this.configuration).getSlotInstances(start, end, page, pageSize, isDescendingSort, sortPropertyName, search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Tento endpoint umožňuje získat plánování instancí slotu.
     * @summary Vrátí plánování instancí slotu
     * @param {string} start Počáteční datum pro vyhledávání plánování instancí slotu.
     * @param {string} end Konečné datum pro vyhledávání plánování instancí slotu.
     * @param {number} page 
     * @param {number} pageSize 
     * @param {boolean} isDescendingSort 
     * @param {string} [sortPropertyName] 
     * @param {string} [search] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SlotinstanceApi
     */
    public getSlotInstancesPlanning(start: string, end: string, page: number, pageSize: number, isDescendingSort: boolean, sortPropertyName?: string, search?: string, options?: AxiosRequestConfig) {
        return SlotinstanceApiFp(this.configuration).getSlotInstancesPlanning(start, end, page, pageSize, isDescendingSort, sortPropertyName, search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Tento endpoint umožňuje aktualizovat stav aktivity slotové instance.
     * @summary Aktualizuje stav aktivity slotové instance
     * @param {number} id ID instance slotu, kterou chcete aktualizovat.
     * @param {PatchIsActiveRequest4} patchIsActiveRequest4 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SlotinstanceApi
     */
    public patchSlotInstanceIsActive(id: number, patchIsActiveRequest4: PatchIsActiveRequest4, options?: AxiosRequestConfig) {
        return SlotinstanceApiFp(this.configuration).patchSlotInstanceIsActive(id, patchIsActiveRequest4, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Tento endpoint umožňuje aktualizovat směny pro konkrétní slotovou instanci.
     * @summary Aktualizace směn v rámci slotové instance
     * @param {number} id ID slotové instance.
     * @param {PatchSlotInstanceShiftsRequest} patchSlotInstanceShiftsRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SlotinstanceApi
     */
    public patchSlotInstanceShifts(id: number, patchSlotInstanceShiftsRequest: PatchSlotInstanceShiftsRequest, options?: AxiosRequestConfig) {
        return SlotinstanceApiFp(this.configuration).patchSlotInstanceShifts(id, patchSlotInstanceShiftsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Tento endpoint umožňuje vytvořit nebo aktualizovat instanci slotu.
     * @summary Uložit instanci slotu
     * @param {SaveSlotInstanceRequest} saveSlotInstanceRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SlotinstanceApi
     */
    public saveSlotInstance(saveSlotInstanceRequest: SaveSlotInstanceRequest, options?: AxiosRequestConfig) {
        return SlotinstanceApiFp(this.configuration).saveSlotInstance(saveSlotInstanceRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SlotinstancenoteApi - axios parameter creator
 * @export
 */
export const SlotinstancenoteApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Uloží poznámky ke všem instancím slotu v daném časovém rozmezí
         * @param {CreateMultipleSlotInstanceNotesRequest} createMultipleSlotInstanceNotesRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMultipleSlotInstanceNotes: async (createMultipleSlotInstanceNotesRequest: CreateMultipleSlotInstanceNotesRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createMultipleSlotInstanceNotesRequest' is not null or undefined
            assertParamExists('createMultipleSlotInstanceNotes', 'createMultipleSlotInstanceNotesRequest', createMultipleSlotInstanceNotesRequest)
            const localVarPath = `/slotinstancenote/multiple`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createMultipleSlotInstanceNotesRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Tento endpoint umožňuje smazat poznámku instance slotu.
         * @summary Smazat poznámku instance slotu
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSlotInstanceNote: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteSlotInstanceNote', 'id', id)
            const localVarPath = `/slotinstancenote/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Tento endpoint umožňuje uložit novou poznámku k instanci slotu.
         * @summary Uložit poznámku k instanci slotu
         * @param {SlotInstanceNoteModel} slotInstanceNoteModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveSlotInstanceNote: async (slotInstanceNoteModel: SlotInstanceNoteModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'slotInstanceNoteModel' is not null or undefined
            assertParamExists('saveSlotInstanceNote', 'slotInstanceNoteModel', slotInstanceNoteModel)
            const localVarPath = `/slotinstancenote`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(slotInstanceNoteModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SlotinstancenoteApi - functional programming interface
 * @export
 */
export const SlotinstancenoteApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SlotinstancenoteApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Uloží poznámky ke všem instancím slotu v daném časovém rozmezí
         * @param {CreateMultipleSlotInstanceNotesRequest} createMultipleSlotInstanceNotesRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createMultipleSlotInstanceNotes(createMultipleSlotInstanceNotesRequest: CreateMultipleSlotInstanceNotesRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createMultipleSlotInstanceNotes(createMultipleSlotInstanceNotesRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Tento endpoint umožňuje smazat poznámku instance slotu.
         * @summary Smazat poznámku instance slotu
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteSlotInstanceNote(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteSlotInstanceNote(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Tento endpoint umožňuje uložit novou poznámku k instanci slotu.
         * @summary Uložit poznámku k instanci slotu
         * @param {SlotInstanceNoteModel} slotInstanceNoteModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveSlotInstanceNote(slotInstanceNoteModel: SlotInstanceNoteModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SaveSlotInstanceNoteResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveSlotInstanceNote(slotInstanceNoteModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SlotinstancenoteApi - factory interface
 * @export
 */
export const SlotinstancenoteApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SlotinstancenoteApiFp(configuration)
    return {
        /**
         * 
         * @summary Uloží poznámky ke všem instancím slotu v daném časovém rozmezí
         * @param {CreateMultipleSlotInstanceNotesRequest} createMultipleSlotInstanceNotesRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMultipleSlotInstanceNotes(createMultipleSlotInstanceNotesRequest: CreateMultipleSlotInstanceNotesRequest, options?: any): AxiosPromise<void> {
            return localVarFp.createMultipleSlotInstanceNotes(createMultipleSlotInstanceNotesRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Tento endpoint umožňuje smazat poznámku instance slotu.
         * @summary Smazat poznámku instance slotu
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSlotInstanceNote(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteSlotInstanceNote(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Tento endpoint umožňuje uložit novou poznámku k instanci slotu.
         * @summary Uložit poznámku k instanci slotu
         * @param {SlotInstanceNoteModel} slotInstanceNoteModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveSlotInstanceNote(slotInstanceNoteModel: SlotInstanceNoteModel, options?: any): AxiosPromise<SaveSlotInstanceNoteResponse> {
            return localVarFp.saveSlotInstanceNote(slotInstanceNoteModel, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SlotinstancenoteApi - object-oriented interface
 * @export
 * @class SlotinstancenoteApi
 * @extends {BaseAPI}
 */
export class SlotinstancenoteApi extends BaseAPI {
    /**
     * 
     * @summary Uloží poznámky ke všem instancím slotu v daném časovém rozmezí
     * @param {CreateMultipleSlotInstanceNotesRequest} createMultipleSlotInstanceNotesRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SlotinstancenoteApi
     */
    public createMultipleSlotInstanceNotes(createMultipleSlotInstanceNotesRequest: CreateMultipleSlotInstanceNotesRequest, options?: AxiosRequestConfig) {
        return SlotinstancenoteApiFp(this.configuration).createMultipleSlotInstanceNotes(createMultipleSlotInstanceNotesRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Tento endpoint umožňuje smazat poznámku instance slotu.
     * @summary Smazat poznámku instance slotu
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SlotinstancenoteApi
     */
    public deleteSlotInstanceNote(id: number, options?: AxiosRequestConfig) {
        return SlotinstancenoteApiFp(this.configuration).deleteSlotInstanceNote(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Tento endpoint umožňuje uložit novou poznámku k instanci slotu.
     * @summary Uložit poznámku k instanci slotu
     * @param {SlotInstanceNoteModel} slotInstanceNoteModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SlotinstancenoteApi
     */
    public saveSlotInstanceNote(slotInstanceNoteModel: SlotInstanceNoteModel, options?: AxiosRequestConfig) {
        return SlotinstancenoteApiFp(this.configuration).saveSlotInstanceNote(slotInstanceNoteModel, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UserApi - axios parameter creator
 * @export
 */
export const UserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Tento endpoint umožňuje uživateli změnit své heslo.
         * @summary Změna hesla
         * @param {ChangePasswordRequest} changePasswordRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changePassword: async (changePasswordRequest: ChangePasswordRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'changePasswordRequest' is not null or undefined
            assertParamExists('changePassword', 'changePasswordRequest', changePasswordRequest)
            const localVarPath = `/user/changePassword`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(changePasswordRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Tento endpoint uloží nově vytvořené heslo
         * @summary Uloží heslo
         * @param {CreatePasswordRequest} createPasswordRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPassword: async (createPasswordRequest: CreatePasswordRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createPasswordRequest' is not null or undefined
            assertParamExists('createPassword', 'createPasswordRequest', createPasswordRequest)
            const localVarPath = `/user/password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createPasswordRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Tento endpoint umožňuje uživateli obnovit zapomenuté heslo
         * @summary Zapomenuté heslo
         * @param {ForgotPasswordRequest} forgotPasswordRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forgotPassword: async (forgotPasswordRequest: ForgotPasswordRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forgotPasswordRequest' is not null or undefined
            assertParamExists('forgotPassword', 'forgotPasswordRequest', forgotPasswordRequest)
            const localVarPath = `/user/forgotpassword`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(forgotPasswordRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Tento endpoint umožňuje získat informace o uživateli.
         * @summary Vrátí data o uživateli
         * @param {number} id ID uživatele
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUser: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getUser', 'id', id)
            const localVarPath = `/user/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Tento endpoint umožňuje získat seznam uživatelů.
         * @summary Získat uživatele
         * @param {boolean} isActive Je uživatel aktivní
         * @param {number} page 
         * @param {number} pageSize 
         * @param {boolean} isDescendingSort 
         * @param {string} [sortPropertyName] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsers: async (isActive: boolean, page: number, pageSize: number, isDescendingSort: boolean, sortPropertyName?: string, search?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'isActive' is not null or undefined
            assertParamExists('getUsers', 'isActive', isActive)
            // verify required parameter 'page' is not null or undefined
            assertParamExists('getUsers', 'page', page)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('getUsers', 'pageSize', pageSize)
            // verify required parameter 'isDescendingSort' is not null or undefined
            assertParamExists('getUsers', 'isDescendingSort', isDescendingSort)
            const localVarPath = `/user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (isActive !== undefined) {
                localVarQueryParameter['IsActive'] = isActive;
            }

            if (page !== undefined) {
                localVarQueryParameter['Page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (sortPropertyName !== undefined) {
                localVarQueryParameter['SortPropertyName'] = sortPropertyName;
            }

            if (isDescendingSort !== undefined) {
                localVarQueryParameter['IsDescendingSort'] = isDescendingSort;
            }

            if (search !== undefined) {
                localVarQueryParameter['Search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Tento endpoint umožňuje uživateli se přihlásit.
         * @summary Přihlásit se
         * @param {LoginRequest} loginRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login: async (loginRequest: LoginRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'loginRequest' is not null or undefined
            assertParamExists('login', 'loginRequest', loginRequest)
            const localVarPath = `/user/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(loginRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Tento endpoint umožňuje změnit profilový obrázek uživatele.
         * @summary Změnit profilový obrázek uživatele
         * @param {number} id ID uživatele
         * @param {PatchUserAvatarImageRequest} patchUserAvatarImageRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchUserAvatarImage: async (id: number, patchUserAvatarImageRequest: PatchUserAvatarImageRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('patchUserAvatarImage', 'id', id)
            // verify required parameter 'patchUserAvatarImageRequest' is not null or undefined
            assertParamExists('patchUserAvatarImage', 'patchUserAvatarImageRequest', patchUserAvatarImageRequest)
            const localVarPath = `/user/{id}/avatarimage`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchUserAvatarImageRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Tento endpoint umožňuje změnit aktivní stav uživatele.
         * @summary Změnit aktivní stav uživatele
         * @param {number} id ID uživatele
         * @param {PatchIsActiveRequest2} patchIsActiveRequest2 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchUserIsActive: async (id: number, patchIsActiveRequest2: PatchIsActiveRequest2, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('patchUserIsActive', 'id', id)
            // verify required parameter 'patchIsActiveRequest2' is not null or undefined
            assertParamExists('patchUserIsActive', 'patchIsActiveRequest2', patchIsActiveRequest2)
            const localVarPath = `/user/{id}/isactive`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchIsActiveRequest2, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Tento endpoint umožňí obnovení tokenu
         * @summary Obnoví token
         * @param {RefreshTokenRequest} refreshTokenRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshToken: async (refreshTokenRequest: RefreshTokenRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'refreshTokenRequest' is not null or undefined
            assertParamExists('refreshToken', 'refreshTokenRequest', refreshTokenRequest)
            const localVarPath = `/user/token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(refreshTokenRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Tento endpoint uloží informace o novém uživateli 
         * @summary Uloží nového uživatele
         * @param {SaveUserRequest} saveUserRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveUser: async (saveUserRequest: SaveUserRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'saveUserRequest' is not null or undefined
            assertParamExists('saveUser', 'saveUserRequest', saveUserRequest)
            const localVarPath = `/user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(saveUserRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserApiAxiosParamCreator(configuration)
    return {
        /**
         * Tento endpoint umožňuje uživateli změnit své heslo.
         * @summary Změna hesla
         * @param {ChangePasswordRequest} changePasswordRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async changePassword(changePasswordRequest: ChangePasswordRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.changePassword(changePasswordRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Tento endpoint uloží nově vytvořené heslo
         * @summary Uloží heslo
         * @param {CreatePasswordRequest} createPasswordRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createPassword(createPasswordRequest: CreatePasswordRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createPassword(createPasswordRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Tento endpoint umožňuje uživateli obnovit zapomenuté heslo
         * @summary Zapomenuté heslo
         * @param {ForgotPasswordRequest} forgotPasswordRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forgotPassword(forgotPasswordRequest: ForgotPasswordRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forgotPassword(forgotPasswordRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Tento endpoint umožňuje získat informace o uživateli.
         * @summary Vrátí data o uživateli
         * @param {number} id ID uživatele
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUser(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetUserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUser(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Tento endpoint umožňuje získat seznam uživatelů.
         * @summary Získat uživatele
         * @param {boolean} isActive Je uživatel aktivní
         * @param {number} page 
         * @param {number} pageSize 
         * @param {boolean} isDescendingSort 
         * @param {string} [sortPropertyName] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUsers(isActive: boolean, page: number, pageSize: number, isDescendingSort: boolean, sortPropertyName?: string, search?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetUsersResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUsers(isActive, page, pageSize, isDescendingSort, sortPropertyName, search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Tento endpoint umožňuje uživateli se přihlásit.
         * @summary Přihlásit se
         * @param {LoginRequest} loginRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async login(loginRequest: LoginRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoginResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.login(loginRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Tento endpoint umožňuje změnit profilový obrázek uživatele.
         * @summary Změnit profilový obrázek uživatele
         * @param {number} id ID uživatele
         * @param {PatchUserAvatarImageRequest} patchUserAvatarImageRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchUserAvatarImage(id: number, patchUserAvatarImageRequest: PatchUserAvatarImageRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchUserAvatarImage(id, patchUserAvatarImageRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Tento endpoint umožňuje změnit aktivní stav uživatele.
         * @summary Změnit aktivní stav uživatele
         * @param {number} id ID uživatele
         * @param {PatchIsActiveRequest2} patchIsActiveRequest2 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchUserIsActive(id: number, patchIsActiveRequest2: PatchIsActiveRequest2, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchUserIsActive(id, patchIsActiveRequest2, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Tento endpoint umožňí obnovení tokenu
         * @summary Obnoví token
         * @param {RefreshTokenRequest} refreshTokenRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async refreshToken(refreshTokenRequest: RefreshTokenRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RefreshTokenResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.refreshToken(refreshTokenRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Tento endpoint uloží informace o novém uživateli 
         * @summary Uloží nového uživatele
         * @param {SaveUserRequest} saveUserRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveUser(saveUserRequest: SaveUserRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SaveUserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveUser(saveUserRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserApiFp(configuration)
    return {
        /**
         * Tento endpoint umožňuje uživateli změnit své heslo.
         * @summary Změna hesla
         * @param {ChangePasswordRequest} changePasswordRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changePassword(changePasswordRequest: ChangePasswordRequest, options?: any): AxiosPromise<void> {
            return localVarFp.changePassword(changePasswordRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Tento endpoint uloží nově vytvořené heslo
         * @summary Uloží heslo
         * @param {CreatePasswordRequest} createPasswordRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPassword(createPasswordRequest: CreatePasswordRequest, options?: any): AxiosPromise<void> {
            return localVarFp.createPassword(createPasswordRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Tento endpoint umožňuje uživateli obnovit zapomenuté heslo
         * @summary Zapomenuté heslo
         * @param {ForgotPasswordRequest} forgotPasswordRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forgotPassword(forgotPasswordRequest: ForgotPasswordRequest, options?: any): AxiosPromise<void> {
            return localVarFp.forgotPassword(forgotPasswordRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Tento endpoint umožňuje získat informace o uživateli.
         * @summary Vrátí data o uživateli
         * @param {number} id ID uživatele
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUser(id: number, options?: any): AxiosPromise<GetUserResponse> {
            return localVarFp.getUser(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Tento endpoint umožňuje získat seznam uživatelů.
         * @summary Získat uživatele
         * @param {boolean} isActive Je uživatel aktivní
         * @param {number} page 
         * @param {number} pageSize 
         * @param {boolean} isDescendingSort 
         * @param {string} [sortPropertyName] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsers(isActive: boolean, page: number, pageSize: number, isDescendingSort: boolean, sortPropertyName?: string, search?: string, options?: any): AxiosPromise<GetUsersResponse> {
            return localVarFp.getUsers(isActive, page, pageSize, isDescendingSort, sortPropertyName, search, options).then((request) => request(axios, basePath));
        },
        /**
         * Tento endpoint umožňuje uživateli se přihlásit.
         * @summary Přihlásit se
         * @param {LoginRequest} loginRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login(loginRequest: LoginRequest, options?: any): AxiosPromise<LoginResponse> {
            return localVarFp.login(loginRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Tento endpoint umožňuje změnit profilový obrázek uživatele.
         * @summary Změnit profilový obrázek uživatele
         * @param {number} id ID uživatele
         * @param {PatchUserAvatarImageRequest} patchUserAvatarImageRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchUserAvatarImage(id: number, patchUserAvatarImageRequest: PatchUserAvatarImageRequest, options?: any): AxiosPromise<void> {
            return localVarFp.patchUserAvatarImage(id, patchUserAvatarImageRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Tento endpoint umožňuje změnit aktivní stav uživatele.
         * @summary Změnit aktivní stav uživatele
         * @param {number} id ID uživatele
         * @param {PatchIsActiveRequest2} patchIsActiveRequest2 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchUserIsActive(id: number, patchIsActiveRequest2: PatchIsActiveRequest2, options?: any): AxiosPromise<void> {
            return localVarFp.patchUserIsActive(id, patchIsActiveRequest2, options).then((request) => request(axios, basePath));
        },
        /**
         * Tento endpoint umožňí obnovení tokenu
         * @summary Obnoví token
         * @param {RefreshTokenRequest} refreshTokenRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshToken(refreshTokenRequest: RefreshTokenRequest, options?: any): AxiosPromise<RefreshTokenResponse> {
            return localVarFp.refreshToken(refreshTokenRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Tento endpoint uloží informace o novém uživateli 
         * @summary Uloží nového uživatele
         * @param {SaveUserRequest} saveUserRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveUser(saveUserRequest: SaveUserRequest, options?: any): AxiosPromise<SaveUserResponse> {
            return localVarFp.saveUser(saveUserRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI {
    /**
     * Tento endpoint umožňuje uživateli změnit své heslo.
     * @summary Změna hesla
     * @param {ChangePasswordRequest} changePasswordRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public changePassword(changePasswordRequest: ChangePasswordRequest, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).changePassword(changePasswordRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Tento endpoint uloží nově vytvořené heslo
     * @summary Uloží heslo
     * @param {CreatePasswordRequest} createPasswordRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public createPassword(createPasswordRequest: CreatePasswordRequest, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).createPassword(createPasswordRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Tento endpoint umožňuje uživateli obnovit zapomenuté heslo
     * @summary Zapomenuté heslo
     * @param {ForgotPasswordRequest} forgotPasswordRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public forgotPassword(forgotPasswordRequest: ForgotPasswordRequest, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).forgotPassword(forgotPasswordRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Tento endpoint umožňuje získat informace o uživateli.
     * @summary Vrátí data o uživateli
     * @param {number} id ID uživatele
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getUser(id: number, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).getUser(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Tento endpoint umožňuje získat seznam uživatelů.
     * @summary Získat uživatele
     * @param {boolean} isActive Je uživatel aktivní
     * @param {number} page 
     * @param {number} pageSize 
     * @param {boolean} isDescendingSort 
     * @param {string} [sortPropertyName] 
     * @param {string} [search] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getUsers(isActive: boolean, page: number, pageSize: number, isDescendingSort: boolean, sortPropertyName?: string, search?: string, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).getUsers(isActive, page, pageSize, isDescendingSort, sortPropertyName, search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Tento endpoint umožňuje uživateli se přihlásit.
     * @summary Přihlásit se
     * @param {LoginRequest} loginRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public login(loginRequest: LoginRequest, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).login(loginRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Tento endpoint umožňuje změnit profilový obrázek uživatele.
     * @summary Změnit profilový obrázek uživatele
     * @param {number} id ID uživatele
     * @param {PatchUserAvatarImageRequest} patchUserAvatarImageRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public patchUserAvatarImage(id: number, patchUserAvatarImageRequest: PatchUserAvatarImageRequest, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).patchUserAvatarImage(id, patchUserAvatarImageRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Tento endpoint umožňuje změnit aktivní stav uživatele.
     * @summary Změnit aktivní stav uživatele
     * @param {number} id ID uživatele
     * @param {PatchIsActiveRequest2} patchIsActiveRequest2 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public patchUserIsActive(id: number, patchIsActiveRequest2: PatchIsActiveRequest2, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).patchUserIsActive(id, patchIsActiveRequest2, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Tento endpoint umožňí obnovení tokenu
     * @summary Obnoví token
     * @param {RefreshTokenRequest} refreshTokenRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public refreshToken(refreshTokenRequest: RefreshTokenRequest, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).refreshToken(refreshTokenRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Tento endpoint uloží informace o novém uživateli 
     * @summary Uloží nového uživatele
     * @param {SaveUserRequest} saveUserRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public saveUser(saveUserRequest: SaveUserRequest, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).saveUser(saveUserRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * VehicleApi - axios parameter creator
 * @export
 */
export const VehicleApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Tento endpoint umožňuje smazat vozidlo.
         * @summary Smazat vozidlo
         * @param {number} id Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteVehicle: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteVehicle', 'id', id)
            const localVarPath = `/vehicle/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Tento endpoint umožňuje získat informace o konkrétním vozidle.
         * @summary Vrátí informace o vozidle
         * @param {number} id Unikátní identifikátor vozidla.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVehicle: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getVehicle', 'id', id)
            const localVarPath = `/vehicle/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Tento endpoint vrátí seznam vozidel.
         * @summary Vrátí seznam vozidel
         * @param {boolean} isActive Příznak označující, zda je vozidlo aktivní nebo ne.
         * @param {number} page 
         * @param {number} pageSize 
         * @param {boolean} isDescendingSort 
         * @param {string} [sortPropertyName] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVehicles: async (isActive: boolean, page: number, pageSize: number, isDescendingSort: boolean, sortPropertyName?: string, search?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'isActive' is not null or undefined
            assertParamExists('getVehicles', 'isActive', isActive)
            // verify required parameter 'page' is not null or undefined
            assertParamExists('getVehicles', 'page', page)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('getVehicles', 'pageSize', pageSize)
            // verify required parameter 'isDescendingSort' is not null or undefined
            assertParamExists('getVehicles', 'isDescendingSort', isDescendingSort)
            const localVarPath = `/vehicle`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (isActive !== undefined) {
                localVarQueryParameter['IsActive'] = isActive;
            }

            if (page !== undefined) {
                localVarQueryParameter['Page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (sortPropertyName !== undefined) {
                localVarQueryParameter['SortPropertyName'] = sortPropertyName;
            }

            if (isDescendingSort !== undefined) {
                localVarQueryParameter['IsDescendingSort'] = isDescendingSort;
            }

            if (search !== undefined) {
                localVarQueryParameter['Search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Tento endpoint umožňuje aktualizaci stavu vozidla na aktivní nebo neaktivní.
         * @summary Aktualizuje stav vozidla
         * @param {number} id 
         * @param {PatchIsActiveRequest} patchIsActiveRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchVehicleIsActive: async (id: number, patchIsActiveRequest: PatchIsActiveRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('patchVehicleIsActive', 'id', id)
            // verify required parameter 'patchIsActiveRequest' is not null or undefined
            assertParamExists('patchVehicleIsActive', 'patchIsActiveRequest', patchIsActiveRequest)
            const localVarPath = `/vehicle/{id}/isactive`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchIsActiveRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Tento endpoint umožňuje uložit nové nebo aktualizovat existující vozidlo.
         * @summary Uložit vozidlo
         * @param {SaveVehicleRequest} saveVehicleRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveVehicle: async (saveVehicleRequest: SaveVehicleRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'saveVehicleRequest' is not null or undefined
            assertParamExists('saveVehicle', 'saveVehicleRequest', saveVehicleRequest)
            const localVarPath = `/vehicle`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(saveVehicleRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * VehicleApi - functional programming interface
 * @export
 */
export const VehicleApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = VehicleApiAxiosParamCreator(configuration)
    return {
        /**
         * Tento endpoint umožňuje smazat vozidlo.
         * @summary Smazat vozidlo
         * @param {number} id Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteVehicle(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteVehicle(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Tento endpoint umožňuje získat informace o konkrétním vozidle.
         * @summary Vrátí informace o vozidle
         * @param {number} id Unikátní identifikátor vozidla.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getVehicle(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetVehicleResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getVehicle(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Tento endpoint vrátí seznam vozidel.
         * @summary Vrátí seznam vozidel
         * @param {boolean} isActive Příznak označující, zda je vozidlo aktivní nebo ne.
         * @param {number} page 
         * @param {number} pageSize 
         * @param {boolean} isDescendingSort 
         * @param {string} [sortPropertyName] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getVehicles(isActive: boolean, page: number, pageSize: number, isDescendingSort: boolean, sortPropertyName?: string, search?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetVehiclesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getVehicles(isActive, page, pageSize, isDescendingSort, sortPropertyName, search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Tento endpoint umožňuje aktualizaci stavu vozidla na aktivní nebo neaktivní.
         * @summary Aktualizuje stav vozidla
         * @param {number} id 
         * @param {PatchIsActiveRequest} patchIsActiveRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchVehicleIsActive(id: number, patchIsActiveRequest: PatchIsActiveRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchVehicleIsActive(id, patchIsActiveRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Tento endpoint umožňuje uložit nové nebo aktualizovat existující vozidlo.
         * @summary Uložit vozidlo
         * @param {SaveVehicleRequest} saveVehicleRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveVehicle(saveVehicleRequest: SaveVehicleRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SaveVehicleResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveVehicle(saveVehicleRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * VehicleApi - factory interface
 * @export
 */
export const VehicleApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = VehicleApiFp(configuration)
    return {
        /**
         * Tento endpoint umožňuje smazat vozidlo.
         * @summary Smazat vozidlo
         * @param {number} id Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteVehicle(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteVehicle(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Tento endpoint umožňuje získat informace o konkrétním vozidle.
         * @summary Vrátí informace o vozidle
         * @param {number} id Unikátní identifikátor vozidla.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVehicle(id: number, options?: any): AxiosPromise<GetVehicleResponse> {
            return localVarFp.getVehicle(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Tento endpoint vrátí seznam vozidel.
         * @summary Vrátí seznam vozidel
         * @param {boolean} isActive Příznak označující, zda je vozidlo aktivní nebo ne.
         * @param {number} page 
         * @param {number} pageSize 
         * @param {boolean} isDescendingSort 
         * @param {string} [sortPropertyName] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVehicles(isActive: boolean, page: number, pageSize: number, isDescendingSort: boolean, sortPropertyName?: string, search?: string, options?: any): AxiosPromise<GetVehiclesResponse> {
            return localVarFp.getVehicles(isActive, page, pageSize, isDescendingSort, sortPropertyName, search, options).then((request) => request(axios, basePath));
        },
        /**
         * Tento endpoint umožňuje aktualizaci stavu vozidla na aktivní nebo neaktivní.
         * @summary Aktualizuje stav vozidla
         * @param {number} id 
         * @param {PatchIsActiveRequest} patchIsActiveRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchVehicleIsActive(id: number, patchIsActiveRequest: PatchIsActiveRequest, options?: any): AxiosPromise<void> {
            return localVarFp.patchVehicleIsActive(id, patchIsActiveRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Tento endpoint umožňuje uložit nové nebo aktualizovat existující vozidlo.
         * @summary Uložit vozidlo
         * @param {SaveVehicleRequest} saveVehicleRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveVehicle(saveVehicleRequest: SaveVehicleRequest, options?: any): AxiosPromise<SaveVehicleResponse> {
            return localVarFp.saveVehicle(saveVehicleRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * VehicleApi - object-oriented interface
 * @export
 * @class VehicleApi
 * @extends {BaseAPI}
 */
export class VehicleApi extends BaseAPI {
    /**
     * Tento endpoint umožňuje smazat vozidlo.
     * @summary Smazat vozidlo
     * @param {number} id Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleApi
     */
    public deleteVehicle(id: number, options?: AxiosRequestConfig) {
        return VehicleApiFp(this.configuration).deleteVehicle(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Tento endpoint umožňuje získat informace o konkrétním vozidle.
     * @summary Vrátí informace o vozidle
     * @param {number} id Unikátní identifikátor vozidla.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleApi
     */
    public getVehicle(id: number, options?: AxiosRequestConfig) {
        return VehicleApiFp(this.configuration).getVehicle(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Tento endpoint vrátí seznam vozidel.
     * @summary Vrátí seznam vozidel
     * @param {boolean} isActive Příznak označující, zda je vozidlo aktivní nebo ne.
     * @param {number} page 
     * @param {number} pageSize 
     * @param {boolean} isDescendingSort 
     * @param {string} [sortPropertyName] 
     * @param {string} [search] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleApi
     */
    public getVehicles(isActive: boolean, page: number, pageSize: number, isDescendingSort: boolean, sortPropertyName?: string, search?: string, options?: AxiosRequestConfig) {
        return VehicleApiFp(this.configuration).getVehicles(isActive, page, pageSize, isDescendingSort, sortPropertyName, search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Tento endpoint umožňuje aktualizaci stavu vozidla na aktivní nebo neaktivní.
     * @summary Aktualizuje stav vozidla
     * @param {number} id 
     * @param {PatchIsActiveRequest} patchIsActiveRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleApi
     */
    public patchVehicleIsActive(id: number, patchIsActiveRequest: PatchIsActiveRequest, options?: AxiosRequestConfig) {
        return VehicleApiFp(this.configuration).patchVehicleIsActive(id, patchIsActiveRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Tento endpoint umožňuje uložit nové nebo aktualizovat existující vozidlo.
     * @summary Uložit vozidlo
     * @param {SaveVehicleRequest} saveVehicleRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleApi
     */
    public saveVehicle(saveVehicleRequest: SaveVehicleRequest, options?: AxiosRequestConfig) {
        return VehicleApiFp(this.configuration).saveVehicle(saveVehicleRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


