import Vue from "vue";
import App from "@/App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import appsettingsUrl from "../appsettings.json";
import axios from "axios";
import { appSettings } from "@/settings";
import { confirmMixin, filtersMixin, sideAlertMixin } from "@/mixins";
import vueDebounce from "vue-debounce";
import GmapVue from "gmap-vue";
import Component from "vue-class-component";
import SignalR from "@/plugins/signalr";
import RefreshToken from "@/plugins/refreshToken";

Vue.config.productionTip = false;

Vue.mixin(confirmMixin);
Vue.mixin(filtersMixin);
Vue.mixin(sideAlertMixin);

Vue.use(vueDebounce);
Vue.use(GmapVue, {
  load: {
    libraries: ["places", "directions"],
  },
});

Component.registerHooks([
  "beforeRouteEnter",
  "beforeRouteLeave",
  "beforeRouteUpdate",
]);

axios
  .get(<string>(<unknown>appsettingsUrl))
  .then((r) => {
    appSettings.loadFromObject(r.data);

    Vue.use(SignalR);
    Vue.use(RefreshToken);

    new Vue({
      router,
      vuetify,
      render: (h) => h(App),
    }).$mount("#app");
  })
  .catch((e) => {
    console.error(e);
    console.error("Failed to get appsettings");
  });
