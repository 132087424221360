import { appSettings } from "@/settings";
import { AddressModel, Configuration, UserApi } from "@/api";
import router from "@/router";
import { refreshTokenStore, tokenStore, userStore } from "@/store";
import { AxiosError } from "axios";
import jwt_decode from "jwt-decode";
import { Role } from "@/models/Role";
import { helpers } from "gmap-vue";
import { filters } from "@/filters";
import { eventBus, Events } from "./event-bus";

export * from "./validation";

const { googleMapsApiInitializer } = helpers;

export function parseAxiosError(error: AxiosError): string[] {
  if (error.response === undefined) {
    return [];
  }

  return Object.values<string[]>(
    (error.response.data as any).errors as any
  ).flat();
}

export function isUserAuthenticated(): boolean {
  const token = tokenStore.get();

  if (token === null || token === undefined || token.expiryDate === undefined) {
    return false;
  }

  const expireDate = new Date(token.expiryDate);

  if (expireDate < new Date()) {
    return false;
  }

  return true;
}

export async function getTokenOrRedirectToLogin(): Promise<string> {
  if (!isUserAuthenticated()) {
    logOut();
    throw new Error("Cannot get token if user is not authenticated");
  }
  const token = tokenStore.get();

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return token!.value!;
}

export function logOut() {
  userStore.set(null);
  tokenStore.set(null);
  refreshTokenStore.set(null);
  eventBus.$emit(Events.UserLoggedOut);
  router.push({
    name: "login",
  });
}

export function removeTrailingSlash(str: string) {
  return str.replace(/\/+$/, "");
}

export function getDefaultApiConfig(useToken = true): Configuration {
  const config = new Configuration();

  if (useToken) {
    config.accessToken = getTokenOrRedirectToLogin;
  }

  config.basePath = removeTrailingSlash(appSettings.BaseApiUrl);

  return config;
}

export function stringNullOrEmpty(str: string | undefined | null): boolean {
  return !str || str.length === 0;
}

export function isCurrentUserInRole(role: string): boolean {
  if (!isUserAuthenticated()) {
    return false;
  }

  const token = tokenStore.get();

  if (token == null || token.value === undefined) {
    throw new Error("Cannot get token");
  }

  const decoded = jwt_decode(token.value) as any;

  if (decoded === null) {
    throw new Error("Cannot decode token");
  }

  if (!decoded.role) {
    return false;
  }

  if (typeof decoded.role === "string") {
    return decoded.role == role;
  }

  return (decoded.role as string[]).includes(role);
}

export function isCurrentUserInAnyRole(roles: string[]): boolean {
  if (!isUserAuthenticated()) {
    return false;
  }

  if (roles.length <= 0) {
    return true;
  }

  const token = tokenStore.get();

  if (token == null || token.value === undefined) {
    throw new Error("Cannot get token");
  }

  const decoded = jwt_decode(token.value) as any;

  if (decoded === null) {
    throw new Error("Cannot decode token");
  }

  if (!decoded.role) {
    return false;
  }

  if (typeof decoded.role === "string") {
    return roles.some((x) => x == decoded.role);
  }

  return (decoded.role as string[]).some((r) => roles.indexOf(r) >= 0);
}

export function isCurrentUserSuperAdmin(): boolean {
  if (!isUserAuthenticated()) {
    return false;
  }

  const user = userStore.get();

  if (user == null || user.id === undefined) {
    throw new Error("Cannot get user");
  }

  return user.id == 1;
}

export function getCurrentUserClaimsForRole(role: Role) {
  if (!isUserAuthenticated()) {
    return [];
  }

  const token = tokenStore.get();

  if (token == null || token.value === undefined) {
    throw new Error("Cannot get token");
  }

  const decoded = jwt_decode(token.value) as any;

  if (decoded === null) {
    throw new Error("Cannot decode token");
  }

  if (!decoded[role]) {
    return [];
  }

  return decoded[role];
}

export function initGoogleMaps() {
  const options: Record<string, unknown> = {};

  if (appSettings.GoogleMapsApiKey && appSettings.GoogleMapsApiKey != "") {
    options.key = appSettings.GoogleMapsApiKey;
    options.libraries = "places,directions";
  }

  googleMapsApiInitializer(options, false);
}

export function selectionEmpty(): boolean {
  const selection = getSelection();
  if (!selection) {
    return true;
  }
  return selection.toString().length === 0;
}

export async function geoCodeAddress(address: AddressModel) {
  const geocoder = new google.maps.Geocoder();

  try {
    const result = await geocoder?.geocode({
      componentRestrictions: {
        country: "cz",
        postalCode: address.postCode,
        route: address.street,
      },
      address: filters.toAddressText(address),
    });

    if (result && result.results.length > 0) {
      const bestResult = result.results.sort((x, y) =>
        x.partial_match === y.partial_match ? 0 : x.partial_match ? 1 : -1
      )[0];

      address.googlePlaceId = bestResult.place_id;

      address.lat = bestResult.geometry.location.lat();
      address.lng = bestResult.geometry.location.lng();

      address.notFound = false;

      return address;
    }
  } catch (e) {
    const code = (<any>e).code;

    if (code == "ZERO_RESULTS") {
      address.notFound = true;
    }
    console.error(e, address.id);
  }

  return address;
}

export function getCorrectedRideDurationInSeconds(
  duration: number,
  timeType: TimeUnit
): number {
  duration *= 1.25;

  if (timeType == TimeUnit.Miliseconds) {
    return (duration + 300 * 1000) / 1000;
  }

  if (timeType == TimeUnit.Seconds) {
    return duration + 300;
  }

  if (timeType == TimeUnit.Minutes) {
    return (duration + 300 / 60) * 60;
  }

  return 0;
}

export enum TimeUnit {
  Miliseconds,
  Seconds,
  Minutes,
}
